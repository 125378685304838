import React from "react";
import {Row} from "../../../theme/grid";
import EOCard from "./EOCard";



const  PrecipitationCards =({precipitations, onReadMore}) =>(
    <Row style={{justifyContent: 'center'}}>
      {precipitations.map(precipitation =>(
        <EOCard
          key={precipitation.node.id}
          eodata={precipitation.node.shortcode}
          color="#2F3592"
          title={precipitation.node.name}
          since={precipitation.node.timeSince}
          spatial_res={precipitation.node.spatialRes}
          temporal_res={precipitation.node.temporalRes}
          image={precipitation.node.dataType.image}
          link={precipitation.node.slug}
          onReadMore={()=>onReadMore(precipitation.node.readmore)}
        />
      ))}
    </Row>
);

export default PrecipitationCards;
