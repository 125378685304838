import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import config from "../../../config";
import {grid} from "styled-components-grid";

const Col2 = styled.div`
  ${grid.unit({ size: { lg: 2 / 12 } })}
`;
const Card = styled(Col2)`
  margin: 0.25rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  position: relative;
  height: 370px;
  min-width: 185px;
  max-width: 225px;
`;
const CardSection = styled.div`
  position: relative;
`;
const TopSection = styled(CardSection)`
  background-color: #efefef;
  height: 200px;
  width: 100%;
  transition: .2s all ease-out;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

`;

const BottomSection = styled(CardSection)`
  background-color: #f7f7f7;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  ${Card}:hover & {

  }
`;
const CardOverlay = styled.div`
  background: rgba(0,0,0,.05);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const EOData = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #ffffff;
  padding: 0.25rem 0.4rem;
  font-size: 0.85rem;
  font-weight: 400;
`;
const CardDetails = styled.div`
  background: #ffffff;
  padding: 0.5rem;
  box-sizing: border-box;
  width: calc(100% - 15px);
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,.05);
  position: relative;
  z-index: 3;
  top: -30px;
  min-height: 120px;
  ${Card}:hover & {
    transition: .2s all ease-out;
    color: #ffffff !important;
    background: rgba(0,0,0,0.5);
  }
`;
const Title = styled.div`
  font-family: ${props => props.theme.headerFont};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 800;
  font-size: 0.8rem;
  color: ${props => props.theme.blackColor};
  ${Card}:hover & {
    color: #ffffff;
  }

`;
const  Item = styled.div`
font-size: 0.75rem;
`;
const  ItemSpan = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  color: ${props => props.theme.blackColor};
  ${Card}:hover & {
    color: #ffffff;
  }
`;
const Buttons = styled.div`
  padding: 0 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
`;
const CardLink = styled(Link)`
   color: #ffffff;;
    padding: 0.3rem;
    font-size: 0.75rem;
   border-radius: 0.2rem;
   text-decoration: none;
  font-weight: 600;
  font-family: ${props => props.theme.headerFont};
   &:hover {
    transition: .2s all ease-out;
    color: white !important;
  }
`;
const ReadMore = styled.div`
  color: #ffffff;;
  padding: 0.3rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  font-family: ${props => props.theme.headerFont};
   &:hover {
    transition: .2s all ease-out;
    color: white !important;
  }
`;

const  EOCard =({eodata, color, title, image, since, spatial_res, temporal_res, link, onReadMore}) =>(
  <Card>
    <TopSection style={{background: `#f7f7f7 url(${config.ROOT_URL}/media/${image}) center center/cover no-repeat`}}>
      <CardOverlay />
      <EOData style={{backgroundColor:`${color}`}}>{eodata}</EOData>
    </TopSection>
    <BottomSection>
      <CardDetails>
        <Title style={{color:`${color} &:hover: #ffffff`}}>{title}</Title>
        <Item><ItemSpan>Since:</ItemSpan> {since}</Item>
        <Item><ItemSpan>Spatial Resolution:</ItemSpan> {spatial_res}</Item>
        <Item><ItemSpan>Temporal Resolution:</ItemSpan> {temporal_res}</Item>
      </CardDetails>

    </BottomSection>
    <Buttons>
      <ReadMore style={{backgroundColor:`${color}`}} onClick={onReadMore} >Read More</ReadMore>
      <CardLink style={{backgroundColor:`${color}`}} to={`/data-center/${link}`} >Download</CardLink>
    </Buttons>
  </Card>

);

export default EOCard;
