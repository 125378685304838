import React, {Component} from 'react';
import Modal from "react-modal";
import {IoIosCloseCircle} from 'react-icons/io';
import styled from "styled-components";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as authActions from "../../actions/authActions";
import LoginForm from "./LoginForm";
import RegistrationForm from "./RegistrationForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import axios from "axios";
import config from "../../config";
import {requestConfig} from "../../utils/requestConfig";
import {Col3, Row} from "../../theme/grid";
import {LineSubmit} from "../buttons";
import isEmail from "../../utils/isEmail";
import toastr from "toastr";


const Section = styled.div`
    margin: 2rem;
`;



const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : '1000',
    width: '50%'
  }
};
Modal.setAppElement('#root');


class AuthModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      login: Object.assign({}, this.props.login),
      registration: Object.assign({}, this.props.registration),
      forgot: Object.assign({}, this.props.forgot),
      password: {},
      countries: [],
      login_errors: {},
      reg_errors: {},
      forgot_errors: {},
      loginFail: "",
      regFail: "",
      forgotFail: "",
      loading: false,
      formType: "login"
    };
  }
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
    this.getCountries();
  }
  componentWillUnmount(){
    this._isMounted = false;
  }

  getCountries(){
    return axios.post(`${config.ROOT_URL}/q/` , countriesQuery(), requestConfig)
      .then((resp)=>{
        let countries = resp.data.data.countries.edges;
        if (this._isMounted) {
          this.setState({countries: countries});
        }
      }).catch(error => {
        throw(error);
      });
  }

  onChange=(event)=> {
    let target = event.target;
    let value = target.value;
    let name = target.name;
    let login = this.state.login;
    this.setState({ login: { ...login, [name]: value } });
  };
  onRegChange=(event)=> {
    let target = event.target;
    let value = target.value;
    let name = target.name;
    let registration = this.state.registration;
    this.setState({ registration: { ...registration, [name]: value } });
  };
  onForgotChange=(event)=> {
    let target = event.target;
    let value = target.value;
    let name = target.name;
    let forgot = this.state.forgot;
    this.setState({ forgot: { ...forgot, [name]: value } });
  };
  onCountryChange=(e)=> {
    this.setState({country: e});
    let registration = this.state.registration;
    this.setState({ registration: { ...registration, "country": parseInt(e.value) } });
  };
  onPasswordChange=(e)=> {
    this.setState({password: e});
    let registration = this.state.registration;
    this.setState({ registration: { ...registration, "password": e.password } });
  };
  loginValid() {
    let formIsValid = true;
    let errors = {};
    if(this.state.login.email.length < 1) {
      errors.email = 'Email is Required';
      formIsValid = false;
    }
    if(!isEmail(this.state.login.email)) {
      errors.email = 'Valid Email address is Required';
      formIsValid = false;
    }
    if(this.state.login.password.length < 1) {
      errors.password = 'Password is Required';
      formIsValid = false;
    }
    this.setState({login_errors: errors});
    return formIsValid;
  }
  onLogin=(event)=> {
    event.preventDefault();
    if (!this.loginValid()) {
      return;
    }
    this.setState({loading: true});
    this.props.actions.userLogin(this.state.login)
      .then(() => this.onSuccessLogin())
      .catch(data => {
        let response = data.response;
        let detail = response.data.detail;
        this.setState({ loginFail: detail });
        this.setState({loading: false});
      });
  };
  onSuccessLogin(){
    this.setState({loading: false});
    this.setState({authenticated: true});
    this.props.actions.onAuthModal();
  }

  regValid() {
    let formIsValid = true;
    let errors = {};
    if(this.state.registration.first_name.length < 1) {
      errors.first_name = 'First Name is Required';
      formIsValid = false;
    }
    if(this.state.registration.last_name.length < 1) {
      errors.last_name = 'Last Name is Required';
      formIsValid = false;
    }
    if(this.state.registration.email.length < 1) {
      errors.email = 'Email is Required';
      formIsValid = false;
    }
    if(!isEmail(this.state.registration.email)) {
      errors.email = 'Valid Email Address is Required';
      formIsValid = false;
    }
    if(this.state.registration.country.length < 1) {
      errors.country = 'Country is Required';
      formIsValid = false;
    }
    if(this.state.registration.organization.length < 1) {
      errors.organization = 'Organization is Required';
      formIsValid = false;
    }
    if(this.state.registration.data_use.length < 1) {
      errors.data_use = 'How you will use the data is Required';
      formIsValid = false;
    }
    if(this.state.registration.password.length < 1) {
      errors.password = 'Password is Required';
      formIsValid = false;
    }
    if(!this.state.password.isValid) {
      errors.password = 'Password Strength is too low';
      formIsValid = false;
    }
    if(this.state.registration.password_confirm.length < 1) {
      errors.password_confirm = 'Password Confirmation is Required';
      formIsValid = false;
    }

    if(this.state.registration.password !== this.state.registration.password_confirm) {
      errors.passwords = 'Password and Password Confirmation must match';
      formIsValid = false;
    }
    this.setState({reg_errors: errors});
    return formIsValid;
  }
  onRegistration=(event)=> {
    event.preventDefault();
    if (!this.regValid()) {
      return;
    }
    this.setState({loading: true});
    this.props.actions.userRegistration(this.state.registration)
      .then(() => {
        this.onSuccessRegistration();
      })
      .catch(data => {
        let errors = data.response.data;
        this.setState({reg_errors: errors});
        let response = data.response;
        let detail = response.data.detail;
        this.setState({ regFail: detail });
        this.setState({loading: false});
      });
  };
  onSuccessRegistration(){
    this.setState({loading: false});
    this.setState({formType: 'login'})
  }

  forgotValid() {
    let formIsValid = true;
    let errors = {};
    if(this.state.forgot.email.length < 1) {
      errors.email = 'Email is Required';
      formIsValid = false;
    }
    if(!isEmail(this.state.forgot.email)) {
      errors.email = 'Valid Email Address is Required';
      formIsValid = false;
    }
    this.setState({forgot_errors: errors});
    return formIsValid;
  }


  onForgot=(event)=> {
    event.preventDefault();
    if (!this.forgotValid()) {
      return;
    }
    this.setState({loading: true});
    this.props.actions.userForgotPassword(this.state.forgot)
      .then(() => this.onSuccessForgotPassword())
      .catch(data => {
        let response = data.response;
        let detail = response.data;
        this.setState({ forgotFail: detail });
        this.setState({loading: false});
      });
  };
  onSuccessForgotPassword(){
    this.setState({loading: false});
    this.props.actions.onAuthModal();
    toastr.success(`<strong>Success</strong> <br /> <em>Instructions for changing your password have been sent to your Email address</em>`);
  }
  onLoginForm=()=>{this.setState({formType: 'login'})};
  onRegForm=()=>{this.setState({formType: 'register'})};
  onForgotForm=()=>{this.setState({formType: 'forgot'})};
  renderForm=()=> {
    if (this.state.formType === "login") {
      return <Section id="login">
        <LoginForm
          onChange={this.onChange}
          login={this.state.login}
          onLogin={this.onLogin}
          onRegForm={this.onRegForm}
          loginFail={this.state.loginFail}
          errors={this.state.login_errors}
        />
      </Section>
    } else if (this.state.formType === "register") {
      return <Section id="registration">
        <RegistrationForm
          onChange={this.onRegChange}
          onCountryChange={this.onCountryChange}
          onPasswordChange={this.onPasswordChange}
          registration={this.state.registration}
          country={this.state.country}
          countries={this.state.countries}
          onRegistration={this.onRegistration}
          onLoginForm={this.onLoginForm}
          regFail={this.state.regFail}
          errors={this.state.reg_errors}
        />
      </Section>

    } else if (this.state.formType === "forgot") {
      return <Section id="forgot">
        <ForgotPasswordForm
          onChange={this.onForgotChange}
          forgot={this.state.forgot}
          onForgot={this.onForgot}
          onRegForm={this.onRegForm}
          onLoginForm={this.onLoginForm}
          forgotFail={this.state.forgotFail}
          errors={this.state.forgot_errors}
        />
      </Section>

    }
  };

  render() {
    const {modalIsOpen, afterOpenModal, closeModal} =this.props;
    return (
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Authentication Modal"
      >
        <IoIosCloseCircle onClick={closeModal} size={30} color='#cc0516'
                          style={{position: "absolute", right: 0, top: 0, cursor: 'pointer'}}/>
        {this.renderForm()}

        <Row style={{justifyContent: 'center'}}>
          <Col3>
            {this.state.formType !== "forgot" ?
            <LineSubmit onClick={this.onForgotForm} text="Forgot Password" />: ""}
          </Col3>
        </Row>
      </Modal>
    )
  };
}

AuthModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  afterOpenModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired
};
function mapStateToProps(state) {
  let login = {email: '', password: ''};
  let registration = {first_name: '', last_name: '', email: '', phone: '',  organization: '', country: '', data_use: '', password: '', password_confirm: ''};
  let forgot = {email: ''};
  return {
    login: login,
    registration: registration,
    forgot: forgot
  };

}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthModal));

function countriesQuery() {
  return `
    query{
      countries{
        edges{
          node{
            id,
            pk,
            name,
          }
        }
      }
    }
`
}
