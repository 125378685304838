import React from "react";
import styled from 'styled-components';


const Section = styled.div`
  border-radius: 5px;
  display: inline-block;
  position: relative;
  padding: 0.25rem 0.5rem;
  border-width: 1px;
  border-style: solid;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  font-family: ${props => props.theme.bodyFont};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.4px;
  line-height: normal;
  transition: 0.3s ease-out;
  text-align: center;
  width: fit-content;
  border-color: ${props => props.theme.darkgreyColor};
  outline: none;
  color: ${props => props.theme.darkgreyColor};
  background-color: #ffffff;
  :hover{
    color: #ffffff;
    background-color: ${props => props.theme.darkgreyColor};
  }
`;



const MetaButton = ({text, onClick}) => (
  <Section onClick={onClick}>
    {text}
  </Section>
);

export default MetaButton;
