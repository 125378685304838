import React, {Component, Fragment} from 'react';
import Layout from "../layout";
import styled from "styled-components";
import config from "../config";
import { Container, Row, Col10 } from "../theme/grid";
import ProjectEvaluation from "../components/about/ProjectEvaluation";
import ConsortiumMembers from "../components/about/ConsortiumMembers";
import {isEmpty} from "../utils/isEmpty";
import axios from "axios";
import {requestConfig} from "../utils/requestConfig";
import parse from "html-react-parser";
import Donors from "../components/about/Donors";


const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  min-height: 350px;
`;
const BannerTitle = styled.div`
  width: fit-content;
  height: 100%;
  font-family: ${props => props.theme.headerFont};
  text-align: center;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 800;
  color: #ffffff;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  padding: 1rem 0;
`;
const PageIntro = styled.div`
z-index: 1;
  position: relative;
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #f7f7f7;
  box-shadow: 0 5px 30px 0 rgba(39,39,39,0.15);
`;
const PageIntroContent = styled.div`
  position: absolute;
  background: #ffffff;
  top: -50px;
  padding: 3rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const PageTitle = styled.div`
  width: 100%;
  height: 100%;
  font-family: ${props => props.theme.bodyFont};
  color: ${props => props.theme.blackColor};
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
`;
const PageSubTitle = styled.div`
  font-family: ${props => props.theme.bodyFont};
  font-weight: 400;
  text-align: center;
  width: 85%;
  color: ${props => props.theme.blackColor};
  p{
    font-size: 1.1rem;
    font-weight: 400;
  }
  @media (max-width: 768px){
    width: 100%;
  }
`;
const Section = styled.section`
width: 100%;
padding: 5rem 0;
 color: ${props => props.theme.blackColor};
h3{
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.4rem;
  text-align: center;
  font-family: ${props => props.theme.headerFont};
}
p{
  font-size: 1.1rem;
  line-height: 1.6;
  font-weight: 400;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: #1D1D1B;
}
`;
const Section1 = styled(Section)`
 background-color: ${props => props.theme.sectionBackground};
`;
const Section2 = styled(Section)`
  background-color: #FFFFFF;
`;
const Section3 = styled(Section)`
  background-color: ${props => props.theme.sectionBackground};
`;
const Section4 = styled(Section)`
  background-color: #FFFFFF;
  text-align: center;
`;

const SectionTitle = styled.h3`
  color: ${props => props.theme.blackColor};
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 700;
  padding-bottom: 1rem;
  text-align: center;
`;
const SectionDesc = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
`;

const Loading = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {}
    };
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getContent();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  getContent(){
    this.setState({loading: true});
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(), requestConfig)
      .then((resp)=> {
        let content = resp.data.data.content;
        if (this._isMounted) {
          this.setState({content: content});
        }
      }).catch(error => {
        this.setState({loading: false});
        throw(error);
      });
  }
  render() {
    let page = {
      title: "About Us",
      description: "The Global Monitoring for Environment and Security and Africa (GMES & Africa) Support Programme is " +
        "the result of the longstanding cooperation between Africa and Europe in the area of space science & technology, " +
        "which is one of the key priorities of the long-term EU-Africa Joint Strategy",
      keywords: "GMES and AFRICA about page"
    };
    return (
      <Layout page={page}>
        {isEmpty(this.state.content) ?
          <Loading>
            <img src={`${config.PUBLIC_URL}/img/preview.gif`} alt="Loading"/>
          </Loading>
          :
          <Fragment>
            <PageBanner style={{background: `url(${config.ROOT_URL}/media/${this.state.content.headerImg}) center center/cover no-repeat fixed`}}>
              <Container>
                <Row style={{ justifyContent: 'center'}}>
                  <BannerTitle>{this.state.content.title}</BannerTitle>
                </Row>
              </Container>
            </PageBanner>
            <PageIntro>
              <PageIntroContent>
                <PageTitle>
                  {this.state.content.description}
                </PageTitle>
                <PageSubTitle>
                  {parse(`${this.state.content.pageIntro}`)}
                </PageSubTitle>
              </PageIntroContent>
            </PageIntro>

        <Section1>
          <Container>
            <Row style={{justifyContent: 'center'}}>
              <Col10>
                {parse(`${this.state.content.about}`)}
              </Col10>
            </Row>
          </Container>
        </Section1>
        <Section2>
          <Container>
            <SectionTitle>Project Evolution</SectionTitle>
            <ProjectEvaluation logos={this.state.content.projects}/>
          </Container>
        </Section2>
        <Section3>
          <Container>
            <SectionTitle>Consortium members</SectionTitle>
            <ConsortiumMembers logos={this.state.content.members}/>
          </Container>
        </Section3>
        <Section4>
          <Container>
            <SectionTitle>Donors</SectionTitle>
            <SectionDesc>GMES & Africa is is co-funded by the European Union and the Africa Union</SectionDesc>
            <Donors logos={this.state.content.donors}/>
          </Container>
        </Section4>
          </Fragment>
        }
      </Layout>
    )
  }
}

export default AboutPage;

function getQuery() {
  return `
query{
  content:about(pageId: 1){
    title,
    headerImg,
    pageIntro,
    about,
    projects: projecthistorySet{
      id,
      title,
      link,
      image,
      period
    },
    members: consortiummembersSet{
      id,
      title,
      link,
      image
    },
    donors: donorsSet{
      id,
      title,
      link,
      image
    }
  }
}
`
}
