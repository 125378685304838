import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as authActions from "../../actions/authActions";
import {connect} from "react-redux";


const Section = styled.div`
position: fixed;
top: 4rem;
left: 0;
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
height: 100%;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
`;
const Links = styled.ul`
  padding: 0.25rem 1rem;
  margin: 0;
  list-style: none;
`;

const ListItem = styled.li`
  line-height: 24px;
  position: relative;
`;
const MenuLink = styled(Link)`
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem;
  margin: 0 0.5rem;
  text-decoration: none;
  color: ${props => props.theme.darkgreyColor};
  font-family: ${props => props.theme.bodyFont};
`;
const TopLink = styled(MenuLink)`
font-weight: 600;
padding: 0.25rem;
`;

const UserMenuItem = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
  margin: 0.1rem 0;
  justify-content: flex-start;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: ${props => props.theme.bodyFont};
  color: ${props => props.theme.fontColor};
  &:hover{
    color: ${props => props.theme.blackColor};
    background: #f1f1f1;
   }
  &:after{
    display: none;
  }
`;

class SideMenu extends Component {


  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onLogin=()=>{
    this.props.actions.onAuthModal()
  };
  onRegister=()=>{
    this.props.actions.onAuthModal()
  };
  onLogout=()=>{
    this.props.actions.userLogout()
  };

  render() {
    return (
      <Section>
        <Nav>
          <TopLink to="/" onClick={this.props.sideMenuToggle}>Home</TopLink>
          <TopLink to="#" onClick={this.props.sideMenuToggle}>What We Do</TopLink>
          <Links>
            <ListItem><MenuLink to="/what-we-do/natural-habitats" onClick={this.props.sideMenuToggle}>Natural Habitats</MenuLink></ListItem>
            <ListItem><MenuLink to="/what-we-do/tropical-forest" onClick={this.props.sideMenuToggle}>Tropical Forest</MenuLink></ListItem>
            <ListItem><MenuLink to="/what-we-do/agriculture-monitoring" onClick={this.props.sideMenuToggle}>Agriculture Monitoring</MenuLink></ListItem>
            <ListItem><MenuLink to="/what-we-do/rangeland-monitoring" onClick={this.props.sideMenuToggle}>Rangeland Monitoring</MenuLink></ListItem>
          </Links>
          <TopLink to="#" onClick={this.props.sideMenuToggle}>EO Products</TopLink>
          <Links>
            <ListItem><MenuLink to="/dashboard/land-cover-fires" onClick={this.props.sideMenuToggle}>Land Cover & Fires</MenuLink></ListItem>
            <ListItem><MenuLink to="/dashboard/eco-climatic-analysis" onClick={this.props.sideMenuToggle}>Eco-climatic Analysis</MenuLink></ListItem>
            <ListItem><MenuLink to="/dashboard/crop-forage-conditions" onClick={this.props.sideMenuToggle}>Crop & Forage Conditions</MenuLink></ListItem>
            <ListItem><MenuLink to="/publications" onClick={this.props.sideMenuToggle}>Bulletins</MenuLink></ListItem>
          </Links>
          <TopLink to="/data-center" onClick={this.props.sideMenuToggle}>Data Center</TopLink>

          <TopLink to="#" onClick={this.props.sideMenuToggle}>About Us</TopLink>
          <Links>
            <ListItem><MenuLink to="/about-us" onClick={this.props.sideMenuToggle}>GMES & Africa</MenuLink></ListItem>
            <ListItem><MenuLink to={{ pathname: "https://icpac.net/events/" }} target="_blank" onClick={this.props.sideMenuToggle}>Events</MenuLink></ListItem>
            <ListItem><MenuLink to="/work-with-us/vacancies" onClick={this.props.sideMenuToggle}>Vacancies</MenuLink></ListItem>
            <ListItem><MenuLink to="/work-with-us/tenders" onClick={this.props.sideMenuToggle}>Tenders</MenuLink></ListItem>
            <ListItem><MenuLink to={{ pathname: "https://www.icpac.net/publications/?project=263#results" }} target="_blank" onClick={this.props.sideMenuToggle}>Visit ICPAC Website</MenuLink></ListItem>
          </Links>
          <TopLink to={{ pathname: "https://www.icpac.net/publications/?project=263#results" }} target="_blank" onClick={this.props.sideMenuToggle}>Publications</TopLink>
          <TopLink to="/get-in-touch" onClick={this.props.sideMenuToggle}>Contact Us</TopLink>
<br/>
          {this.props.isAuthenticated ?
            <UserMenuItem onClick={this.onLogout}>Logout</UserMenuItem>
            :
            <UserMenuItem onClick={this.onLogin}>Login</UserMenuItem>
          }

        </Nav>
      </Section>
    );
  }
}

SideMenu.propTypes = {
  actions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    modal: state.modal,
    isAuthenticated: state.isAuthenticated
  };

}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenu));
