import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import ReactGA from "react-ga";
ReactGA.initialize("UA-165808312-1");


class PageTracker extends Component{

  componentDidMount() {
    // eslint-disable-next-line
    const page = this.props.location.pathname + this.props.location.search;
    ReactGA.pageview(page);

  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      ReactGA.pageview(nextPage);
    }
  }

  render(){
    return <div/>
  }
}

export default withRouter(PageTracker);
