import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function authModalReducer(state = initialState.modal, action) {
  switch  (action.type) {

    case types.TOGGLE_AUTH_MODAL:
      return !state;

    default:
      return state;
  }
}
