import React, {useState} from 'react';
import styled from 'styled-components';
import {Row, Col3} from "../../../theme/grid";
import { RadioInput } from "../../../components/input";
import ReactSelect from "../../../components/input/ReactSelect";
import { FaTimes} from 'react-icons/fa';

const Remove = styled.div`
  padding: 2px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    background-color: #DDDDDD;
  }
`;


const dataOptions =(options) =>{
  return options.map(option =>{
    return{
      value: option.node,
      label: option.node.name
    }
  })
};

const RegionFilter = ({areaType, onPaChange, onAdminChange, onCountryChange, onAreaTypeChange, pa_area, admin_area, adminAreas, paAreas, country, countries, errors}) => {
  // const [selectedCountry, setSelectedCountry] = useState(country);
  // const [selectedAreaType, setSelectedAreaType] = useState(areaType);
  // const [selectedAreaName, setSelectedAreaName] = useState('');

  // const handleCountryChange = (selectedOption) => {
  //   setSelectedCountry(selectedOption);
  //   onCountryChange(selectedOption);
  // };

  // const handleAreaTypeChange = (e) => {
  //   setSelectedAreaType(e.target.value);
  //   onAreaTypeChange(e);
  // };

  // const handlePaChange = (selectedOption) => {
  //   setSelectedAreaName(selectedOption);
  //   onPaChange(selectedOption);
  // };

  // const handleAdminChange = (selectedOption) => {
  //   setSelectedAreaName(selectedOption);
  //   onAdminChange(selectedOption);
  // };
  return(
    <div style={{border: "1px solid #eee", padding: "1rem"}}>

      <Row style={{justifyContent: "space-evenly", padding: "1rem"}}>
        <Col3>
          <RadioInput
            onChange={onAreaTypeChange}
            name="areaType"
            defaultChecked={areaType ==="admin"}
            label="Administrative Boundaries"
            value="admin" />
        </Col3>
        <Col3>
          <RadioInput
            onChange={onAreaTypeChange}
            name="areaType"
            defaultChecked={areaType ==="protected"}
            label="Protected Area"
            value="protected" />
        </Col3>
        <Col3>
          <RadioInput
            onChange={onAreaTypeChange}
            name="areaType"
            defaultChecked={areaType ==="icpac"}
            label="ICPAC Region"
            value="icpac" />
        </Col3>
      </Row>
      {areaType !== "icpac" ?
        <>
        <Row style={{justifyContent: "space-evenly", padding: "1rem"}}>
          <Col3>
            <ReactSelect
              onChange={onCountryChange}
              value={country}
              placeholder="Select"
              options={dataOptions(countries)}
              label="Country"
            />
          </Col3>
          {areaType === "protected" ? 
            <Col3>
              <ReactSelect
                onChange={onPaChange}
                isClearable={true}
                name="pa_area"
                value={pa_area}
                options={dataOptions(paAreas)}
                label="Protected Areas"
              />
            </Col3> :
            <Col3>
              <ReactSelect
                onChange={onAdminChange}
                isClearable={true}
                name="admin_area"
                value={admin_area}
                options={dataOptions(adminAreas)}
                label="Administration Areas"
              />
            </Col3>}
        </Row>
        <Row style={{justifyContent: "space-evenly", padding: "1rem"}}>
        <table>
          <thead>
            <tr style={{background: '#DDDDDD'}}><td>Country</td><td>Area Type</td><td>Area Name</td><td></td></tr>
          </thead>
          <tbody>
            <tr><td>{country ? country.label : 'No countries selected'}</td><td> {areaType }</td><td>{admin_area ? admin_area.label : pa_area? pa_area.label :"No area selected"}</td><td><Remove><FaTimes size="1em" /></Remove></td></tr>
          </tbody>
        </table>
      </Row>
        </>:""
      }

      

      {errors.region ?
        <Row style={{justifyContent: "center"}}>
          <p className="errors">{errors.region}</p>
        </Row>: ""}
    </div>
  )

};

export default RegionFilter;
