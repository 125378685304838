import React, {Component} from 'react';
import Layout from "../../layout";
import styled from 'styled-components';
import {Container, FluidContainer, Col2, Col5, Col6, Col10, Row} from "../../theme/grid";
import config from "../../config";
import VariableFilter from "./filters/VariableFilter";
import EcoClimatic from "./result";
import axios from "axios";
import {requestConfig} from "../../utils/requestConfig";
import NDVILegend from "./result/legends/NDVILegend";
import RFELegend from "./result/legends/RFELegend";
import DMPLegend from "./result/legends/DMPLegend";
import FAPARLegend from "./result/legends/FAPARLegend";
import SMLegend from "./result/legends/SMLegend";
import RegionFilter from "./filters/RegionFilter";
import {ecoAdminArea, ecoCountry, ecoPaArea, ecoRegion} from "../../actions/ecoclimaticActions";


const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  position: relative;
  width: 100%;
  height: 60px;
  z-index: 100;
`;

const H3 = styled.h3`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;

const H5 = styled.h5`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.4rem;
`;
const PageSubTitle = styled(H5)`
  color: #ffffff;
`;
const PageRow = styled(Row)`
position: relative;
`;
const Side = styled(Col2)`
position: relative;
height: auto;
min-height: 60vh;
width: 100%;
border-right: 1px solid #eeeeee;
background-color: #ffffff;
padding: 0.5rem;
box-shadow: 0 5px 30px 0 rgba(39,39,39,0.15);
`;

const Main = styled(Col10)`
  height: 100%;
  min-height: 50vh;
  position: relative;
  width: 100%;
`;
const TopBar = styled(Container)`
  position: sticky;
  top: 6rem;
  background: #ffffff;
  z-index: 1;
`;

class EcoClimaticPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      filter:{
        region: "", country: "", admin: false, pa: false, all: true, ndvi: false, rfe: false, dmp: false, fapar: false, sm: false },
      region: true,
      regions: [],
      ecoclimatic: {},
      area_type: '',
      variable: "all",
      search: "",
      isRegion: true,
      regionId: "",
      isCountry: true,
      countryId: "",

      area: 'region',
      country: null,
      pa_area: null,
      admin_area: null,
      countries: [],
      areaType: "admin",
      dataType: this.props.match.params.slug,
      areas: [],
      adminAreas: [],
      paAreas: [],
      data: {}
    };
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getRegionData();
    this.onRegionLoad();
  }
  componentWillUnmount(){
    this._isMounted = false;
    // this.getRegions();
  }
  componentDidUpdate(prevProps, prevState){
    if (prevState.country !== this.state.country && this.state.country !== null) {
      this.getAreas();
    }
    if (prevState.areaType !== this.state.areaType) {
      this.setState({pa_area: null});
      this.setState({admin_area: null});
    }
    if (prevState.region !== this.state.region && this.state.region !== false){
      this.onRegionLoad();
      this.setState({country: null});
      this.setState({pa_area: null});
      this.setState({admin_area: null});
      this.setState({areaType: "admin"});
    }
  }

  onChange=(event)=> {
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let filter = this.state.filter;
    this.setState({ filter: { ...filter, [name]: value } })
  };

  onRegionChange=()=>{
    this.setState({region: !this.state.region});
  };
  onRegionLoad=()=>{
    this.setState({loading: true});
    ecoRegion(this.state.filter).then((data)=>{
      if(this._isMounted) {
        this.setState({data: data});
        this.setState({loading: false});
      }
    }).catch(error => {
      this.setState({loading: false});
      throw(error);
    })
  };
  onAreaTypeChange=(e)=> {
    this.setState({areaType: e.target.value});
  };
  onAdminChange=(e)=> {
    this.setState({pa_area: null});
    this.setState({admin_area: e});
    this.onAreaCountryChange(e);
    this.onAdminAreaLoad(e);
    this.setState({region: false});
    this.setState({area: 'admin_area'});
  };
  onAdminAreaLoad=(e)=>{
    this.setState({loading: true});
    ecoAdminArea(e.value.id).then((data)=>{
      if(this._isMounted) {
        this.setState({data: data});
        this.setState({loading: false});
      }
    }).catch(error => {
      this.setState({loading: false});
      throw(error);
    })
  };
  onPaChange=(e)=> {
    this.setState({pa_area: e});
    this.setState({admin_area: null});
    this.onAreaCountryChange(e);
    this.onPaAreaLoad(e);
    this.setState({region: false});
    this.setState({area: 'pa_area'});
  };
  onPaAreaLoad=(e)=>{
    this.setState({loading: true});
    ecoPaArea(e.value.id).then((data)=>{
      if(this._isMounted) {
        this.setState({data: data});
        this.setState({loading: false});
      }
    }).catch(error => {
      this.setState({loading: false});
      throw(error);
    })
  };
  onAreaCountryChange(e){
    if(this.state.country === null){
      let country = {value: e.value.country, label: e.value.country.name};
      this.setState({country: country});
    }
  }
  onCountryChange=(e)=> {
    this.setState({country: e});
    this.onCountryLoad(e);
    this.setState({region: false});
    this.setState({pa_area: null});
    this.setState({admin_area: null});
    this.setState({area: 'country'});
  };
  onCountryLoad=(e)=>{
    this.setState({loading: true});
    ecoCountry(e.value.id).then((data)=>{
      if(this._isMounted) {
        this.setState({data: data});
        this.setState({loading: false});
      }
    }).catch(error => {
      this.setState({loading: false});
      throw(error);
    })
  };

  getRegionData(){
    return axios.post(`${config.ROOT_URL}/q/` , regionQuery(), requestConfig)
      .then((resp)=>{
        let data = resp.data.data.regions.edges;
        this.setState({regions: data});
        let regionId = data[0].node.id;
        let filter = this.state.filter;
        this.setState({ filter : { ...filter, region: regionId } });
        this.setState({ regionId : regionId});
        this.setState({region: true});
        this.getCountries(regionId);
      }).catch(error => {
        throw(error);
      });
  }
  getCountries(){
    return axios.post(`${config.ROOT_URL}/q/` , countryQuery(), requestConfig)
      .then((resp)=>{
        let data = resp.data.data.countries.edges;
        this.setState({countries: data});
        let paAreas = resp.data.data.paAreas.edges;
        this.setState({paAreas: paAreas});
        let adminAreas = resp.data.data.adminAreas.edges;
        this.setState({adminAreas: adminAreas});
      }).catch(error => {
        throw(error);
      });
  }
  getAreas(){
    if(this.props.country !== null) {
      return axios.post(`${config.ROOT_URL}/q/`, areaQuery(this.state.country.value.gid), requestConfig)
        .then((resp) => {
          let paAreas = resp.data.data.paAreas.edges;
          this.setState({paAreas: paAreas});
          let adminAreas = resp.data.data.adminAreas.edges;
          this.setState({adminAreas: adminAreas});
        }).catch(error => {
          throw(error);
        });
    }
  }

  onAll=()=>{
    let filter= this.state.filter;
    filter["all"] = true;
    filter["ndvi"] = false;
    filter["rfe"] = false;
    filter["dmp"] = false;
    filter["fapar"] = false;
    filter["sm"] = false;
    this.setState({variable: "all"})
  };
  onNDVI=()=>{
    let filter= this.state.filter;
    filter["all"] = false;
    filter["ndvi"] = true;
    filter["rfe"] = false;
    filter["dmp"] = false;
    filter["fapar"] = false;
    filter["sm"] = false;
    this.setState({variable: "ndvi"})};
  onRFE=()=>{
    let filter= this.state.filter;
    filter["all"] = false;
    filter["ndvi"] = false;
    filter["rfe"] = true;
    filter["dmp"] = false;
    filter["fapar"] = false;
    filter["sm"] = false;
    this.setState({variable: "rfe"})};
  onDMP=()=>{
    let filter= this.state.filter;
    filter["all"] = false;
    filter["ndvi"] = false;
    filter["rfe"] = false;
    filter["dmp"] = true;
    filter["fapar"] = false;
    filter["sm"] = false;
    this.setState({variable: "dmp"})};
  onFAPAR=()=>{
    let filter= this.state.filter;
    filter["all"] = false;
    filter["ndvi"] = false;
    filter["rfe"] = false;
    filter["dmp"] = false;
    filter["fapar"] = true;
    filter["sm"] = true;
    this.setState({variable: "fapar"})};
  onSM=()=>{
    let filter= this.state.filter;
    filter["all"] = false;
    filter["ndvi"] = false;
    filter["rfe"] = false;
    filter["dmp"] = false;
    filter["fapar"] = true;
    this.setState({variable: "sm"})};
  onAreaChange=(event)=> {
    this.setState({area_type: event.target.value});
    let filter = this.state.filter;
    if(event.target.value === "admin"){
      filter["admin"] = true;
      filter["pa"] = false;
      this.setState({filter: filter})
    }else if(event.target.value === "protected"){
      filter["admin"] = false;
      filter["pa"] = true;
      this.setState({filter: filter})
    }
  };

  onSearchChange=(event)=>{
    console.log(event.target.value);
    console.log('on search');
    // this.setState({search: event.target.value})
  };
  renderLegend=()=>{
    if(this.state.filter.ndvi){
      return <NDVILegend horizontal/>
    }else if(this.state.filter.rfe){
      return <RFELegend horizontal/>
    }else if(this.state.filter.dmp){
      return <DMPLegend horizontal/>
    }else if(this.state.filter.fapar){
      return <FAPARLegend horizontal/>
    }else if(this.state.filter.sm){
      return <SMLegend horizontal/>
    }
  };

  render() {
    let page ={
      title: "Eco-Climatic Analysis",
      description: "10 day monitoring of climate and vegetation conditions",
      keywords:"Eco-Climatic Analysis"
    };
    let location = {country: this.state.country, admin_area: this.state.admin_Area, pa_area: this.state.pa_area};
    return (
      <Layout page={page}>
        <PageBanner>
          <Container style={{textAlign: "center"}}>
            <PageTitle>Eco-Climatic Analysis</PageTitle>
            <PageSubTitle>Monitoring of climate and vegetation conditions</PageSubTitle>
          </Container>
        </PageBanner>
        <FluidContainer>
          <PageRow>
            <Side>
              <RegionFilter
                onRegionChange={this.onRegionChange}
                onCountryChange={this.onCountryChange}
                onAreaTypeChange={this.onAreaTypeChange}
                onPaChange={this.onPaChange}
                onAdminChange={this.onAdminChange}
                region={this.state.region}
                country={this.state.country}
                countries={this.state.countries}
                areaType={this.state.areaType}
                adminAreas={this.state.adminAreas}
                admin_area={this.state.admin_area}
                paAreas={this.state.paAreas}
                pa_area={this.state.pa_area}
                errors={this.state.errors}
              />
            </Side>
            <Main>
              <TopBar>
                <Row style={{justifyContent: "space-between"}}>
                  <Col5>
                    <VariableFilter
                      variable={this.state.variable}
                      onAll={this.onAll}
                      onNDVI={this.onNDVI}
                      onRFE={this.onRFE}
                      onDMP={this.onDMP}
                      onFAPAR={this.onFAPAR}
                      onSM={this.onSM}
                    />
                  </Col5>
                  <Col6>
                    {this.renderLegend()}
                  </Col6>
                </Row>
              </TopBar>
              {!this.state.loading ?
                <EcoClimatic
                  data={this.state.data}
                  filter={this.state.filter}
                  location={location}
                  area={this.state.area}
                  region={this.state.region}
                  country={this.state.country}
                  pa_area={this.state.pa_area}
                  admin_area={this.state.admin_area}
                />
                :
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <img src={`${config.PUBLIC_URL}/img/preview.gif`} alt="" />
                </div>
              }
            </Main>
          </PageRow>
        </FluidContainer>
      </Layout>
    )
  }
}
function regionQuery() {
  return `
  query{
    regions:allRegions{
      edges{
        node{
          id,
          name
        }
      }
    }
  }
`
}

function countryQuery() {
  return`
query{
    countries:allCountries{
      edges{
        node{
          id,
          pk,
          gid,
          name
        }
      }
    },

  adminAreas:allAdminAreas{
    edges{
      node{
        id,
        pk,
        gid,
        name,
        country{
          id,
          gid,
          name
        }
      }
    }
  },
  paAreas:allProtectedAreas{
    edges{
      node{
        id,
        pk,
        wdpaid,
        name,
        country{
          id,
          gid,
          name
        }
      }
    }
  }
}
  `
}

function areaQuery(country) {
  return`
  query{
    adminAreas:allAdminAreas(country_Gid:"${country}"){
      edges{
        node{
          id,
          pk,
          gid,
          name
        }
      }
    },
    paAreas:allProtectedAreas(country_Gid:"${country}"){
      edges{
        node{
          id,
          pk,
          wdpaid,
          name
        }
      }
    }
  }
  `;
}
export default  EcoClimaticPage;
