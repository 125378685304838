import { createGlobalStyle } from 'styled-components';

const  GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Manjari:100,400,700|Questrial|Raleway:300,400,500,600,700,800&display=swap');
  html{
   height: 100%;
  }
  body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    background: #ffffff;
    font-weight: 400;
    color: ${props => props.theme.fontColor};
    font-family: ${props => props.theme.bodyFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
  }
  #root{
    position: relative;
  }

  @media (max-width: 768px){
     body {
      font-size: 14px !important;
     }
     p{
      font-size: 14px !important;
     }
  }
  p{
    font-family: ${props => props.theme.bodyFont};
    font-size: 15px;
  }
  h1, h2, h3, h4, h5, h6{
    font-family: ${props => props.theme.headerFont};
  }
  * {
    box-sizing: border-box;
  }
  .text-center{
    text-align: center;
  }
  .small{
    font-size: 0.8rem;
  }
  .pt-1{padding-top: 1rem;}
  .pt-2{padding-top: 2rem;}
  .pt-3{padding-top: 3rem;}
    span.input-error{
    font-size: 0.8rem;
    color: #ff0000;
  }
  .text-input {
    display: block;
    width: 100%;
    font-size: 17px;
    line-height: 1.42857143;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    color: #555;
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    margin-bottom: 0.3rem;
  }

  .input-a {
    height: 35px;
    padding: 8px 10px;
    font-size: 0.9rem;
    font-weight: 300;
    resize: none;
    border: 1px solid #d2beb6!important;
    border-radius: 4px!important;
    background: #fff!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;

  }
  .text-area{
    margin-top: 0.5rem;
  }
  .area-input {
    font-family: ${props => props.theme.bodyFont};
    font-size: 0.9rem;
    font-weight: 400;
    display: block;
    width: 100%;
    line-height: 1.42857143;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    color: #333333;
    outline: 0;
    background-image: none;
    padding: 8px 10px;
    resize: none;
    border: 1px solid #d2beb6!important;
    border-radius: 4px!important;
    background: #fff!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    margin-bottom: 0.3rem;
  }

.checkbox {
  display: flex;
  align-items: center;
  height: 25px;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: #999999;
}
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox input:checked ~ .checkmark {
  background-color: #0B612D;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
	left: 7px;
	top: 3px;
	width: 7px;
	height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
input[disabled]{
  opacity: 0.1;
}
.radio-input {
  display: flex;
  align-items: center;
  height: 25px;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.side-radio-input {
  font-size: 0.9rem;
  height: 20px;
}
.side-radio-input .radiomark{
  background-color: #999999;
  height: 17px;
  width: 17px;
}
.radio-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.radio-input:hover input ~ .radiomark {
  background-color: #ccc;
}
.radio-input input:checked ~ .radiomark {
  background-color: #0B612D;
}
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio-input input:checked ~ .radiomark:after {
  display: block;
}
.radio-input .radiomark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.side-radio-input .radiomark:after{
  top: 4px;
  left: 4px;
  width: 9px;
  height: 9px;
}
.limiter {
  width: 100%;
  margin: 0 auto;
}
.container-table100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 33px 30px;
}

.wrap-table100 {
  width: 1170px;
}

table {
  border-collapse: collapse;
  background: white;
  border-radius: 1px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
table * {
  position: relative;
}
table td, table th {
  padding-left: 8px;
}
table thead tr {
  height: 40px;
  background: ${props => (props.theme.darkgreyColor)};
  background: -webkit-linear-gradient(to right, ${props => (props.theme.greyColor)}, ${props => (props.theme.darkgreyColor)});
  background: linear-gradient(to right, ${props => (props.theme.greyColor)}, ${props => (props.theme.darkgreyColor)});
}
table tbody tr {
  height: 50px;
}
table tbody tr:last-child {
  border: 0;
}
table td, table th {
  text-align: left;
}
table td.l, table th.l {
  text-align: right;
}
table td.c, table th.c {
  text-align: center;
}
table td.r, table th.r {
  text-align: center;
}


.table100-head th{
  font-family: ${props => (props.theme.bodyFont)};
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

tbody tr {
  font-family: ${props => (props.theme.bodyFont)};
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:hover {
  color: #555555;
  background-color: #f5f5f5;
  cursor: pointer;
}

.column1 {
  width: 260px;
  padding-left: 40px;
}

.column2 {
  width: 160px;
}

.column3 {
  width: 245px;
}

.column4 {
  width: 110px;
  text-align: right;
}

.column5 {
  width: 170px;
  text-align: right;
}

.column6 {
  width: 222px;
  text-align: right;
  padding-right: 62px;
}


@media screen and (max-width: 992px) {
  table {
    display: block;
  }
  table > *, table tr, table td, table th {
    display: block;
  }
  table thead {
    display: none;
  }
  table tbody tr {
    height: auto;
    padding: 37px 0;
  }
  table tbody tr td {
    padding-left: 40% !important;
    margin-bottom: 24px;
  }
  table tbody tr td:last-child {
    margin-bottom: 0;
  }
  table tbody tr td:before {
    font-family: ${props => props.theme.bodyFont};
    font-size: 14px;
    color: #999999;
    line-height: 1.2;
    font-weight: unset;
    position: absolute;
    width: 40%;
    left: 30px;
    top: 0;
  }
  table tbody tr td:nth-child(1):before {
    content: "Date";
  }
  table tbody tr td:nth-child(2):before {
    content: "Order ID";
  }
  table tbody tr td:nth-child(3):before {
    content: "Name";
  }
  table tbody tr td:nth-child(4):before {
    content: "Price";
  }
  table tbody tr td:nth-child(5):before {
    content: "Quantity";
  }
  table tbody tr td:nth-child(6):before {
    content: "Total";
  }

  .column4,
  .column5,
  .column6 {
    text-align: left;
  }

  .column4,
  .column5,
  .column6,
  .column1,
  .column2,
  .column3 {
    width: 100%;
  }

  tbody tr {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .container-table100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}


.search-input{
  font-family: ${props => props.theme.headerFont};
  font-size: 1em;
  font-weight: 500;
  outline: 0;
  width: 100%;
  padding: 0.8em;
  background-color: rgba(255, 255, 255, 0.5);
  color: #212121;
  float: left;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.07);
  padding-right: 2em;
}
  .search-input::placeholder{
    opacity: 1;
    font-size: 0.8em;
    color: #000000;
    padding: 1em;
  }

  button.on-search{
    font-family: "Raleway", sans-serif;
    padding: 0.4em;
    font-weight: 400;
    border-radius: 0 4px 4px 0;
    border: none;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0.25rem;
  }
  .ReactModal__Overlay{
    z-index: 1000;
  }
  .pdf-document{
    height: calc(100vh - 6rem);
    overflow-y: auto;
    margin: 0.5rem;
  }
  .pdf-page{
    margin-bottom: 0.5rem;
  }
  .loading{
    width: calc(100vw - 16.66%);
    height: calc(100vh - 6rem);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div-loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.div-loading div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: div-loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.div-loading div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.div-loading div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.div-loading div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}
@keyframes div-loading {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
.react-datepicker-popper{
  top: -100px !important;
}


@keyframes ldio-rpinwye8j0b {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-rpinwye8j0b div {
  position: absolute;
  animation: ldio-rpinwye8j0b 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #e15b64;
  transform-origin: 80px 82px;
}
.loadingio-eclipse {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-rpinwye8j0b {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-rpinwye8j0b div { box-sizing: content-box; }
.rcs-custom-scroll{min-height:0;min-width:0}.rcs-custom-scroll .rcs-outer-container{overflow:hidden}.rcs-custom-scroll .rcs-outer-container .rcs-positioning{position:relative}.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar{opacity:1;transition-duration:0.2s}.rcs-custom-scroll .rcs-inner-container{overflow-x:hidden;overflow-y:scroll;-webkit-overflow-scrolling:touch}.rcs-custom-scroll .rcs-inner-container:after{content:'';position:absolute;top:0;right:0;left:0;height:0;background-image:linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.05) 60%, rgba(0,0,0,0) 100%);pointer-events:none;transition:height 0.1s ease-in;will-change:height}.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after{height:5px;transition:height 0.15s ease-out}.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container{user-select:none}.rcs-custom-scroll .rcs-custom-scrollbar{position:absolute;height:100%;width:6px;right:3px;opacity:0;z-index:1;transition:opacity 0.4s ease-out;padding:6px 0;box-sizing:border-box;will-change:opacity;pointer-events:none}.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl{right:auto;left:3px}.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar{opacity:1}.rcs-custom-scroll .rcs-custom-scroll-handle{position:absolute;width:100%;top:0}.rcs-custom-scroll .rcs-inner-handle{height:calc(100% - 12px);margin-top:6px;background-color:rgba(78,183,245,0.7);border-radius:3px}
.errors{
  padding: 0.5rem;
  font-size: 0.9rem;
  color: #bd0606;
}
.ReactPasswordStrength-input {
  font-size: 18px !important;
  padding: 0 !important;
  width: 100% !important;
}
.ReactPasswordStrength-strength-desc {
  bottom: -30px;
  top: auto !important;
  width: 100% !important;
  font-size: 0.8rem;
}
#front{

}
`;
export default GlobalStyle;
