import React from 'react';
import {Row, Col12} from "../../../theme/grid";
import {RadioInput} from "../../../components/input";
import styled from "styled-components";


const Section = styled.div`
  border: 1px solid #eeeeee;
  padding: 1rem;
  margin-bottom: 2rem;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;


`;
const Title = styled.h5`
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin: 0;
`;

const ServiceFilter = ({onChange, filter, services}) =>{
  return(
    <Section>
      { services ?
      <Row>
        <Title>Service Category</Title>
        {services.map(service =>{
          return(
            <Col12 key={service.node.id} style={{ display: "flex", flexDirection: "column"}}>
              <RadioInput
                onChange={onChange}
                name="service"
                defaultChecked={filter.service === service.node.slug}
                label={service.node.title}
                style={{borderRadius: '3px'}}
                value={service.node.slug}/>

            </Col12>
          )
        })}
      </Row>: ""}
    </Section>
  )

};

export default ServiceFilter;
