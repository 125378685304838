import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import config from "../config";



const Seo = ({title, description, keywords, path, cover}) => {
  return (
    <Helmet>
      <link rel="canonical" href={`${config.PUBLIC_URL+path}`} />
      <link rel="shortcut icon" href={`${config.PUBLIC_URL}/icons/favicon.ico`} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${config.PUBLIC_URL}/icons/apple-icon-180x180.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${config.PUBLIC_URL}/icons/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${config.PUBLIC_URL}/icons/favicon-16x16.png`} />
      <link rel="manifest" href={`${config.PUBLIC_URL}/icons/site.webmanifest`} />
      <link rel="mask-icon" href={`${config.PUBLIC_URL}/icons/safari-pinned-tab.svg`} color="#ff214f" />

      <title>{`${title} | ICPAC - GMES & Africa`}</title>
      <meta name="title" content={`${title} | ICPAC - GMES & Africa`} />
      <meta name="description" content={`${description}`}/>

      <meta property="og:title" content={`${title} | ICPAC - GMES & Africa`}/>
      <meta property="og:description" content={`${description}`}/>
      <meta property="og:site_name" content="ICPAC - GMES & Africa"/>
      <meta property="og:url" content={`${config.PUBLIC_URL+path}`}/>
      <meta property="og:image:height" content="366" />
      <meta property="og:image:width" content="700" />
      <meta property="og:image" content={`${config.PUBLIC_URL}/img/radioz.png`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="‎@icpac_igad" />
      <meta name="twitter:title" content={`${title} | ICPAC - GMES & Africa`} />
      <meta name="twitter:description" content={`${description}`} />
      <meta name="twitter:image" content={`${config.PUBLIC_URL}/img/gmes.png`} />

      <meta name="keywords" content={`${keywords}, ICPAC, GMES, Africa`}/>
      <meta name="copyright" content={config.PUBLIC_URL} />
      <script type="application/ld+json">
        {
          `{
            "@context": "http://schema.org/",
            "@type": "Organization",
            "name": "${title} | ICPAC - GMES & Africa",
            "alternateName": "ICPAC - GMES & Africa",
            "description": "${description}",
            "url": "${config.PUBLIC_URL + path}",
            "logo": {
              "@type": "ImageObject",
              "contentUrl": "${config.PUBLIC_URL}/img/logo.png",
              "height": "200",
              "width": "280"
            },
            "image":{
              "@type": "ImageObject",
              "url": "${config.PUBLIC_URL}/img/gmes.png",
              "height": "366",
              "width": "700"
            },
            "priceRange": "free"
          }`
        }
      </script>
    </Helmet>
  );
};
Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  path: PropTypes.string
};

export default Seo;
