import React, {Fragment} from 'react';
import {Row, Col12 } from "../../../theme/grid";
import ReactSelect from "../../../components/input/ReactSelect";
import styled from "styled-components";

const Section = styled.div`
display: flex;
margin: 1rem 0;
border-bottom: 1px solid ${props => (props.theme.greyColor)};
`;
const Tabs = styled.div`
  display: flex;
  flex-direction: row;
`;
const Tab = styled.div`
  font-family: ${props => (props.theme.headerFont)};
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 500;
  border-left: 1px solid #f1f1f1;
  text-decoration: none;
  color: ${props => (props.theme.greyColor)};
  background: #ffffff;
  cursor: pointer;
`;
const ActiveTab = styled(Tab)`
  color: #ffffff;
  background: ${props => (props.theme.greyColor)};
`;


const countryOptions =(options) =>{
  return options.map(option =>{
    return{
      value: option.node.country,
      label: option.node.country.name
    }
  })
};
const dataOptions =(options) =>{
  return options.map(option =>{
    return{
      value: option.node,
      label: option.node.name
    }
  })
};
const DataFilter = ({area_type, onAllArea, onProtectedArea, onForestArea, onCountryChange, onAreaChange, area, areas, country, countries, errors }) => {
  const AllTab = area_type === "all" ? ActiveTab : Tab;
  const ProtectedTab = area_type === "protected" ? ActiveTab : Tab;
  const ForestTab = area_type === "forest" ? ActiveTab : Tab;
  return(
    <Fragment>
      <Section>
        <Tabs>
          <AllTab onClick={onAllArea}>All</AllTab>
          <ProtectedTab onClick={onProtectedArea}>Protected</ProtectedTab>
          <ForestTab onClick={onForestArea}>Forest</ForestTab>
        </Tabs>
      </Section>

      <Row style={{padding: "1rem 0.3rem"}}>
        <Col12>
          <ReactSelect
            onChange={onAreaChange}
            name="area"
            value={area}
            options={dataOptions(areas)}
            label="Area"
          />
        </Col12>
      </Row>

      <Row style={{padding: "1rem 0.3rem"}}>
        <Col12>
          <ReactSelect
            onChange={onCountryChange}
            name="country"
            value={country}
            placeholder="Select"
            options={countryOptions(countries)}
            label="Country"
          />
        </Col12>
      </Row>




      {/*{errors.region ?*/}
      {/*  <Row style={{justifyContent: "center"}}>*/}
      {/*    <p className="errors">{errors.region}</p>*/}
      {/*  </Row>: ""}*/}
    </Fragment>
  )

};

export default DataFilter;
