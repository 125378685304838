import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {withRouter} from "react-router-dom";
import {setViewPort} from "../actions/setViewPort";
import Seo from "../seo";
import Footer from "./footer";
import Header from "./header";
import {bindActionCreators} from "redux";
import * as authActions from "../actions/authActions";
import {connect} from "react-redux";
import AuthModal from "../components/auth/AuthModal";
import SideMenu from "./header/SideMenu";


setViewPort();
const Main = styled.main`
  margin-top: ${props => props.landing ? "0" : "3rem"};
  @media (min-width: 1100px) {
    margin-top: ${props => props.landing ? "0": "6rem"};
  }
`;
const Transition = styled.div`
  .active {
    transition: all 200ms ease-in;
  }
  .transparent {
    transition: all 200ms ease-out;
    transform: translate(0, -100%);
  }
`;
const Side = styled.div`
z-index: 1000;
position: relative;
display: none;
flex-direction: row;
justify-content: flex-end;
background-color: #ffffff;
display: ${props => props.show ? "flex" : "none"};
height: ${props => props.show ? "100%" : "0"};
width: ${props => props.show ? "100%" : "0"};
`;
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenu: false,
      landing: this.props.landing,
      scrollPos: 0
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  sideMenuToggle=()=>{
    this.setState({ sideMenu: !this.state.sideMenu })
  };
  handleScroll=()=> {
    let scrollPos = window.scrollY;
    if(this.props.location.pathname === "/"){
      if(scrollPos > 100){
        this.setState({landing: false})
      }else {
        this.setState({landing: true})
      }
    }
  };
  render() {
    return (
      <Fragment>
        <Seo title={this.props.page.title} description={this.props.page.description} path={this.props.location.pathname}/>
        <Transition>
          <Header
            sideMenuToggle={this.sideMenuToggle}
            sideMenu={this.state.sideMenu}
            landing={this.state.landing}
            className={this.state.landing ? "active" : "transparent"}/>
        </Transition>
        <Side show={this.state.sideMenu}>
          <SideMenu sideMenuToggle={this.sideMenuToggle}/>
        </Side>
        <Main landing={this.props.landing}>
          {this.props.children}
        </Main>
        <Footer/>
        <AuthModal
          closeModal={this.props.actions.onAuthModal}
          modalIsOpen={this.props.modal}
          afterOpenModal={this.afterOpenModal}
        />
      </Fragment>
    )
  }
}
Layout.propTypes = {
  actions: PropTypes.object.isRequired,
  landing: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    isAuthenticated: state.isAuthenticated,
    modal: state.modal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
