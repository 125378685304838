import React, {Component} from "react";
import Chart from 'react-apexcharts';
import {loadCSV, parseFireChart} from "../../../../actions/loadCSV";


class FireChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: {},
      chart_error: [],
    }
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getFireChart();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState){
    if (prevProps.fireChart !== this.props.fireChart) {
      this.getFireChart();
    }
  }

  getFireChart=()=>{
    loadCSV(this.props.fireChart).then((results)=>{
      let data = parseFireChart(results.data);
      if (this._isMounted) {
        this.setState({chart: data});
        this.setState({chart_error: results.errors})
      }
    })
  };
  // getData=()=>{
  //   loadCSV(this.props.fireChart).then((results)=>{
  //     let data = parseFireChart(results.data);
  //     this.setState({chart: data })
  //   });
  //   // loadLegend(this.props.coverLegend).then((results)=>{
  //   //   let data = this.getLegendColors(results.data);
  //   //   this.setState({legends: data});
  //   // })
  // };

  loadOptions(categories){
    return {
      colors : ['rgb(249, 237, 105)', 'rgb(240, 138, 94)' ],
      title: {
        text: 'Last 12 Months Fire Vs Long Term',
        style: {
          fontSize:  '14px',
          fontWeight:  'normal',
          color:  '#263238'
        }
      },
      chart: {
        id: `${this.props.location+"-fire-chart"}`,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: {
          text: "Number of Fires",
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 400,
          },
        },
        labels: {
          formatter: function (val) {
            return val ? val.toFixed(0): null
          }
        }
      },
      fill: {
        opacity: 1
      }
    }
  }


  render() {
    return (
      <div id="fire-chart" style={{paddingTop: '2rem'}}>
        {this.state.chart.series  ?
          <Chart
            options={this.loadOptions(this.state.chart.categories)}
            series={this.state.chart.series}
            type="bar"
            height="300"
          />
          :<p>Loading......</p>}
      </div>
    )
  }
}
export default FireChart;
