import React from 'react';
import {Row, Col4} from "../../../theme/grid";
import {CheckBox} from "../../../components/input";
import styled from "styled-components";


const Section = styled.div`
  border: 1px solid #eeeeee;
  padding: 1rem;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  border-bottom: none;
`;

const ProductFilter = ({onChange, filter, products, errors}) =>{
  return(
    <Section>
      <Row>
        {products.map(product =>{
          let name = product.node.name;
          return(
            <Col4 key={product.node.id}>
              <CheckBox
                id={product.node.id}
                onChange={onChange}
                name={name}
                label={product.node.label}
                description={product.node.description}
                value={filter.value}
              />
            </Col4>
          )
        })}
      </Row>
      {errors.product ?
      <Row style={{justifyContent: "center"}}>
        <p className="errors">{errors.product}</p>
      </Row>: ""}
    </Section>
  )

};

export default ProductFilter;
