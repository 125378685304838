import React from "react";
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import {IoLogoFacebook, IoLogoTwitter, IoLogoYoutube, IoLogoInstagram, IoLogoLinkedin, IoLogoGithub} from 'react-icons/io';
import {FaMediumM} from 'react-icons/fa';
import {Container, Row, Col6, Col3} from "../../theme/grid";

const Section = styled.div`
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
`;
const Span3 = styled(Col3)`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Span6 = styled(Col6)`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const P = styled.p`
  font-size: 0.8rem;
  span{
    font-size: 18px;
  }
`;
const CopyrightMenu = styled.div`
  min-height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CopyrightLink = styled(Link)`
  color: ${props => props.theme.darkgreyColor};
  font-size: 0.8rem;
  text-decoration: none;
  padding: 5px;
`;
const Social = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const SocialLink = styled.a`
  color: ${props => props.theme.darkgreyColor};
  padding: 2.3rem 0.1rem;
  margin: 0 0.5rem;
`;
const Copyright = () =>(
  <Section>
    <Container>
      <Row style={{justifyContent: 'center'}}>
        <Span6>
          <CopyrightMenu>
            <CopyrightLink to="/about-us">About GMES</CopyrightLink>
            <CopyrightLink to="/policy">Privacy Policy</CopyrightLink>
            <CopyrightLink to="/get-in-touch">Feedback</CopyrightLink>
          </CopyrightMenu>
        </Span6>
        <Span3>
          <Social>
            <SocialLink href="https://www.facebook.com/ICPACigad/"><IoLogoFacebook size={20} /></SocialLink>
            <SocialLink href="https://twitter.com/icpac_igad"><IoLogoTwitter size={20} /></SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UCoiL8Xk0_55BtXNBgEO4pRA"><IoLogoYoutube size={20} /></SocialLink>
            <SocialLink href="https://medium.com/@icpac"><FaMediumM size={20} /></SocialLink>
            <SocialLink href="https://www.instagram.com/icpac_climatecenter/"><IoLogoInstagram size={20} /></SocialLink>
            <SocialLink href="http://linkedin.com/company/igad-climate-prediction-and-aplication-center-icpac-"><IoLogoLinkedin size={20} /></SocialLink>
            <SocialLink href="https://github.com/icpac-igad"><IoLogoGithub size={20} /></SocialLink>
          </Social>
        </Span3>
      </Row>
      <Row style={{justifyContent: 'center'}}>
        <Span6 style={{height: '2rem'}}>
          <P><span>&copy;</span> ICPAC - GMES & Africa 2023</P>
        </Span6>
      </Row>

    </Container>
  </Section>
);

export default withRouter(Copyright);
