import React, {Component, Fragment} from 'react';
import Layout from "../../layout";
import styled from 'styled-components';
import {Container, FluidContainer, Row, Col2, Col10, Col12} from "../../theme/grid";
import config from "../../config";
import LandCover from "./result";
import axios from "axios";
import {requestConfig} from "../../utils/requestConfig";
import {isEmpty} from "../../utils/isEmpty";
import DataFilter from "./filters/DataFilter";
import NotAvailable from "../../utils/NotAvailable";


const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  position: sticky;
  top: 6rem;
  width: 100%;
  height: 70px;
  z-index: 100;
  @media (max-width: 1099px) {
    top: 4rem;
  }
`;
const H3 = styled.h3`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;
const H5 = styled.h5`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;
const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.3rem;
`;
const PageSubTitle = styled(H5)`
  color: #ffffff;
`;
const TitleContent = styled(H5)`
  color: #ffffff;
  font-size: 1rem;
`;
const Side = styled(Col2)`
position: relative;
height: auto;
font-size: 14px;
min-height: 60vh;
width: 100%;
border-right: 1px solid #eeeeee;
background-color: #ffffff;
padding: 0.5rem;
box-shadow: 0 5px 30px 0 rgba(39,39,39,0.15);
`;
const Main = styled(Col10)`
position: relative;
height: auto;
`;
const FilterContainer = styled.div`
position: sticky;
top: calc(6rem + 70px);
`;

class LandCoverFirePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modalIsOpen: false,
      modal: {},
      loading: false,
      filter:{country:"", area:"",  allArea: false, protectedArea: false,  forestArea: false},
      country:null,
      area: null,
      countries:[],
      landcover: {},
      area_type: "all",
      areas:[],
      cover_legend: {},
      cover_change_legend: {},
      cover_type_chart: {},
      cover_chart_data: {categories:[], series: []}

    };
    this.onChange = this.onChange.bind(this);
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getCountries();
    let filter = `country_Gid: ""`;
    this.loadData(filter);
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  onChange(event) {
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let filter = this.state.filter;
    this.setState({ filter: { ...filter, [name]: value } })
  }
  onAreaChange=(e)=>{
    this.setState({area: e});
    let country = {value: e.value.country, label: e.value.country.name};
    if(!this.state.country){
      this.onCountryChange(country);
    }
  };
  onCountryChange=(e)=> {
    this.setState({country: e});
  };
  componentDidUpdate(prevProps, prevState){
    if (prevState.area !== this.state.area) {
      let slug = this.state.area !== null ? this.state.area.value.slug: '';
      this.loadCoverData(slug);
    }
    if (prevState.country !== this.state.country && prevState.country !== null) {
      let country = this.state.country ? this.state.country.value.gid: "";
      this.loadAreas(this.updateFilter(country)).then(()=>{
          this.loadCoverData(this.state.area.value.slug);
        }
      )
    }
  }

  updateFilter(country){
    if(this.state.area_type === 'protected'){
      return `country_Gid: "${country}", protectedArea: true`
    } else if (this.state.area_type === 'forest'){
      return `country_Gid: "${country}", forestArea: true`
    }
    else {
      return `country_Gid: "${country}"`;
    }
  }

  loadData(filter){
    this.loadAreas(filter).then(()=>{
        this.loadCoverData();
      }
    )
  }

  getCountries(){
    return axios.post(`${config.ROOT_URL}/q/` , countryQuery(), requestConfig)
      .then((resp)=>{
        let data = resp.data.data.countries.edges;
        let countries = Object.values(data.reduce((acc,cur)=>Object.assign(acc,{[cur.node.country.id]:cur}),{}));
        if(this._isMounted) {
          this.setState({countries: countries});
        }
      }).catch(error => {
        throw(error);
      });
  }
  loadAreas=(filter)=>{
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(filter), requestConfig)
      .then((resp)=>{
        let areas = resp.data.data.areas.edges;
        this.setState({areas: areas});
        let area = areas[0] ? {value: areas[0].node, label: areas[0].node.name}: null;
        // let area = {value: areas[0].node, label: areas[0].node.name};
        if(this._isMounted) {
          this.setState({area: area});
        }
      }).catch(error => {
        throw(error);
      });
  };
  loadCoverData=()=>{
    this.setState({loading: true});
    let slug = this.state.area !== null ? this.state.area.value.slug: "";
    return axios.post(`${config.ROOT_URL}/q/` , getLandCover(slug), requestConfig)
      .then((resp)=>{
        let landcover = resp.data.data.landcover;
        if(this._isMounted) {
          this.setState({landcover: landcover});
        }
        this.setState({loading: false});
      }).catch(error => {
        this.setState({loading: false});
        throw(error);
      });
  };
  onAllArea=()=>{
    this.setState({area_type: "all"});
    let country = this.state.country ? this.state.country.value.gid: '';
    let filter = `country_Gid: "${country}"`;
    this.loadData(filter);
  };
  onProtectedArea=()=>{
    this.setState({area_type: "protected"});
    let country = this.state.country ? this.state.country.value.gid: '';
    let filter = `country_Gid: "${country}", protectedArea: true`;
    this.loadData(filter);
  };
  onForestArea=()=>{
    this.setState({area_type: "forest"});
    let country = this.state.country ? this.state.country.value.gid: '';
    let filter = `country_Gid: "${country}", forestArea: true`;
    this.loadData(filter);
  };

  openModal=()=> {
    this.setState({modalIsOpen: true});
  };

  afterOpenModal=()=> {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  };
  closeModal=()=> {
    this.setState({modalIsOpen: false});
  };

  renderCoverData(){
    if(this.state.area !== null){
      return <LandCover
        landcover={this.state.landcover}
        area_type={this.state.area_type}
        cover_type_chart={this.state.cover_type_chart}
        cover_chart_data={this.state.cover_chart_data} />
    }else {
      return <NotAvailable/>
    }
  }

  render() {
    let page ={
      title: "Land Cover & Fires",
      description: "Land use land cover change analysis and near-real time fire monitoring",
      keywords:"Land Cover & Fires"
    };

    return (
      <Layout page={page}>
        <PageBanner>
          <Container>
            <Row style={{}}>
              <Col12 style={{textAlign: "center", margin: '0.5rem'}}>
                <PageTitle>{!isEmpty(this.state.landcover) ? <Fragment>{this.state.landcover.name} | {this.state.landcover.country.name}</Fragment> : ""}</PageTitle>
                <TitleContent>Land Cover & Fires</TitleContent>
                <PageSubTitle>Land use, land cover change analysis and fire</PageSubTitle>
              </Col12>
            </Row>
          </Container>
        </PageBanner>
        <FluidContainer>
          <Row>
            <Side>
              <FilterContainer>
                <DataFilter
                  onAllArea={this.onAllArea}
                  onProtectedArea={this.onProtectedArea}
                  onForestArea={this.onForestArea}
                  area_type={this.state.area_type}
                  areas={this.state.areas}
                  countries={this.state.countries}
                  area={this.state.area}
                  country={this.state.country}
                  onAreaChange={this.onAreaChange}
                  onCountryChange={this.onCountryChange}
                />
              </FilterContainer>
            </Side>
            <Main>
              {
                this.state.loading ?
                  <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={`${config.PUBLIC_URL}/img/preview.gif`} alt="" />
                  </div>:this.renderCoverData()
              }
            </Main>
          </Row>
        </FluidContainer>
      </Layout>
    )
  }
}
function countryQuery() {
  return `
  query{
    countries:allLandCoverFires{
      edges{
        node{
          country{
            id,
            name,
            gid
          }
        }
      }
    }
  }`
}
function getQuery(filter) {
  return `
  query{
    areas:allLandCoverFires(${filter}){
        edges{
          node{
            id,
            name,
            slug,
            country{
              id,
              name,
              gid
            }
        }
      }
    }
  }
`
}
function getLandCover(area) {
  return `
  query{
    landcover:landCoverFire(slug:"${area}"){
    id,
    name,
    country{
      id,
      name,
      gid
    }
    protectedArea,
    forestArea,
    coverLegend,
    coverChangeLegend,
    coverTypeChart,
    fireChart,
    rfeChart,
    ndviChart,
    description,
    coverMeta,
    coverChangeMeta,
    cover_maps:landcoverSet{
      edges{
        node{
          id,
          title,
          image,
        }
      }
    }
    coverchange_maps:landcoverchangeSet{
      edges{
        node{
          id,
          title,
          image
        }
      }
    }
    }
  }
`
}
export default  LandCoverFirePage;
