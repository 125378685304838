import React, {Component} from 'react';
import styled from "styled-components";
import {Container} from "../../../../theme/grid";
import {loadLegend} from "../../../../actions/loadCSV";


const LegendContainer =styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: row;
padding: 0.125rem;
align-items: center;
`;
const Color =styled.div`
width: 25px;
height: 15px;
`;
const Name =styled.div`
width: 100%;
padding-left: 0.25rem;
font-size: 0.75rem;
`;
class CoverLegend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      errors: []
    }
  }
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    this.getData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.legend !== this.props.legend) {
      this.getData();
    }
  }

  getData=()=>{
    loadLegend(this.props.legend).then((results)=>{
      if(this._isMounted) {
        this.setState({results: results.data});
        this.setState({errors: results.data.errors});
      }
    })
  };

  render() {
    return (
      <Container style={{padding: '1rem'}}>
        {this.state.results ?
          this.state.results.map((legend, index) => {
            return <LegendContainer key={index}><Color
              style={{backgroundColor: `${legend.color}`}}/><Name style={this.props.style}>{legend.name}</Name></LegendContainer>
          })
          : ""
        }
      </Container>
    )
  }
}

export default CoverLegend;
