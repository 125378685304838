import React from "react";
import styled from "styled-components";
import {Row, Col3} from "../../theme/grid";
import config from "../../config";

const History = styled(Row)`
  padding: 1rem 0;
`;
const Item = styled(Col3)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 15rem;
`;
const Text = styled.div`
  font-family: ${props => props.theme.headerFont};
  font-size: 0.85rem;
  padding-top: 1rem;
  min-height: 2rem;
  text-align: center;
  font-weight: 500;
`;
const Year = styled.div`
  font-family: ${props => props.theme.numberFont};
  padding: 0.5rem;
    font-weight: 500;
`;
const ProjectLogo = styled.img`
   max-height: 7.5rem;
   max-width: 7.5rem;
`;

const ProjectEvaluation = ({logos})=> {
  return (
    <History>
      {logos.map(logo => {
        return <Item key={logo.id}>
          <LogoContainer>
            <ProjectLogo src={`${config.ROOT_URL}/media/${logo.image}`} alt={logo.title}/>
          </LogoContainer>
          <Text>{logo.title}</Text>
          <Year>{logo.period}</Year>
        </Item>
      })}
    </History>

  )
};
export default ProjectEvaluation;
