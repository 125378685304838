import React from 'react';
import {Row, Col5} from "../theme/grid";
import styled from "styled-components";
import { FaList, FaTh} from 'react-icons/fa';

const Section = styled.div`
  padding-top: 1rem;
`;
const Container = styled(Col5)`
  cursor: pointer;
`;
const DisplayChanger = ({onDisplayChange, display}) =>{
  return(
    <Section>
        <Row style={{justifyContent: 'space-around'}}>
          <Container onClick={()=>{onDisplayChange('list')}}><FaList size={25} color='#1D1D1B' /></Container>
          <Container onClick={()=>{onDisplayChange('grid')}}><FaTh size={25} color='#1D1D1B' /></Container>
        </Row>
    </Section>
  )

};

export default DisplayChanger;
