import React, {Component} from 'react';
import Layout from "../../layout";
import styled, { css } from "styled-components";
import {Container, Row, Col6, Col5, Col12} from "../../theme/grid";
import {Submit} from "../../components/buttons";
import {withRouter} from "react-router-dom";
import axios from "axios";
import config from "../../config";
import PasswordRegistration from "../../components/input/PasswordRegistration";
import PasswordInput from "../../components/input/PasswordInput";
import toastr from "toastr";



const ResetContainer = styled(Container)`
    min-height: 70vh;
`;
const H2 = styled.h2`
  color: ${props => props.theme.greenColor};
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 1rem;
  text-align: center;
  ${props => props.error && css`
    color: ${props => props.theme.brownColor};
  `}
`;


class PasswordResetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasError: false,
      resetErrors: '',
      errors: {},
      passObj: {},
      new_password: '',
      re_new_password: ''
    };
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;

  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState){

  }
  onPasswordChange=(e)=> {
    this.setState({passObj: e});
    this.setState({  "new_password": e.password});
  };
  onPasswordConfirmChange=(e)=> {
    this.setState({"re_new_password": e.target.value });
  };


  resetValid() {
    let formIsValid = true;
    let errors = {};
    if(this.state.new_password.length < 1) {
      errors.new_password = 'Password is Required';
      formIsValid = false;
    }
    if(!this.state.passObj.isValid) {
      errors.new_password = 'Password Strength is too low';
      formIsValid = false;
    }
    if(this.state.re_new_password.length < 1) {
      errors.re_new_password = 'Password Confirmation is Required';
      formIsValid = false;
    }

    if(this.state.new_password !== this.state.re_new_password) {
      errors.passwords = 'Password and Password Confirmation must match';
      formIsValid = false;
    }
    this.setState({errors: errors});
    return formIsValid;
  }
  onResetPassword =(e)=>{
    e.preventDefault();
    if (!this.resetValid()) {
      return;
    }
    let reset = this.props.match.params;
    let data = {'new_password': this.state.new_password, 're_new_password': this.state.re_new_password};
    return axios.post(`${config.ROOT_URL}/accounts/password-reset/${reset.uid}/${reset.token}`, data)
      .then( (resp) => {
        this.toHomePage();
        toastr.success(`<strong>Success</strong> <br /> <em>You password has been changed successfully</em>`);
      }).catch(error => {
        this.setState({ hasError: true });
        this.setState({ resetErrors: error.response.data });
        throw (error);
      });
  };

  toHomePage =()=>{
    const { history } = this.props;
    history.push(`/`);
  };

  render() {
    let page ={
      title: "Account Password Reset"
    };
    return (
      <Layout page={page}>
        <ResetContainer>
          <Row style={{justifyContent: 'center'}}>
            <Col5 style={{padding: '2rem'}}>
              <H2>Account Password Reset</H2>
              <Row style={{justifyContent: 'center', padding: '1rem 0'}}>
                <Col12 style={{margin: '1rem 0'}}>
                  <PasswordRegistration
                    name="new_password"
                    label="Password"
                    onChange={this.onPasswordChange}
                    error={this.state.errors.new_password} />
                </Col12>
                <Col12>
                  <PasswordInput
                    name="re_new_password"
                    label="Confirm Password"
                    value={this.state.re_new_password}
                    onChange={this.onPasswordConfirmChange}
                    error={this.state.errors.re_new_password}/>
                </Col12>
                <Col12 style={{textAlign: 'center', padding: '0.3rem'}}>
                  {this.state.errors.passwords ?  <span className="errors" >{this.state.errors.passwords}</span>:""}
                </Col12>
              </Row>

              <Row style={{justifyContent: 'center', padding: '0.3rem 0'}}>
                {this.state.resetErrors && <span className="errors">{this.state.resetErrors.token} <br />{this.state.resetErrors.uid}</span>}
              </Row>
              <Row style={{justifyContent: 'center'}}>
                <Col6 style={{display: 'flex', justifyContent: 'center'}}>
                  {this.state.loading ? <img src={`${config.PUBLIC_URL}/img/button-loading.gif`} alt="Button Loading" height={35} width={35}/>
                    :
                    <Submit onClick={this.onResetPassword} text="Reset Password" />
                  }
                </Col6>

              </Row>
            </Col5>
          </Row>
        </ResetContainer>
      </Layout>
    )
  }
}

export default withRouter(PasswordResetPage);
