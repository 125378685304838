import config from "../config";
import axios from "axios";
import {requestConfig} from "../utils/requestConfig";


export function regionDMP(Q){
  return axios.post(`${config.ROOT_URL}/q/` , regionDMPQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.eco.edges[0].node;
      return{
        name: "Eastern Africa Region",
        dmps: data.dmp.edges ? data.dmp.edges:[],
        dmps_info: data.dmp.edges ? data.dmp.pageInfo:{},
        dmp: data.dmp_mon.edges[0] ? data.dmp_mon.edges[0].node: {},
        dmp_anom: data.dmp_mon_anom.edges[0] ? data.dmp_mon_anom.edges[0].node: {},
        dmp_mon: data.dmp_mon.edges ? data.dmp_mon.edges: [],
        dmp_mon_info: data.dmp_mon.edges ? data.dmp_mon.pageInfo: {},
        dmp_anoms: data.dmp_mon_anom.edges ? data.dmp_mon_anom.edges: [],
        dmp_anoms_info: data.dmp_mon_anom.edges ? data.dmp_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function regionDMPQuery(Q){
  return`
  query{
     eco:allRegions{
      edges{
        node{
        id,
        name,
        dmp:ecoclimaticSet(isRegion: true, isCountry: false, isDekad: true, isAdmin: false, isPa: false, isAnom: false, dmp: true, first: 36, before: "${Q.dmpBefore}", after:"${Q.dmpAfter}"){
          edges{
            node{
              id,
              dekad,
              dmp,
              rfe,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      dmp_mon:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, dmp: true, first: 12, before: "${Q.dmpMonBefore}", after:"${Q.dmpMonAfter}"){
        edges{
          node{
            id,
            dekad,
            dmp,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon_anom:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, dmp: true, first: 12, before: "${Q.dmpAnomBefore}", after:"${Q.dmpAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            dmp,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
    }
    }
  `
}

export function countryDMP(Q){
  return axios.post(`${config.ROOT_URL}/q/` , countryDMPQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.country;
      return{
        dmps: data.dmp.edges ? data.dmp.edges:[],
        dmps_info: data.dmp.edges ? data.dmp.pageInfo:{},
        dmp: data.dmp_mon.edges[0] ? data.dmp_mon.edges[0].node: {},
        dmp_anom: data.dmp_mon_anom.edges[0] ? data.dmp_mon_anom.edges[0].node: {},
        dmp_mon: data.dmp_mon.edges ? data.dmp_mon.edges: [],
        dmp_mon_info: data.dmp_mon.edges ? data.dmp_mon.pageInfo: {},
        dmp_anoms: data.dmp_mon_anom.edges ? data.dmp_mon_anom.edges: [],
        dmp_anoms_info: data.dmp_mon_anom.edges ? data.dmp_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function countryDMPQuery(Q){
  return`
  query{
    country(id:"${Q.country}"){
      id,
      name,
        dmp:ecoclimaticSet(isRegion: false, isCountry: true, isDekad: true, isAdmin: false, isPa: false, isAnom: false, dmp: true, first: 36, before: "${Q.dmpBefore}", after:"${Q.dmpAfter}"){
          edges{
            node{
              id,
              dekad,
              dmp,
              rfe,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      dmp_mon:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, dmp: true, first: 12, before: "${Q.dmpMonBefore}", after:"${Q.dmpMonAfter}"){
        edges{
          node{
            id,
            dekad,
            dmp,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon_anom:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, dmp: true, first: 12, before: "${Q.dmpAnomBefore}", after:"${Q.dmpAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            dmp,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}

export function paAreaDMP(Q){
  return axios.post(`${config.ROOT_URL}/q/` , paAreaDMPQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.area;
      return{
        dmps: data.dmp.edges ? data.dmp.edges:[],
        dmps_info: data.dmp.edges ? data.dmp.pageInfo:{},
        dmp: data.dmp_mon.edges[0] ? data.dmp_mon.edges[0].node: {},
        dmp_anom: data.dmp_mon_anom.edges[0] ? data.dmp_mon_anom.edges[0].node: {},
        dmp_mon: data.dmp_mon.edges ? data.dmp_mon.edges: [],
        dmp_mon_info: data.dmp_mon.edges ? data.dmp_mon.pageInfo: {},
        dmp_anoms: data.dmp_mon_anom.edges ? data.dmp_mon_anom.edges: [],
        dmp_anoms_info: data.dmp_mon_anom.edges ? data.dmp_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function paAreaDMPQuery(Q){
  return`
  query{
    area:protectedArea(id:"${Q.pa}"){
      id,
      name,
      country{
        id,
        name
      }
        dmp:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: false, isPa: true, isAnom: false, dmp: true, first: 36, before: "${Q.dmpBefore}", after:"${Q.dmpAfter}"){
          edges{
            node{
              id,
              dekad,
              dmp,
              rfe,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      dmp_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: false, dmp: true, first: 12, before: "${Q.dmpMonBefore}", after:"${Q.dmpMonAfter}"){
        edges{
          node{
            id,
            dekad,
            dmp,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: true, dmp: true, first: 12, before: "${Q.dmpAnomBefore}", after:"${Q.dmpAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            dmp,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}

export function adminAreaDMP(Q){
  return axios.post(`${config.ROOT_URL}/q/` , adminAreaDMPQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.area;
      return{
        dmps: data.dmp.edges ? data.dmp.edges:[],
        dmps_info: data.dmp.edges ? data.dmp.pageInfo:{},
        dmp: data.dmp_mon.edges[0] ? data.dmp_mon.edges[0].node: {},
        dmp_anom: data.dmp_mon_anom.edges[0] ? data.dmp_mon_anom.edges[0].node: {},
        dmp_mon: data.dmp_mon.edges ? data.dmp_mon.edges: [],
        dmp_mon_info: data.dmp_mon.edges ? data.dmp_mon.pageInfo: {},
        dmp_anoms: data.dmp_mon_anom.edges ? data.dmp_mon_anom.edges: [],
        dmp_anoms_info: data.dmp_mon_anom.edges ? data.dmp_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function adminAreaDMPQuery(Q){
  return`
  query{
    area:adminArea(id:"${Q.admin}"){
      id,
      name,
      country{
        id,
        name
      }
        dmp:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: true, isPa: false, isAnom: false, dmp: true, first: 36, before: "${Q.dmpBefore}", after:"${Q.dmpAfter}"){
          edges{
            node{
              id,
              dekad,
              dmp,
              rfe,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      dmp_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: false, dmp: true, first: 12, before: "${Q.dmpMonBefore}", after:"${Q.dmpMonAfter}"){
        edges{
          node{
            id,
            dekad,
            dmp,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: true, dmp: true, first: 12, before: "${Q.dmpAnomBefore}", after:"${Q.dmpAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            dmp,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}
