import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function viewportReducer(state = initialState.viewport, action) {
  switch  (action.type) {
    case types.SET_VIEW_PORT:
      return action.viewport;


    default:
      return state;
  }
}

