import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Row, Col4, Col6} from '../../../theme/grid'
import {LineButtonDark} from "../../../components/buttons";
import parse from "html-react-parser";


const NewsContainer = styled(Col4)`
  width: 100%;
  position: relative;
  background: #ffffff;
  min-height: 280px;
  margin: 0 0.5rem;
`;
const NewsRow = styled(Row)`

`;
const Title = styled.h5`
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
`;
const Image = styled.img`
  min-height: auto;
  width: 200px;
  object-fit: cover;
`;
const Content = styled.div`
  background: #ffffff;
  padding: 0.5rem;
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  p{
    font-size: 0.85rem;
  }
`;
const Text = styled.div`
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 400;
`;
const Date = styled(Text)`
  color: ${props => props.theme.greenColor};
  padding: 0.35rem;
  border-radius: 0.2rem;
`;

const LatestItem = ({img, title, date, summary, pdf, readlink}) => {
  return(
    <NewsContainer>
      <NewsRow>
        <Col6 style={{padding: 0}}>
          <Image src={img} alt={title} />
        </Col6>
        <Col6 style={{padding: 0}}>
          <Content>
            <Title className="heading_title">{title}</Title>
            <Date>{moment(date).format('DD MM YYYY')}</Date>
            {parse(`${summary}`)}
            <LineButtonDark
              text="Read More"
              link={readlink}
            />
          </Content>
        </Col6>
      </NewsRow>
    </NewsContainer>
  )
};

export default LatestItem;
