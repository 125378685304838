import React from 'react';
import PropTypes from 'prop-types';
import {Col2, Col8, FluidContainer, Row} from "../../theme/grid";
import TextInput from '../input/TextInput';
import PasswordInput from '../input/PasswordInput';
import {LineSubmit, Submit} from "../buttons"
import styled from "styled-components";
import config from "../../config";


const H3 = styled.h3`
    font-size: 1.3rem;
    margin: 0;
    font-weight: 300;
    text-decoration: underline;
`;

const LoginForm = ({login, onLogin, loginFail, onChange, loading, errors, onRegForm}) => {
  return (
    <FluidContainer>
      <form style={{fontSize: '0.85rem'}}>
        <Row style={{justifyContent: 'center'}}>
          <H3>Login</H3>
        </Row>
        <hr />
        <Row style={{justifyContent: 'center'}}>
          <Col8 style={{padding: '1rem 0'}}>
            <TextInput
              name="email"
              label="Email"
              value={login.email}
              onChange={onChange}
              error={errors.email}/>
          </Col8>
          <Col8 style={{padding: '1rem 0'}}>
            <PasswordInput
              name="password"
              label="Password"
              value={login.password}
              onChange={onChange}
              error={errors.password}/>
          </Col8>
        </Row>

        <Row style={{justifyContent: 'center'}}>
          {loginFail && <span className="errors">{loginFail}</span>}
        </Row>
        <Row style={{justifyContent: 'space-evenly'}}>
          <Col2>
            <LineSubmit onClick={onRegForm} text="Register" />
          </Col2>
          <Col2>
            {loading ? <img src={`${config.PUBLIC_URL}/img/button-loading.gif`} alt="Button Loading" />
              :
              <Submit onClick={onLogin} text="Login" />
            }
          </Col2>

        </Row>

      </form>
    </FluidContainer>
  );
};

LoginForm.propTypes = {
  login: PropTypes.object.isRequired,
  onLogin: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loginFail: PropTypes.string,
  loading: PropTypes.bool,
  errors: PropTypes.object

};

export default LoginForm;
