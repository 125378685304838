import React from "react";
import styled from 'styled-components';
import {Link} from "react-router-dom";

const Section = styled(Link)`
  border-radius: 5px;
  display: inline-block;
  position: relative;
  padding: 0.8rem 1.6rem;
  border-width: 1px;
  border-style: solid;
  outline: none;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  font-family: ${props => props.theme.bodyFont};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.4px;
  line-height: normal;
  transition: 0.3s ease-out;
  border-radius: 9999px!important;
  border-color: ${props => props.theme.greenColor};
  outline: none;
  background-color: ${props => props.theme.greenColor};
  :hover{
    color: ${props => props.theme.greenColor};
    background-color: #ffffff;
  }
`;



const Button = ({text, link}) => (
  <Section to={link} >
    {text}
  </Section>
);

export default Button;
