import * as types from './actionTypes';



export function countryChange(country) {
  return {type: types.ON_COUNTRY_CHANGE, country};
}
export function areaTypeChange(areaType) {
  return {type: types.ON_AREA_TYPE_CHANGE, areaType};
}
export function adminChange(admin_area) {
  return {type: types.ON_ADMIN_AREA_CHANGE, admin_area};
}
export function paChange(pa_area) {
  return {type: types.ON_PA_AREA_CHANGE, pa_area};
}
export function clearArea() {
  return {type: types.ON_CLEAR_AREA};
}
