import * as types from './actionTypes';
import isServer from "../utils/isServer";


export function viewportRequest(viewport) {
  return {type: types.SET_VIEW_PORT, viewport};
}


export function setViewPort(){
  return function (dispatch) {
    if(!isServer) {
      if (window.matchMedia("(max-width: 575.98px)").matches) {
        dispatch(viewportRequest('xSmall'));
        // return 'xSmall'
      } else if (window.matchMedia("(min-width: 576px)").matches && (window.matchMedia("(max-width: 767.98px)").matches)) {
        dispatch(viewportRequest('small'));
        // return 'small'
      } else if (window.matchMedia("(min-width: 768px)").matches && (window.matchMedia("(max-width: 991.98px)").matches)) {
        dispatch(viewportRequest('medium'));
        // return 'medium'
      } else if (window.matchMedia("(min-width: 992px)").matches && (window.matchMedia("(max-width: 1199.98px)").matches)) {
        dispatch(viewportRequest('large'));
        // return 'large'
      } else {
        dispatch(viewportRequest('xLarge'));
        // return 'xLarge'
      }
    }

  };
}
