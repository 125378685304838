import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import config from '../../config';


const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const LogoLink = styled(Link)`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  @media (min-width: 1100px) {
    height: 6rem;
  }
`;
const LogoImg = styled.img`
  height: 4rem;
  width: auto;
  @media (min-width: 1100px) {
    height: 6rem;
  }
`;

const Logo = () => {
  return (
    <Container>
      <LogoLink to="/">
        <LogoImg src={`${config.PUBLIC_URL}/img/logo_edited.png`} alt="ICPAC - GMES & Africa logo" />
      </LogoLink>
    </Container>
  );
};

export default Logo;
