import React from 'react';
import PropTypes from "prop-types";
import ReactPasswordStrength from "react-password-strength";


const PasswordRegistration = ({name, onChange, value, placeholder, label, error }) => (
  <div>
    {label ? <label>{label}</label>: ""}
    <ReactPasswordStrength
      className="text-input input-a"
      minLength={5}
      minScore={2}
      scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
      changeCallback={onChange}
      inputProps={{ name: `${name}`, autoComplete: "off" }}
    />
    {error && <span className="input-error">{error}</span>}
  </div>
);

PasswordRegistration.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string

};

export default PasswordRegistration;
