import React, {Component} from 'react';
import Layout from "../layout";
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

class NotFound extends Component{

  render(){
    let page ={
      title: "404",
      description: "The Global Monitoring for Environment and Security and Africa (GMES & Africa) Support Programme is " +
        "the result of the longstanding cooperation between Africa and Europe in the area of space science & technology, " +
        "which is one of the key priorities of the long-term EU-Africa Joint Strategy",
      keywords:"GMES and AFRICA contact page"
    };
    return(
      <Layout page={page}>
        <Container>
          <h1>404</h1>
          <p><strong>Error</strong> The page is not available</p>
          <p>The requested URL <strong><span>{this.props.location.pathname}</span></strong><br /> was not found on this server. That’s all we know.</p>
        </Container>
      </Layout>

    )
  }
}

export default NotFound;
