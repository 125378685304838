import React from 'react';
import {Row, Col12 } from "../../../theme/grid";
import {CheckBox, RadioInput} from "../../../components/input";
import ReactSelect from "../../../components/input/ReactSelect";
import styled from "styled-components";

const dataOptions =(options) =>{
  return options.map(option =>{
    return{
      value: option.node,
      label: option.node.name
    }
  })
};
const FilterContainer = styled.div`
position: sticky;
top: 6rem;

`;

const RegionFilter = ({areaType, onRegionChange, onPaChange, onAdminChange, onCountryChange, onAreaTypeChange, pa_area, admin_area, adminAreas, paAreas, region, country, countries, errors }) => {
  return(
    <FilterContainer>
      <Row style={{padding: "1rem 0.3rem"}}>
        <Col12>
        <CheckBox
          onChange={onRegionChange}
          checked={region} name='region' label='Eastern Africa Region' value={region} />
        </Col12>
      </Row>
      <Row style={{padding: "1rem 0.3rem"}}>
        <Col12>
          <ReactSelect
            onChange={onCountryChange}
            value={country}
            placeholder="Select"
            options={dataOptions(countries)}
            label="Country"
          />
        </Col12>
      </Row>

      <Row style={{padding: "1.5rem 0.3rem", fontSize: '0.9rem'}}>
        <Col12>
          <RadioInput
            onChange={onAreaTypeChange}
            name="areaType"
            defaultChecked={areaType ==="admin"}
            label="Administrative Boundaries"
            value="admin"
            style={{backgroundColor: '#999999'}}
          />
        </Col12>
        <Col12>
          <RadioInput
            onChange={onAreaTypeChange}
            name="areaType"
            defaultChecked={areaType ==="protected"}
            label="Protected Area"
            value="protected"
            style={{backgroundColor: '#999999'}}
          />
        </Col12>
      </Row>

      <Row style={{padding: "1rem 0.3rem"}}>
        {areaType === "protected" ?
          <Col12>
            <ReactSelect
              onChange={onPaChange}
              name="pa_area"
              value={pa_area}
              options={dataOptions(paAreas)}
              label="Protected Areas"
            />
          </Col12>:
          <Col12>
            <ReactSelect
              onChange={onAdminChange}
              name="admin_area"
              value={admin_area}
              options={dataOptions(adminAreas)}
              label="Administration Areas"
            />
          </Col12>}
      </Row>


      {/*{errors.region ?*/}
      {/*  <Row style={{justifyContent: "center"}}>*/}
      {/*    <p className="errors">{errors.region}</p>*/}
      {/*  </Row>: ""}*/}
    </FilterContainer>
  )

};

export default RegionFilter;
