import React, {Component, Fragment} from "react";
import Toggle from 'react-toggle';
import {Col5, Container, Row, Col10} from "../../../theme/grid";
import styled from "styled-components";
import FireMap from "./FireMap";
import CoverChart from "./charts/CoverChart";
import CoverTypeTable from "./tables/CoverTypeTable";
import FireChart from "./charts/FireChart";
import RFEChart from "./charts/RFEChart";
import NDVIChart from "./charts/NDVIChart";
import CoverMaps from "./maps/CoverMaps";
import CoverChangeMaps from "./maps/CoverChangeMaps";
import {slugify} from "../../../utils/slugify";


const ToogleText = styled.div`
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 0.3rem;
`;
const Section = styled.div`
  padding: 1rem 0;
  background: #ffffff;
`;

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 400;
  padding: 0.5rem 2rem;
  width: 100%;
  color: #ffffff;
  text-align: center;
  @media (max-width: 768px){
    width: 100%;
  }
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background: ${props => (props.theme.mainColor)};

`;
const ResultsContainer = styled.div`
box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
border-radius: 0.3rem;
`;

class LandCover extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      landCover: true,
      coverChange: true,
      changeStats: true,
      fire: true,
      trends: true,
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  toggleLandCover =()=>{this.setState({landCover: !this.state.landCover})};
  togglecoverChange =()=>{this.setState({coverChange: !this.state.coverChange})};
  toggleChangeStats =()=>{this.setState({changeStats: !this.state.changeStats})};
  toggleFire =()=>{this.setState({fire: !this.state.fire})};
  toggleTrends =()=>{this.setState({trends: !this.state.trends})};

  render() {
    let landcover = this.props.landcover;
    return (
      <Fragment>
        {landcover ?
        <Fragment>
        <Section>
          <Container>
            <ResultsContainer>
              <SectionTitle><span>Land Use/Cover Maps</span>
                <Toggle
                  checked={this.state.landCover}
                  icons={{
                    checked: <ToogleText>On</ToogleText>,
                    unchecked: <ToogleText>Off</ToogleText>,
                  }}
                  name='landcover'
                  value='yes'
                  onChange={this.toggleLandCover}/>
              </SectionTitle>
              {
                this.state.landCover && landcover ?
                  <CoverMaps
                    landcover={landcover}
                  />:
                  ""
              }
            </ResultsContainer>
          </Container>
        </Section>
        {this.props.landcover.forestArea ?
          <Section>
            <Container>
              <ResultsContainer>
                <SectionTitle><span>Land Use/Cover Change Maps</span>
                  <Toggle
                    checked={this.state.coverChange}
                    icons={{
                      checked: <ToogleText>On</ToogleText>,
                      unchecked: <ToogleText>Off</ToogleText>,
                    }}
                    name='coverchange'
                    value='yes'
                    onChange={this.togglecoverChange}/>
                </SectionTitle>
                {
                  this.state.coverChange && landcover ?
                    <CoverChangeMaps
                      landcover={landcover}

                    /> :
                    ""
                }
              </ResultsContainer>
            </Container>
          </Section>
          : ""
        }
        <Section>
          <Container>
            <ResultsContainer>
              <SectionTitle><span>Summary Land Change Statistics</span>
                <Toggle
                  checked={this.state.changeStats}
                  icons={{
                    checked: <ToogleText>On</ToogleText>,
                    unchecked: <ToogleText>Off</ToogleText>,
                  }}
                  name='changeStats'
                  value='yes'
                  onChange={this.toggleChangeStats}/>
              </SectionTitle>
              {landcover.coverTypeChart ?
                <Row style={{justifyContent: "space-evenly"}}>
                  <Col5>{landcover.coverTypeChart ? <CoverTypeTable coverTypeChart={landcover.coverTypeChart}/>: ""}</Col5>
                  <Col5>{landcover.coverTypeChart ?
                    <CoverChart
                    coverChart={landcover.coverTypeChart}
                    coverTypeChart={landcover.coverTypeChart}
                    coverLegend={landcover.coverLegend}
                    landcover={landcover}
                    location={slugify(landcover.name+"_"+landcover.country.name)}

                    />:""}</Col5>

                </Row>:""}
            </ResultsContainer>
          </Container>
        </Section>

        <Section>
          <Container>
            <ResultsContainer>
              <SectionTitle><span>Fire</span>
                <Toggle
                  checked={this.state.fire}
                  icons={{
                    checked: <ToogleText>On</ToogleText>,
                    unchecked: <ToogleText>Off</ToogleText>,
                  }}
                  name='fire'
                  value='yes'
                  onChange={this.toggleFire}/>
              </SectionTitle>
              {this.state.fire ?
                <Row style={{justifyContent: "space-evenly"}}>
                  <Col5  style={{margin: "1rem"}}>
                    <FireMap center={{lat: -2.653340, lng: 37.263611}} lat={-2.653340} lng={37.263611} />
                  </Col5>
                  <Col5>{landcover.fireChart ?
                    <FireChart
                      fireChart={landcover.fireChart}
                      location={slugify(this.props.landcover.name+"_"+this.props.landcover.country.name)}
                    />:""}</Col5>
                </Row>:""}
            </ResultsContainer>

          </Container>
        </Section>

        <Section>
          <Container>
            <ResultsContainer>
              <SectionTitle><span>Climate & Vegetation Trends</span>
                <Toggle
                  checked={this.state.trends}
                  icons={{
                    checked: <ToogleText>On</ToogleText>,
                    unchecked: <ToogleText>Off</ToogleText>,
                  }}
                  name='trends'
                  value='yes'
                  onChange={this.toggleTrends}/>
              </SectionTitle>
              {this.state.trends ?
                <Row style={{justifyContent: "space-evenly"}}>
                  <Col10>{landcover.rfeChart ?
                    <RFEChart
                      rfeChart={landcover.rfeChart}
                      location={slugify(this.props.landcover.name+"_"+this.props.landcover.country.name)}
                    />: ""}</Col10>
                  <Col10>
                {landcover.ndviChart ?
                    <NDVIChart
                      ndviChart={landcover.ndviChart}
                      location={slugify(this.props.landcover.name+"_"+this.props.landcover.country.name)}
                    />: ""}
                  </Col10>
                </Row>:""}
            </ResultsContainer>
          </Container>
        </Section>
        </Fragment>
          : <div className="div-loading"><div /><div /><div /></div>
        }
      </Fragment>

    )

  };
}
export default LandCover;
