import React, { Component } from 'react';
import styled from "styled-components";
import GoogleMapReact from 'google-map-react';
import {FaGripfire} from 'react-icons/fa';
import PropTypes from "prop-types";

const LocationComponent = () => (
  <FaGripfire size="2rem" color="#990000"/>
);

const Container =styled.div`
width: 100%;
height: 60vh;

`;
class FireMap extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      center: {lat: 59.95, lng: 30.33},
      zoom: 10
    };
  }

  render() {
    const {center, lat, lng} =this.props;
    return (
      <Container>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDZ7KJqWNVjayIXcwVBWKoFGU8mmC4lnuo" }}
          defaultCenter={center}
          defaultZoom={this.state.zoom}
        >
          <LocationComponent
            lat={lat}
            lng={lng}
          />
        </GoogleMapReact>
      </Container>
    );
  }
}
FireMap.propTypes = {
  center: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
};
export default FireMap;
