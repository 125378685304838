import React from "react";
import {Row} from "../../../theme/grid";
import EOCard from "./EOCard";



const  FireCards =({fires, onReadMore}) =>(
  <Row style={{justifyContent: 'center'}}>
      {fires.map(fire =>(
        <EOCard
          key={fire.node.id}
          eodata={fire.node.shortcode}
          color="#CA5131"
          title={fire.node.name}
          since={fire.node.timeSince}
          spatial_res={fire.node.spatialRes}
          temporal_res={fire.node.temporalRes}
          image={fire.node.dataType.image}
          link={fire.node.slug}
          onReadMore={()=>onReadMore(fire.node.readmore)}
        />
      ))}


  </Row>
);

export default FireCards;
