import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function areaReducer(state = initialState.pa_area, action) {
  switch  (action.type) {

    case types.ON_PA_AREA_CHANGE:
      return action.pa_area;

    case types.ON_PA_CLEAR_AREA:
      return { state: { ...state, area: null } };

    default:
      return state;
  }
}
