import config from "../config";
import axios from "axios";
import {requestConfig} from "../utils/requestConfig";
import moment from "moment";
export function getByDate(nodes, date){
  return nodes.filter(option => option.node.date === date)[0];
}

export function ecoRegion(Q){
  return axios.post(`${config.ROOT_URL}/q/` , regionQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.eco.edges[0].node;

      let date1 = data.ndvi_mon.edges[0] ? data.ndvi_mon.edges[0].node.date: '';
      let date2 = data.rfe_mon.edges[0] ? data.rfe_mon.edges[0].node.date: '';
      let date3 = data.dmp_mon.edges[0] ? data.dmp_mon.edges[0].node.date: '';
      let date4 = data.fapar_mon.edges[0] ? data.fapar_mon.edges[0].node.date: '';

      // separate date for DMP
      let dmp_date = new Date(date3);

      let date5 = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      let date6 = new Date(date3) > new Date(date4) ? new Date(date4):new Date(date3);
      // let date = new Date(date5) > new Date(date6) ? new Date(date6):new Date(date5);
      let date = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      // console.log("date1:", date1);
      // console.log("date2:", date2);
      // console.log("date3:", date3);
      // console.log("date4:",date4);
      // console.log("date5:",date5);
      // console.log("date6:",date6);
      // console.log("date:",date);

      return{
        name: "Eastern Africa Region",
        ndvis: data.ndvi.edges ? data.ndvi.edges:[],
        ndvis_info: data.ndvi.edges ? data.ndvi.pageInfo:{},
        ndvi: data.ndvi_mon.edges[0] ? getByDate(data.ndvi_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        ndvi_anom: data.ndvi_mon_anom.edges[0] ? getByDate(data.ndvi_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        ndvi_mon: data.ndvi_mon.edges ? data.ndvi_mon.edges: [],
        ndvi_mon_info: data.ndvi_mon.edges ? data.ndvi_mon.pageInfo: {},
        ndvi_anoms: data.ndvi_mon_anom.edges ? data.ndvi_mon_anom.edges: [],
        ndvi_anoms_info: data.ndvi_mon_anom.edges ? data.ndvi_mon_anom.pageInfo: {},

        rfes: data.rfe.edges ? data.rfe.edges:[],
        rfes_info: data.rfe.edges ? data.rfe.pageInfo:{},
        rfe: data.rfe_mon.edges[0] ? getByDate(data.rfe_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        rfe_anom: data.rfe_mon_anom.edges[0] ? getByDate(data.rfe_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        rfe_mon: data.rfe_mon.edges ? data.rfe_mon.edges: [],
        rfe_mon_info: data.rfe_mon.edges ? data.rfe_mon.pageInfo: {},
        rfe_anoms: data.rfe_mon_anom.edges ? data.rfe_mon_anom.edges: [],
        rfe_anoms_info: data.rfe_mon_anom.edges ? data.rfe_mon_anom.pageInfo: {},

        dmps: data.dmp.edges ? data.dmp.edges:[],
        dmps_info: data.dmp.edges ? data.dmp.pageInfo: {},
        dmp: data.dmp_mon.edges[0] ? getByDate(data.dmp_mon.edges, moment(dmp_date).format('YYYY-MM-DD')).node: {},
        dmp_anom: data.dmp_mon_anom.edges[0] ? getByDate(data.dmp_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        dmp_mon: data.dmp_mon.edges ? data.dmp_mon.edges: [],
        dmp_mon_info: data.dmp_mon.edges ? data.dmp_mon.pageInfo: {},
        dmp_anoms: data.dmp_mon_anom.edges ? data.dmp_mon_anom.edges: [],
        dmp_anoms_info: data.dmp_mon_anom.edges ? data.dmp_mon_anom.pageInfo: {},

        fapars: data.fapar.edges ? data.fapar.edges:[],
        fapars_info: data.fapar.edges ? data.fapar.pageInfo: {},
        fapar: data.fapar_mon.edges[0] ? getByDate(data.fapar_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        fapar_anom: data.fapar_mon_anom.edges[0] ? getByDate(data.fapar_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        fapar_mon: data.fapar_mon.edges ? data.fapar_mon.edges: [],
        fapar_mon_info: data.fapar_mon.edges ? data.fapar_mon.pageInfo: {},
        fapar_anoms: data.fapar_mon_anom.edges ? data.fapar_mon_anom.edges: [],
        fapar_anoms_info: data.fapar_mon_anom.edges ? data.fapar_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

function regionQuery(Q) {
  return`
  query{
    eco:allRegions{
      edges{
        node{
     id,
      name,
      ndvi:ecoclimaticSet(isRegion: true, isCountry: false, isDekad: true, isAdmin: false, isPa: false, isAnom: false, ndvi: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe:ecoclimaticSet(isRegion: true, isCountry: false, isDekad: true, isAdmin: false, isPa: false, isAnom: false, rfe: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }

      },
      dmp:ecoclimaticSet(isRegion: true, isCountry: false, isDekad: true, isAdmin: false, isPa: false, isAnom: false, dmp: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar:ecoclimaticSet(isRegion: true, isCountry: false, isDekad: true, isAdmin: false, isPa: false,  isAnom: false, fapar: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      ndvi_mon:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, ndvi: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe_mon:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, rfe: true, first: 12){
        edges{
          node{
            id,
            ndvi,
            rfe,
            dmp,
            fapar,
            dekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, dmp: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, fapar: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      ndvi_mon_anom:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, ndvi: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe_mon_anom:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, rfe: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon_anom:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, dmp: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon_anom:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, fapar: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }
        }
      }
    }
  }
  `
}

export function ecoCountry(Q){
  return axios.post(`${config.ROOT_URL}/q/` , countryQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.country;
      let date1 = data.ndvi_mon.edges[0] ? data.ndvi_mon.edges[0].node.date: '';
      let date2 = data.rfe_mon.edges[0] ? data.rfe_mon.edges[0].node.date: '';
      let date3 = data.dmp_mon.edges[0] ? data.dmp_mon.edges[0].node.date: '';
      let date4 = data.fapar_mon.edges[0] ? data.fapar_mon.edges[0].node.date: '';

      // let date5 = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      // let date6 = new Date(date3) > new Date(date4) ? new Date(date4):new Date(date3);
      // let date = new Date(date5) > new Date(date6) ? new Date(date6):new Date(date5);

      // separate date for DMP
      let dmp_date = new Date(date3);

      let date5 = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      let date6 = new Date(date3) > new Date(date4) ? new Date(date4):new Date(date3);
      // let date = new Date(date5) > new Date(date6) ? new Date(date6):new Date(date5);
      let date = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      // console.log("date1:", date1);
      // console.log("date2:", date2);
      // console.log("date3:", date3);
      // console.log("date4:",date4);
      // console.log("date5:",date5);
      // console.log("date6:",date6);
      // console.log("date:",date);

      return{
        name: `${resp.data.data.country.name}`,
        ndvis: data.ndvi.edges ? data.ndvi.edges:[],
        ndvis_info: data.ndvi.edges ? data.ndvi.pageInfo:{},
        ndvi: data.ndvi_mon.edges[0] ? getByDate(data.ndvi_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        ndvi_anom: data.ndvi_mon_anom.edges[0] ? getByDate(data.ndvi_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        ndvi_mon: data.ndvi_mon.edges ? data.ndvi_mon.edges: [],
        ndvi_mon_info: data.ndvi_mon.edges ? data.ndvi_mon.pageInfo: {},
        ndvi_anoms: data.ndvi_mon_anom.edges ? data.ndvi_mon_anom.edges: [],
        ndvi_anoms_info: data.ndvi_mon_anom.edges ? data.ndvi_mon_anom.pageInfo: {},

        rfes: data.rfe.edges ? data.rfe.edges:[],
        rfes_info: data.rfe.edges ? data.rfe.pageInfo:{},
        rfe: data.rfe_mon.edges[0] ? getByDate(data.rfe_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        rfe_anom: data.rfe_mon_anom.edges[0] ? getByDate(data.rfe_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        rfe_mon: data.rfe_mon.edges ? data.rfe_mon.edges: [],
        rfe_mon_info: data.rfe_mon.edges ? data.rfe_mon.pageInfo: {},
        rfe_anoms: data.rfe_mon_anom.edges ? data.rfe_mon_anom.edges: [],
        rfe_anoms_info: data.rfe_mon_anom.edges ? data.rfe_mon_anom.pageInfo: {},

        dmps: data.dmp.edges ? data.dmp.edges:[],
        dmps_info: data.dmp.edges ? data.dmp.pageInfo: {},
        dmp: data.dmp_mon.edges[0] ? getByDate(data.dmp_mon.edges, moment(dmp_date).format('YYYY-MM-DD')).node: {},
        dmp_anom: data.dmp_mon_anom.edges[0] ? getByDate(data.dmp_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        dmp_mon: data.dmp_mon.edges ? data.dmp_mon.edges: [],
        dmp_mon_info: data.dmp_mon.edges ? data.dmp_mon.pageInfo: {},
        dmp_anoms: data.dmp_mon_anom.edges ? data.dmp_mon_anom.edges: [],
        dmp_anoms_info: data.dmp_mon_anom.edges ? data.dmp_mon_anom.pageInfo: {},

        fapars: data.fapar.edges ? data.fapar.edges:[],
        fapars_info: data.fapar.edges ? data.fapar.pageInfo: {},
        fapar: data.fapar_mon.edges[0] ? getByDate(data.fapar_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        fapar_anom: data.fapar_mon_anom.edges[0] ? getByDate(data.fapar_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        fapar_mon: data.fapar_mon.edges ? data.fapar_mon.edges: [],
        fapar_mon_info: data.fapar_mon.edges ? data.fapar_mon.pageInfo: {},
        fapar_anoms: data.fapar_mon_anom.edges ? data.fapar_mon_anom.edges: [],
        fapar_anoms_info: data.fapar_mon_anom.edges ? data.fapar_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

function countryQuery(country) {
  return `
  query{
    country(id:"${country}"){
      id,
      name,
       ndvi:ecoclimaticSet(isRegion: false, isCountry: true, isDekad: true, isAdmin: false, isPa: false, isAnom: false, ndvi: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe:ecoclimaticSet(isRegion: false, isCountry: true, isDekad: true, isAdmin: false, isPa: false, isAnom: false, rfe: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp:ecoclimaticSet(isRegion: false, isCountry: true, isDekad: true, isAdmin: false, isPa: false, isAnom: false, dmp: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar:ecoclimaticSet(isRegion: false, isCountry: true, isDekad: true, isAdmin: false, isPa: false, isAnom: false, fapar: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      ndvi_mon:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, ndvi: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe_mon:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, rfe: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, dmp: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, fapar: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      ndvi_mon_anom:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, ndvi: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe_mon_anom:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, rfe: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon_anom:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, dmp: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon_anom:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, fapar: true, first: 12){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }
    }
  }
  `
}

export function ecoAdminArea(Q){
  return axios.post(`${config.ROOT_URL}/q/` , adminAreaQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.area;
      let date1 = data.ndvi_mon.edges[0] ? data.ndvi_mon.edges[0].node.date: '';
      let date2 = data.rfe_mon.edges[0] ? data.rfe_mon.edges[0].node.date: '';
      let date3 = data.dmp_mon.edges[0] ? data.dmp_mon.edges[0].node.date: '';
      let date4 = data.fapar_mon.edges[0] ? data.fapar_mon.edges[0].node.date: '';

      // let date5 = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      // let date6 = new Date(date3) > new Date(date4) ? new Date(date4):new Date(date3);
      // let date = new Date(date5) > new Date(date6) ? new Date(date6):new Date(date5);
      // separate date for DMP
      let dmp_date = new Date(date3);

      let date5 = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      let date6 = new Date(date3) > new Date(date4) ? new Date(date4):new Date(date3);
      // let date = new Date(date5) > new Date(date6) ? new Date(date6):new Date(date5);
      let date = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);

      return{
        name: `${resp.data.data.area.name} | ${resp.data.data.area.country.name}`,
        ndvis: data.ndvi.edges ? data.ndvi.edges:[],
        ndvis_info: data.ndvi.edges ? data.ndvi.pageInfo:{},
        ndvi: data.ndvi_mon.edges[0] ? getByDate(data.ndvi_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        ndvi_anom: data.ndvi_mon_anom.edges[0] ? getByDate(data.ndvi_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        ndvi_mon: data.ndvi_mon.edges ? data.ndvi_mon.edges: [],
        ndvi_mon_info: data.ndvi_mon.edges ? data.ndvi_mon.pageInfo: {},
        ndvi_anoms: data.ndvi_mon_anom.edges ? data.ndvi_mon_anom.edges: [],
        ndvi_anoms_info: data.ndvi_mon_anom.edges ? data.ndvi_mon_anom.pageInfo: {},

        rfes: data.rfe.edges ? data.rfe.edges:[],
        rfes_info: data.rfe.edges ? data.rfe.pageInfo:{},
        rfe: data.rfe_mon.edges[0] ? getByDate(data.rfe_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        rfe_anom: data.rfe_mon_anom.edges[0] ? getByDate(data.rfe_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        rfe_mon: data.rfe_mon.edges ? data.rfe_mon.edges: [],
        rfe_mon_info: data.rfe_mon.edges ? data.rfe_mon.pageInfo: {},
        rfe_anoms: data.rfe_mon_anom.edges ? data.rfe_mon_anom.edges: [],
        rfe_anoms_info: data.rfe_mon_anom.edges ? data.rfe_mon_anom.pageInfo: {},

        dmps: data.dmp.edges ? data.dmp.edges:[],
        dmps_info: data.dmp.edges ? data.dmp.pageInfo: {},
        dmp: data.dmp_mon.edges[0] ? getByDate(data.dmp_mon.edges, moment(dmp_date).format('YYYY-MM-DD')).node: {},
        dmp_anom: data.dmp_mon_anom.edges[0] ? getByDate(data.dmp_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        dmp_mon: data.dmp_mon.edges ? data.dmp_mon.edges: [],
        dmp_mon_info: data.dmp_mon.edges ? data.dmp_mon.pageInfo: {},
        dmp_anoms: data.dmp_mon_anom.edges ? data.dmp_mon_anom.edges: [],
        dmp_anoms_info: data.dmp_mon_anom.edges ? data.dmp_mon_anom.pageInfo: {},

        fapars: data.fapar.edges ? data.fapar.edges:[],
        fapars_info: data.fapar.edges ? data.fapar.pageInfo: {},
        fapar: data.fapar_mon.edges[0] ? getByDate(data.fapar_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        fapar_anom: data.fapar_mon_anom.edges[0] ? getByDate(data.fapar_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        fapar_mon: data.fapar_mon.edges ? data.fapar_mon.edges: [],
        fapar_mon_info: data.fapar_mon.edges ? data.fapar_mon.pageInfo: {},
        fapar_anoms: data.fapar_mon_anom.edges ? data.fapar_mon_anom.edges: [],
        fapar_anoms_info: data.fapar_mon_anom.edges ? data.fapar_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

function adminAreaQuery(Q) {
  return `
  query{
    area:adminArea(id:"${Q}"){
      id,
      name,
      country{
        id,
        name
      }
       ndvi:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: true, isPa: false, isAnom: false, ndvi: true, first: 36){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: true, isPa: false, isAnom: false, rfe: true, first: 36){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: true, isPa: false, isAnom: false, dmp: true, first: 36){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: true, isPa: false, isAnom: false, fapar: true, first: 36){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      ndvi_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: false, ndvi: true, first: 12){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: false, rfe: true, first: 12){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: false, dmp: true, first: 12){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: false, fapar: true, first: 12){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      ndvi_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: true, ndvi: true, first: 12){
        edges{
            node{
            id,
             dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: true, rfe: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: true, dmp: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: true, fapar: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }
    }
  }
  `
}

export function ecoPaArea(Q){
  return axios.post(`${config.ROOT_URL}/q/` , paQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.area;
      let date1 = data.ndvi_mon.edges[0] ? data.ndvi_mon.edges[0].node.date: '';
      let date2 = data.rfe_mon.edges[0] ? data.rfe_mon.edges[0].node.date: '';
      let date3 = data.dmp_mon.edges[0] ? data.dmp_mon.edges[0].node.date: '';
      let date4 = data.fapar_mon.edges[0] ? data.fapar_mon.edges[0].node.date: '';

      // let date5 = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      // let date6 = new Date(date3) > new Date(date4) ? new Date(date4):new Date(date3);
      // let date = new Date(date5) > new Date(date6) ? new Date(date6):new Date(date5);
      // separate date for DMP
      let dmp_date = new Date(date3);

      let date5 = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      let date6 = new Date(date3) > new Date(date4) ? new Date(date4):new Date(date3);
      // let date = new Date(date5) > new Date(date6) ? new Date(date6):new Date(date5);
      let date = new Date(date1) > new Date(date2) ? new Date(date2):new Date(date1);
      return{
        name: `${resp.data.data.area.name} | ${resp.data.data.area.country.name}`,
        ndvis: data.ndvi.edges ? data.ndvi.edges:[],
        ndvis_info: data.ndvi.edges ? data.ndvi.pageInfo:{},
        ndvi: data.ndvi_mon.edges[0] ? getByDate(data.ndvi_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        ndvi_anom: data.ndvi_mon_anom.edges[0] ? getByDate(data.ndvi_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        ndvi_mon: data.ndvi_mon.edges ? data.ndvi_mon.edges: [],
        ndvi_mon_info: data.ndvi_mon.edges ? data.ndvi_mon.pageInfo: {},
        ndvi_anoms: data.ndvi_mon_anom.edges ? data.ndvi_mon_anom.edges: [],
        ndvi_anoms_info: data.ndvi_mon_anom.edges ? data.ndvi_mon_anom.pageInfo: {},

        rfes: data.rfe.edges ? data.rfe.edges:[],
        rfes_info: data.rfe.edges ? data.rfe.pageInfo:{},
        rfe: data.rfe_mon.edges[0] ? getByDate(data.rfe_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        rfe_anom: data.rfe_mon_anom.edges[0] ? getByDate(data.rfe_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        rfe_mon: data.rfe_mon.edges ? data.rfe_mon.edges: [],
        rfe_mon_info: data.rfe_mon.edges ? data.rfe_mon.pageInfo: {},
        rfe_anoms: data.rfe_mon_anom.edges ? data.rfe_mon_anom.edges: [],
        rfe_anoms_info: data.rfe_mon_anom.edges ? data.rfe_mon_anom.pageInfo: {},

        dmps: data.dmp.edges ? data.dmp.edges:[],
        dmps_info: data.dmp.edges ? data.dmp.pageInfo: {},
        dmp: data.dmp_mon.edges[0] ? getByDate(data.dmp_mon.edges, moment(dmp_date).format('YYYY-MM-DD')).node: {},
        dmp_anom: data.dmp_mon_anom.edges[0] ? getByDate(data.dmp_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        dmp_mon: data.dmp_mon.edges ? data.dmp_mon.edges: [],
        dmp_mon_info: data.dmp_mon.edges ? data.dmp_mon.pageInfo: {},
        dmp_anoms: data.dmp_mon_anom.edges ? data.dmp_mon_anom.edges: [],
        dmp_anoms_info: data.dmp_mon_anom.edges ? data.dmp_mon_anom.pageInfo: {},

        fapars: data.fapar.edges ? data.fapar.edges:[],
        fapars_info: data.fapar.edges ? data.fapar.pageInfo: {},
        fapar: data.fapar_mon.edges[0] ? getByDate(data.fapar_mon.edges, moment(date).format('YYYY-MM-DD')).node: {},
        fapar_anom: data.fapar_mon_anom.edges[0] ? getByDate(data.fapar_mon_anom.edges, moment(date).format('YYYY-MM-DD')).node: {},
        fapar_mon: data.fapar_mon.edges ? data.fapar_mon.edges: [],
        fapar_mon_info: data.fapar_mon.edges ? data.fapar_mon.pageInfo: {},
        fapar_anoms: data.fapar_mon_anom.edges ? data.fapar_mon_anom.edges: [],
        fapar_anoms_info: data.fapar_mon_anom.edges ? data.fapar_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

function paQuery(Q) {
  return `
  query{
    area:protectedArea(id:"${Q}"){
      id,
      name,
      country{
        id,
        name
      }
       ndvi:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: false, isPa: true, isAnom: false, ndvi: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: false, isPa: true, isAnom: false, rfe: true, first: 36){
        edges{
          node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: false, isPa: true, isAnom: false, dmp: true, first: 36){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: false, isPa: true, isAnom: false, fapar: true, first: 36){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isDekad,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      ndvi_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: false, ndvi: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: false, rfe: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: false, dmp: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: false, fapar: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      ndvi_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: true, ndvi: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      rfe_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: true, rfe: true, first: 12){
        edges{
            node{
            id,
                   dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      dmp_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: true, dmp: true, first: 12){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: true, fapar: true, first: 12){
        edges{
            node{
            id,
            dekad,
            ndvi,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }
    }
  }
  `
}

