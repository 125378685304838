import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";
import Logo from "./Logo";
import MainMenu from "./MainMenu";
import {Container, Row} from "../../theme/grid";
import { IoMdMenu, IoMdClose } from 'react-icons/io'


const Section = styled.header`
  width: 100%;
  height: 4rem;
  //border-bottom: ${props => props.landing ? "transparent" : "1px solid #eeeeee"};
  border-bottom: 1px solid #eeeeee;
  position: fixed;
  top: 0;
  //background-color: ${props => props.landing ? "transparent" : "#ffffff"};
  background-color: #ffffff;
  z-index: 1000;
  @media (min-width: 1100px) {
    height: 6rem;
  }
`;

const MobileIcon = styled.div`
  color: ${props => props.theme.darkgreyColor};
  padding: 1rem 0.1rem;
  margin: 0 0.5rem;
  position: absolute;
  right: 0;
  @media (min-width: 1100px) {
    display: none;
  }

`;
const C3 = styled.div.attrs(props => ({
  className: "col-6 col-md-3",
}))`
`;
const C9 = styled.div.attrs(props => ({
  className: "col-6 col-md-9",
}))`
`;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false
    };
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onFeedbackClick = this.onFeedbackClick.bind(this);
  }

  onMenuClick(){
    this.setState({ openMenu: !this.state.openMenu })
  }
  onFeedbackClick(){
    this.setState({ openMenu: !this.state.openMenu });
  }

  render () {
    return(
      <Section landing={this.props.landing}>
        <Container>
          <Row>
            <C3>
              <Logo landing={this.props.landing}/>
            </C3>
            <C9>
              <MainMenu landing={this.props.landing}/>
            </C9>
            <MobileIcon onClick={this.props.sideMenuToggle}>
              {this.props.sideMenu ?
                <IoMdClose size={30} />:
                <IoMdMenu size={30} />
              }
            </MobileIcon>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default withRouter(Header);
