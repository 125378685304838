import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import config from "../../config";
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/pdfjs-2.2.228/pdf.worker.js`;


const NoPdf = styled.div`
  background: linear-gradient(125.75deg, white 0%, #dee4f0 33%, white 66%, #dee4f0 100%);
  background-size: 200% 100%;
  animation-name: loading-animate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 8px;
  max-width: 400px;
  width: 90vw;
  padding-bottom: 141.453%;
  font-size: 0;
`;


export default class PDFDisplay extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    pdf:""
  };

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1,
    });
  };

  changePage = offset => this.setState(prevState => ({
    pageNumber: prevState.pageNumber + offset,
  }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  render() {
    const { numPages } = this.state;

    return (
      <Fragment>
        {this.props.pdf ?
          <Fragment>
            <Document
              file={`${config.ROOT_URL}/media/${this.props.pdf}`}
              onLoadSuccess={this.onDocumentLoadSuccess}
              className="pdf-document"
            >
              {Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    className="pdf-page"
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ),
              )}
            </Document>
          </Fragment>
          : <NoPdf/>}
      </Fragment>
    );
  }
}
