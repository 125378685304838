import * as types from '../actions/actionTypes';
import initialState from './initialState';
import isEmpty from 'lodash/isEmpty';

export default function isAuthenticatedReducer(state = initialState.isAuthenticated, action) {
  switch  (action.type) {

    case types.USER_AUTHENTICATED:
      return !isEmpty(action.token);


    default:
      return state;
  }
}
