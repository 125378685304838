import Papa from "papaparse";
import config from "../config";
import axios from "axios";
import {requestConfig} from "../utils/requestConfig";

function fetchCsv(CSVLink) {
  let link = `${config.ROOT_URL}/media/${CSVLink}`;
  return fetch(link).then(function (response) {
    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');
    return reader.read().then(function (result) {
      return decoder.decode(result.value);
    });
  });
}

export async function loadLegend(CSVLink) {
  let csvData = await fetchCsv(CSVLink);
  return Papa.parse(csvData, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    complete: (results)=>{return results}
  });
}
export function ecoLegends(){
  return axios.post(`${config.ROOT_URL}/q/` , legendsQuery(), requestConfig)
    .then((resp)=>{
      let legends = resp.data.data.legends.edges[0];
      return legends.node;
    }).catch(error => {
      throw(error);
    });
}
function legendsQuery() {
  return `
    query{
      legends:ecoLegends{
         edges{
          node{
            pk
            ndvi,
            ndviAnom,
            rfe,
            rfeAnom,
            dmp,
            dmpAnom,
            fapar,
            faparAnom,
            sm
          }
        }

      }
    }
`
}

export async function loadCSV(CSVLink) {
  let csvData = await fetchCsv(CSVLink);

  return Papa.parse(csvData, {
    dynamicTyping: true,
    skipEmptyLines: true,
    complete: (results)=>{return results}
  });
}
export async function parseCSV(CSVLink) {
  let csvData = await fetchCsv(CSVLink);

  return Papa.parse(csvData, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    complete: (results)=>{return results}
  });
}

export function parseCoverChart(data){
  let cats = [];
  let series = [];
  for(let i = 1; i < data.length; i++){
    if(data[i].length ===  2){
      series.push({
        name: data[i][0],
        data: [data[i][1], data[i][2]]
      })
    } else if(data[i].length === 3){
      series.push({
        name: data[i][0],
        data: [data[i][1], data[i][2], data[i][3]]
      })
    } else if(data[i].length === 4){
      series.push({
        name: data[i][0],
        data: [data[i][1], data[i][2], data[i][3]]
      })
    }

  }
  for(let i = 0; i < data.length; i++){

    if(data[i].length ===  2){
      cats.push(
        [data[i][1].toString()
        ]);
    } else if(data[i].length === 3){
      cats.push(
        [data[i][1].toString(),
          data[i][2].toString()
        ]);
    } else if(data[i].length === 4){
      cats.push(
        [data[i][1].toString(),
          data[i][2].toString(),
          data[i][3].toString()
        ]);
    }
  }
  let categories = cats[0];
  return {categories, series};
}
export function parseFireChart(data){
  let categories = [];
  let data1 = [];
  let data2 = [];
  let series = [
    {name: data[0][1], data: data1},
    {name: data[0][2], data: data2},
  ];

  for(let i = 1; i < data.length; i++){
    data1.push(data[i][1]);
    data2.push(data[i][2]);
  }
  for(let i = 1; i < data.length; i++){
    // let cols = data[i][0];
    // console.log(cols);
    categories.push(data[i][0].toString());
  }
  return {categories, series};
}

export function parseRFEChart(data){
  let categories = [];
  let data1 = [];
  let data2 = [];
  let data3 = [];
  let data4 = [];
  let series = [
    {name: data[0][1], type: "line", data: data1},
    {name: data[0][2], type: "column", data: data2},
    {name: data[0][3], type: "column", data: data3},
    {name: data[0][4], type: "column", data: data4},
  ];

  for(let i = 1; i < data.length; i++){
    data1.push(data[i][1]);
    data2.push(data[i][2]);
    data3.push(data[i][3]);
    data4.push(data[i][4]);
  }
  for(let i = 1; i < data.length; i++){
    categories.push(data[i][0].toString());
  }
  return {categories, series};
}
export function parseNDVIChart(data){
  let categories = [];
  let data1 = [];
  let data2 = [];
  let data3 = [];
  let data4 = [];
  let series = [
    {name: data[0][1], data: data1},
    {name: data[0][2], data: data2},
    {name: data[0][3], data: data3},
    {name: data[0][4], data: data4},
  ];
  for(let i = 1; i < data.length; i++){
    data1.push(data[i][1]);
    data2.push(data[i][2]);
    data3.push(data[i][3]);
    data4.push(data[i][4]);
  }
  for(let i = 1; i < data.length; i++){
    categories.push(data[i][0].toString());
  }
  return {categories, series};
}
