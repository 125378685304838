import React, { Component } from "react";
import Chart from 'react-apexcharts';
import {loadCSV, parseRFEChart} from "../../../../actions/loadCSV";


class RFEChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: {},
      chart_error: []
    }
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getRFEChart();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState){
    if (prevProps.rfeChart !== this.props.rfeChart) {
      this.getRFEChart();
    }
  }
  getRFEChart=()=>{
    loadCSV(this.props.rfeChart).then((results)=>{
        let data = parseRFEChart(results.data);
      if (this._isMounted) {
        this.setState({chart: data});
        this.setState({chart_error: results.errors});
      }
    })
  };

  loadOptions(categories){
    return{
      colors : ['rgb(27, 42, 73)', 'rgb(201, 209, 211)', 'rgb(0, 144, 158)', 'rgb(70, 88, 129)'],
      title: {
        text: 'Last 12 Months Rainfall Vs Long Term',
        style: {
          fontSize:  '14px',
          fontWeight:  'normal',
          color:  '#263238'
        }
      },
      chart: {
        id: `${this.props.location+"-rfe-chart"}`,
        height: 350,
        type: 'line',
        stacked: false,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: false
        }
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%'
        },
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1]
      },
      stroke: {
        show: true,
        width: 2
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: {
          text: "Total Precipitation (mm)",
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 400,
          },
        },
        labels: {
          formatter: function (val) {
            return val ? val.toFixed(0): null
          }
        },
        forceNiceScale: true,
      },
      fill: {
        opacity: 1
      }
    }
  }

  render() {
    return (
      <div id="rfe-chart" style={{paddingTop: '2rem'}}>
        {this.state.chart.series ?
          <Chart
            options={this.loadOptions(this.state.chart.categories)}
            series={this.state.chart.series}
            type="bar" height={350} />
          : <p>Loading......</p>
        }

      </div>
    )
  }
}
export default RFEChart;
