import React from 'react';
import {Row, Col3} from "../../../theme/grid";
import CheckboxInput from '../../../components/input/CheckboxInput';


const FormatFilter = ({format, onFormatChange, errors}) => {
  return(
    <div style={{border: "1px solid #eee", padding: "1rem"}}>

      <Row style={{justifyContent: "space-evenly", padding: "1rem"}}>
        <Col3>
          <CheckboxInput
            onChange={onFormatChange}
            name="format"
            checked={format.png}
            label="PNG"
            value="png" />
        </Col3>
        <Col3>
          <CheckboxInput
            onChange={onFormatChange}
            name="format"
            checked={format.geotiff}
            // defaultChecked={format ==="geotiff"}
            label="GeoTiff"
            value="geotiff" />
        </Col3>
        <Col3>
          <CheckboxInput
            onChange={onFormatChange}
            name="format"
            checked={format.tabular}
            //defaultChecked={format ==="tabular"}
            label="Tabular"
            value="tabular" />
        </Col3>

      </Row>


      {errors.format ?
        <Row style={{justifyContent: "center"}}>
          <p className="errors">{errors.format}</p>
        </Row>: ""}
    </div>
  )

};

export default FormatFilter;
