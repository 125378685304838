import React from 'react';
import styled from 'styled-components';
import Copyright from "./Copyright";
import FooterMenu from "./FooterMenu";

const Section = styled.footer`
  width: 100%;
`;


const Footer = ()=>(
  <Section>
    <FooterMenu/>
    <Copyright />
  </Section>
);

export default Footer;
