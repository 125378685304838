import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Container =styled.div`
3
`;
const Select =styled.select`
  height: 35px;
  padding: 8px 10px;
  font-size: 0.9rem;
  font-weight: 300;
  resize: none;
  border: 1px solid #d2beb6!important;
  border-radius: 4px!important;
  background: #fff!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  display: block;
  width: 100%;
  line-height: 1.42857143;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  color: #555;
  outline: 0;
`;

const SelectInput = ({name, label, onChange, value, error, options, disabled}) => {
  return (
    <Container>
      {label ? <label>{label}</label>: ""}
      <Select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className="text-input input-a">
        {options.map((option) => {
          return <option key={option.id} value={option.slug}>{option.name}</option>;
        })
        }
      </Select>
      {error && <span className="input-error">{error}</span>}
    </Container>
  );
};
SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object)
};


export default SelectInput;
