import config from "../config";
import axios from "axios";
import {requestConfig} from "../utils/requestConfig";


export function regionSM(Q){
  return axios.post(`${config.ROOT_URL}/q/` , regionSMQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.eco.edges[0].node;
      return{
        name: "Eastern Africa Region",
        sms: data.sm.edges ? data.sm.edges:[],
        sms_info: data.sm.edges ? data.sm.pageInfo:{},
        sm: data.sm_mon.edges[0] ? data.sm_mon.edges[0].node: {},
        sm_anom: data.sm_mon_anom.edges[0] ? data.sm_mon_anom.edges[0].node: {},
        sm_mon: data.sm_mon.edges ? data.sm_mon.edges: [],
        sm_mon_info: data.sm_mon.edges ? data.sm_mon.pageInfo: {},
        sm_anoms: data.sm_mon_anom.edges ? data.sm_mon_anom.edges: [],
        sm_anoms_info: data.sm_mon_anom.edges ? data.sm_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function regionSMQuery(Q){
  return`
  query{
     eco:allRegions{
      edges{
        node{
        id,
        name,
        sm:ecoclimaticSet(isRegion: true, isCountry: false, isDekad: true, isAdmin: false, isPa: false, isAnom: false, sm: true, first: 36, before: "${Q.smBefore}", after:"${Q.smAfter}"){
          edges{
            node{
              id,
              dekad,
              sm,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      sm_mon:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, sm: true, first: 12, before: "${Q.smMonBefore}", after:"${Q.smMonAfter}"){
        edges{
          node{
            id,
            dekad,
            sm,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      sm_mon_anom:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, sm: true, first: 12, before: "${Q.smAnomBefore}", after:"${Q.smAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            sm,
            sm,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
    }
    }
  `
}

export function countrySM(Q){
  return axios.post(`${config.ROOT_URL}/q/` , countrySMQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.country;
      return{
        sms: data.sm.edges ? data.sm.edges:[],
        sms_info: data.sm.edges ? data.sm.pageInfo:{},
        sm: data.sm_mon.edges[0] ? data.sm_mon.edges[0].node: {},
        sm_anom: data.sm_mon_anom.edges[0] ? data.sm_mon_anom.edges[0].node: {},
        sm_mon: data.sm_mon.edges ? data.sm_mon.edges: [],
        sm_mon_info: data.sm_mon.edges ? data.sm_mon.pageInfo: {},
        sm_anoms: data.sm_mon_anom.edges ? data.sm_mon_anom.edges: [],
        sm_anoms_info: data.sm_mon_anom.edges ? data.sm_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function countrySMQuery(Q){
  return`
  query{
    country(id:"${Q.country}"){
      id,
      name,
        sm:ecoclimaticSet(isRegion: false, isCountry: true, isDekad: true, isAdmin: false, isPa: false, isAnom: false, sm: true, first: 36, before: "${Q.smBefore}", after:"${Q.smAfter}"){
          edges{
            node{
              id,
              dekad,
              sm,
              sm,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      sm_mon:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, sm: true, first: 12, before: "${Q.smMonBefore}", after:"${Q.smMonAfter}"){
        edges{
          node{
            id,
            dekad,
            sm,
            sm,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      sm_mon_anom:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, sm: true, first: 12, before: "${Q.smAnomBefore}", after:"${Q.smAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            sm,
            sm,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}

export function paAreaSM(Q){
  return axios.post(`${config.ROOT_URL}/q/` , paAreaSMQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.area;
      return{
        sms: data.sm.edges ? data.sm.edges:[],
        sms_info: data.sm.edges ? data.sm.pageInfo:{},
        sm: data.sm_mon.edges[0] ? data.sm_mon.edges[0].node: {},
        sm_anom: data.sm_mon_anom.edges[0] ? data.sm_mon_anom.edges[0].node: {},
        sm_mon: data.sm_mon.edges ? data.sm_mon.edges: [],
        sm_mon_info: data.sm_mon.edges ? data.sm_mon.pageInfo: {},
        sm_anoms: data.sm_mon_anom.edges ? data.sm_mon_anom.edges: [],
        sm_anoms_info: data.sm_mon_anom.edges ? data.sm_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function paAreaSMQuery(Q){
  return`
  query{
    area:protectedArea(id:"${Q.pa}"){
      id,
      name,
      country{
        id,
        name
      }
        sm:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: false, isPa: true, isAnom: false, sm: true, first: 36, before: "${Q.smBefore}", after:"${Q.smAfter}"){
          edges{
            node{
              id,
              dekad,
              sm,
              sm,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      sm_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: false, sm: true, first: 12, before: "${Q.smMonBefore}", after:"${Q.smMonAfter}"){
        edges{
          node{
            id,
            dekad,
            sm,
            sm,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      sm_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: true, sm: true, first: 12, before: "${Q.smAnomBefore}", after:"${Q.smAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            sm,
            sm,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}

export function adminAreaSM(Q){
  return axios.post(`${config.ROOT_URL}/q/` , adminAreaSMQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.area;
      return{
        sms: data.sm.edges ? data.sm.edges:[],
        sms_info: data.sm.edges ? data.sm.pageInfo:{},
        sm: data.sm_mon.edges[0] ? data.sm_mon.edges[0].node: {},
        sm_anom: data.sm_mon_anom.edges[0] ? data.sm_mon_anom.edges[0].node: {},
        sm_mon: data.sm_mon.edges ? data.sm_mon.edges: [],
        sm_mon_info: data.sm_mon.edges ? data.sm_mon.pageInfo: {},
        sm_anoms: data.sm_mon_anom.edges ? data.sm_mon_anom.edges: [],
        sm_anoms_info: data.sm_mon_anom.edges ? data.sm_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function adminAreaSMQuery(Q){
  return`
  query{
    area:adminArea(id:"${Q.admin}"){
      id,
      name,
      country{
        id,
        name
      }
        sm:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: true, isPa: false, isAnom: false, sm: true, first: 36, before: "${Q.smBefore}", after:"${Q.smAfter}"){
          edges{
            node{
              id,
              dekad,
              sm,
              sm,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      sm_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: false, sm: true, first: 12, before: "${Q.smMonBefore}", after:"${Q.smMonAfter}"){
        edges{
          node{
            id,
            dekad,
            sm,
            sm,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      sm_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: true, sm: true, first: 12, before: "${Q.smAnomBefore}", after:"${Q.smAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            sm,
            sm,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}
