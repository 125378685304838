export const SET_VIEW_PORT = 'SET_VIEW_PORT';

export const LOAD_REGIONS_SUCCESS = 'LOAD_REGIONS_SUCCESS';
export const CREATE_REGION_SUCCESS = 'CREATE_REGION_SUCCESS';
export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
export const REMOVE_REGION_SUCCESS = 'REMOVE_REGION_SUCCESS';


export const ON_REGION_CHANGE = 'ON_REGION_CHANGE';
export const ON_COUNTRY_CHANGE = 'ON_COUNTRY_CHANGE';
export const ON_AREA_TYPE_CHANGE = 'ON_AREA_TYPE_CHANGE';
export const ON_AREA_CHANGE = 'ON_AREA_CHANGE';
export const ON_FORMAT_CHANGE = 'ON_FORMAT_CHANGE';
export const ON_CLEAR_AREA = 'ON_CLEAR_AREA';
export const ON_PA_AREA_CHANGE = 'ON_PA_AREA_CHANGE';
export const ON_ADMIN_AREA_CHANGE = 'ON_ADMIN_AREA_CHANGE';
export const ON_PA_CLEAR_AREA = 'ON_PA_CLEAR_AREA';
export const ON_ADMIN_CLEAR_AREA = 'ON_ADMIN_CLEAR_AREA';

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';

export const USER_AUTHENTICATE = 'USER_AUTHENTICATE';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const STORE_ACCESS_TOKEN = 'STORE_ACCESS_TOKEN';
export const STORE_REFRESH_TOKEN= 'STORE_REFRESH_TOKEN';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const TOGGLE_AUTH_MODAL = 'TOGGLE_AUTH_MODAL';
export const TOGGLE_REGISTER_MODAL = 'TOGGLE_REGISTER_MODAL';
