import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function areaReducer(state = initialState.admin_area, action) {
  switch  (action.type) {

    case types.ON_ADMIN_AREA_CHANGE:
      return action.admin_area;

    case types.ON_ADMIN_CLEAR_AREA:
      return null;

    default:
      return state;
  }
}
