import React, {Component} from 'react';
import Layout from "../layout";
import styled from 'styled-components';
import {Container} from "../theme/grid";

const PageBanner = styled.div`
  background: #26a0da;
  background: -webkit-linear-gradient(to right, #314755, #26a0da);
  background: linear-gradient(to right, #314755, #26a0da);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 10vh;
`;

const H3 = styled.h3`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;

const H5 = styled.h5`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;


const PageTitle = styled(H3)`
  color: #ffffff;
`;
const PageSubTitle = styled(H5)`
  color: #ffffff;
`;


class DashboardPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }


  render() {
    let page ={
      title: "Dashboard",
      description: "The Dashboard",
      keywords:"Dashboard"
    };
    return (
      <Layout page={page}>
        <PageBanner>
          <Container>
            <PageTitle>Dashboard</PageTitle>
            <PageSubTitle>The Dashboard.</PageSubTitle>
          </Container>
        </PageBanner>
        <Container>
          <H3>The Dashboard</H3>

        </Container>
      </Layout>
    )
  }
}


export default  DashboardPage;
