import React from 'react';
import PropTypes from "prop-types";
import {IoMdSearch} from 'react-icons/io';

const SearchInput = ({onClick, onChange, onKeyPress, value, placeholder, iconSize }) => (
  <div style={{position: "relative"}}>
    <input
      required
      className="search-input"
      type="text"
      name="search"
      placeholder={placeholder}
      value={value}
      onKeyPress={onKeyPress}
      onChange={onChange}/>
    <button className="on-search" onClick={onClick}>
      <IoMdSearch size={`${iconSize}px`} color="#333333"/>
    </button>
  </div>
);

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  iconSize: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string

};

export default SearchInput;

