export default {
  ajaxCallsInProgress: 0,
  isAuthenticated: false,
  accessToken: '',
  refreshToken: '',
  user:{},
  modal: false,
  viewport: '',
  areaType: "admin",
  format: "png",
  country: null,
  area: null,
  pa_area: null,
  admin_area: null
}
