import React from 'react';
import PropTypes from 'prop-types';


const RadioInput = ({onChange, value, name, label, defaultChecked, checked, style }) => {
  return (
    <label className="checkbox radio">{label}
  <input id="radio1" 
    type="radio" 
    name={name}  
    defaultChecked={defaultChecked} 
    checked={checked} 
    onChange={onChange}
    value={value}  />
  <span className="checkmark" style={style}/>
</label>
  );
};

RadioInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};


export default RadioInput;
