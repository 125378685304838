import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";



const CheckBox = ({id, onChange, value, name, label, description, style}) => {
  return (
    <Fragment>
      <label className="checkbox" style={style}>
        <div  data-tip={description} data-for={id}>{label}</div>
        <input type="checkbox" name={name} onChange={onChange} checked={value} />
        <span className="checkmark" />
      </label>
      <ReactTooltip id={id} aria-haspopup='true' getContent={() => { return null }}>
        <p>{description}</p>
      </ReactTooltip>
    </Fragment>
  );
};

CheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};


export default CheckBox;
