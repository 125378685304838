import React, {Component} from 'react';
import Layout from "../../layout";
import styled from "styled-components";
import NewsItem from "./NewsItem";
import NewsItem2 from "./NewsItem2";
import {Container, Row, Col3, Col9, Col6} from "../../theme/grid";
import {requestConfig} from "../../utils/requestConfig";
import config from "../../config";
import axios from "axios";
import ServiceFilter from "./filters/ServiceFilter";
import TypeFilter from "./filters/TypeFilter";
import SearchInput from "../../components/input/SearchInput";
import {isEmpty} from "../../utils/isEmpty";
import DisplayChanger from "../../utils/DisplayChanger";
import FeaturedNews from "./FeaturedNews";



const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 10vh;
`;

const H3 = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin: 2rem 0;
`;

const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.4rem;
`;
const NewsItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

class NewsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      filter: {title: "", service: "", type: ""},
      services: [],
      types: [],
      news: [],
      featured: [],
      search:"",
      display: 'list'
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);

  }

  onChange=(event)=>{
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let filter = this.state.filter;
    this.setState({ filter: { ...filter, [name]: value } });
    // this.getContent();
  };


  componentDidMount(){
    this.getContent();
    this.getFilterContent();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.filter !== this.state.filter){
      this.getContent();
    }
  }

  onSearchChange(event){
    this.setState({search: event.target.value});
    let filter = this.state.filter;
    this.setState({ filter: { ...filter, title: event.target.value } });
  }

  searchFormError() {
    let formIsValid = true;
    let errors = {};

    if(isEmpty(this.state.filter.title)) {
      errors.search = 'Search item is required';
      formIsValid = false;
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  onDisplayChange=(value)=>{this.setState({display: value});};
  onSearchClick(){
    // if(!this.searchFormError()) {return;}
    // this.getContent(this.state.filter.title);
    // console.log(this.state.filter.title);

  }
  onSubmit=()=>{
    // if(!this.searchFormError()) {return;}
    this.getContent(this.state.filter.title);
    console.log(this.state.filter.title);
  };
  handleKeyPress=(e)=> {
    // if(!this.searchFormError()) {return;}
    if (e.key === 'Enter') {
      let filter = this.state.filter;
      this.setState({ filter: { ...filter, title: e.target.value } });
    }
  };

  getContent(){
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(this.state.filter), requestConfig)
      .then((resp)=>{
        let news = resp.data.data.news.edges;
        this.setState({news : news });
        let featured = resp.data.data.featured.edges;
        this.setState({featured : featured });
      }).catch(error => {
        throw(error);
      });
  }
  getFilterContent(){
    return axios.post(`${config.ROOT_URL}/q/` , getFilterQuery(), requestConfig)
      .then((resp)=>{
        let services = resp.data.data.services.edges;
        this.setState({services : services });
        let types = resp.data.data.types.edges;
        this.setState({types : types });
      }).catch(error => {
        throw(error);
      });
  }


  render() {
    let page ={
      title: "News",
      description: "Latest News"
    };

    return (
      <Layout page={page}>

        <PageBanner>
          <Container style={{textAlign: "center"}}>
            <PageTitle>Latest News</PageTitle>
          </Container>
        </PageBanner>

        <FeaturedNews news={this.state.featured} />

        <H3>All Publications</H3>

        <Container style={{padding: "2rem"}}>
          <Row style={{justifyContent: "center"}}>
            <Col6>
              <SearchInput
                onClick={this.onSearchClick}
                onSubmit={this.onSubmit}
                iconSize='30' value={this.state.search}
                onChange={this.onSearchChange }
                onKeyPress={this.handleKeyPress}
                placeholder="News Search"
              />
            </Col6>
            <Col6>
              <Row style={{justifyContent: "center"}}>
                <DisplayChanger onDisplayChange={this.onDisplayChange} display={this.state.display} />
              </Row>
            </Col6>
          </Row>
        </Container>

        <Container style={{ marginBottom: '5rem'}}>
          <Row>
            <Col3>
              <ServiceFilter onChange={this.onChange} filter={this.state.filter} services={this.state.services}/>
              <TypeFilter onChange={this.onChange} filter={this.state.filter} types={this.state.types}/>
            </Col3>
            <Col9>
              <NewsItems>
                {this.state.news.map(item =>{
                    return this.state.display === 'list' ?<NewsItem
                      key={item.node.id}
                      title={item.node.title}
                      image={item.node.image}
                      timestamp={item.node.timestamp}
                      summary={item.node.summary}
                      slug={item.node.slug}
                    />:
                      <NewsItem2
                        key={item.node.id}
                        title={item.node.title}
                        image={item.node.image}
                        timestamp={item.node.timestamp}
                        summary={item.node.summary}
                        slug={item.node.slug}
                      />
                  }
                )}
              </NewsItems>
            </Col9>
          </Row>
        </Container>
      </Layout>
    )
  }
}
function getQuery(filter) {
  return `
  query{
    news:allNews(title:"${filter.title}", service_Slug:"${filter.service}", type_Slug:"${filter.type}"){
      edges{
        node{
          id,
          title,
          timestamp,
          image,
          slug,
          summary,
          service{
            title
          },
          type{
            title
          }
        }
      }
    },
    featured:allNews(first: 4, isFeatured:true){
      edges{
        node{
          id,
          title,
          timestamp,
          image,
          slug,
          summary,
          service{
            title
          },
          type{
            title
          }
        }
      }
    }
  }
`
}
function getFilterQuery() {
  return `
  query{
    services:allNewsService{
      edges{
        node{
          id,
          title,
          slug
        }
      }
    },
    types:allNewsType{
      edges{
        node{
          id,
          title,
          slug
        }
      }
    }
  }
`
}
export default NewsPage;

