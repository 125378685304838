import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import config from "../../config";


const FrontPartners = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  min-height: 5rem;
`;
const PartnerLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
`;
const PartnerImg = styled.img`
  filter: grayscale(100%);
  height: 6rem;
  margin: 1rem 3rem;
  width: auto;
  max-width: 235px;
  object-fit: contain;
  :hover{
  filter: none;
  }
`;
const Partner = ({partners}) => {
  return (
      <FrontPartners>
        {partners.map(partner =>
          <PartnerLink key={partner.node.id} href={partner.node.link} target="_blank">
            <PartnerImg src={`${config.ROOT_URL}/media/${partner.node.image}`} alt={partner.node.title} />
          </PartnerLink>
        )}
      </FrontPartners>
  );
};
Partner.propTypes = {
  partners: PropTypes.array.isRequired
};
export default Partner;
