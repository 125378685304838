import React, {Component} from 'react';
import styled from "styled-components";
import NewsItem2 from "./NewsItem2";
import {Container, Row, Col12} from "../../theme/grid";

const H3 = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin: 2rem 0;
`;

const NewsItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

class FeaturedNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }


  render() {
    return this.props.news ?
        <Container style={{ marginBottom: '3rem'}}>
          <H3>Featured News</H3>
          <Row>
            <Col12>
              <NewsItems>
                {this.props.news.map(item => {
                    return <NewsItem2
                      key={item.node.id}
                      title={item.node.title}
                      image={item.node.image}
                      timestamp={item.node.timestamp}
                      summary={item.node.summary}
                      slug={item.node.slug}
                    />
                  }
                )}
              </NewsItems>
            </Col12>
          </Row>
        </Container>: "";

  }
}
export default FeaturedNews;

