import React, {Component} from 'react';
import Layout from "../../layout";
import styled from 'styled-components';
import * as viewportActions from '../../actions/viewportActions';
import { IoIosCloseCircle, IoMdDownload } from 'react-icons/io';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {Container, FluidContainer, Row, Col12} from "../../theme/grid";
import VegetationCards from "./cards/VegetationCards";
import PrecipitationCards from "./cards/PrecipitationCards";
import FireCards from "./cards/FireCards";
import config from "../../config";
import OthersCards from "./cards/OthersCards";
import axios from "axios";
import {requestConfig} from "../../utils/requestConfig";
import PDFDisplay from "../../components/pdf/PDFDisplay";
import ReactTooltip from "react-tooltip";


const PageBanner = styled.div`
  background: #26a0da;
  background: -webkit-linear-gradient(to right, #314755, #26a0da);
  background: linear-gradient(to right, #314755, #26a0da);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  min-height: 350px;
`;
const BannerTitle = styled.div`
  width: 100%;
  height: 100%;
  font-family: ${props => props.theme.headerFont};
  text-align: center;
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 800;
  color: #ffffff;
`;
const BannerDesc = styled.div`
  width: fit-content;
  font-family: ${props => props.theme.headerFont};
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
  color: #ffffff;
  background-color: rgba(255,255,255,0.3);
  padding: 0.5rem;
`;
const H1 = styled.h1`
  font-weight: 700;
  color: ${props => props.theme.blackColor};
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.4rem;
`;

const HBorderLight = styled.div`
  background: linear-gradient(to right, #dddddd, #f7f7f7);
  height: 3px;
  border-radius: 30px;
  width: 40px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const CenterContainer = styled.div`
  position: relative;
`;
const Left = styled.div`
  background: #f2f2f2;
  width: ${props => props.showPDF ? "50%" : "100%"};

`;

const Right = styled.div`
  background: #222222;
  width: 50%;
  display: ${props => props.showPDF ? "flex" : "none"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 6rem;
  right: 0;
  height: calc(100vh - 6rem);
  z-index: 10;
  border-left: 2px solid #f7f7f7;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
`;

const PageIntro = styled.div`
  position: relative;
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

const PageIntroContent = styled.div`
  position: absolute;
  background: #ffffff;
  top: -50px;
  padding: 3rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f7f7f7;
  width: 100%;
`;
const PageTitle = styled.div`
  width: 100%;
  height: 100%;
  font-family: ${props => props.theme.headerFont};
  color: ${props => props.theme.blackColor};
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
`;
const PageSubTitle = styled.div`
  font-family: ${props => props.theme.bodyFont};
  font-weight: 400;
  text-align: center;
    width: 85%;
  color: ${props => props.theme.darkgreyColor};
  @media (max-width: 768px){
    width: 100%;
  }
`;
const Section = styled.div`
  padding: 2rem 0;
`;
const Section1 = styled(Section)`
background: #f2f2f2;
padding-top: 5rem;
`;
const Section2 = styled(Section)`
background: #ffffff;
`;
const Section3 = styled(Section)`
background: #f2f2f2;
`;
const Section4 = styled(Section)`
background: #ffffff;
`;
const IntroP = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  text-align: center;
  width: 65%;
  color: ${props => props.theme.darkgreyColor};
  @media (max-width: 768px){
    width: 100%;
  }
`;
const SectionIntro = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
`;
class DataCenterPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      vegetations: [],
      precipitations: [],
      fires: [],
      others: [],
      showPDF: false,
      pdf: ""
    };
  }
  componentDidMount(){
    this.getContent();
    // processPDF();
  }


  getContent(){
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(), requestConfig)
      .then((resp)=>{
        let vegetations = resp.data.data.vegetations.edges;
        this.setState({vegetations: vegetations});

        let precipitations = resp.data.data.precipitations.edges;
        this.setState({precipitations: precipitations});

        let fires = resp.data.data.fires.edges;
        this.setState({fires: fires});

        let others = resp.data.data.others.edges;
        this.setState({others: others});
      }).catch(error => {
        throw(error);
      });
  }
  onReadMore=(read)=>{
    this.setState({pdf: read});
    this.setState({showPDF: true});
  };
  onReadMoreClose=()=>{
    this.setState({showPDF: false});
  };

  render() {
    let page ={
      title: "Data Center",
      description: "The Data Center",
      keywords:"Data Center"
    };
    return (
      <Layout page={page}>
        <CenterContainer>
          <Left showPDF={this.state.showPDF}>
            <FluidContainer>
              <Row>
                <Col12 style={{ padding: 0}}>
                  <PageBanner style={{background: `url(${config.PUBLIC_URL}/img/datacenter/datacenter-banner.jpg) center center/cover no-repeat fixed`}}>
                    <BannerTitle>EO Data</BannerTitle>
                    <BannerDesc>Discover and Download</BannerDesc>
                    <HBorderLight />
                  </PageBanner>
                  <PageIntro>
                    <PageIntroContent>
                      <PageTitle>EO Time-Series Data for Environmental Monitoring</PageTitle>
                      <PageSubTitle>
                        <p>Browse our vast EO database and in 3 easy steps download your EO data of interest. The database contains daily, 10-day,
                          monthly, seasonal and yearly aggregates as well as anomalies and long-term statistics.</p>
                      </PageSubTitle>
                    </PageIntroContent>
                  </PageIntro>
                </Col12>
              </Row>
            </FluidContainer>

            <Section1>
              <Container>
                <SectionIntro>
                  <H1>Vegetation</H1>
                  <IntroP>Download EO vegetation indicators to monitor vegetation conditions over your area of interest.
                    Five vegetation indices products are available for download: NDVI, DMP, fAPAR, fcover and LAI.
                  </IntroP>
                </SectionIntro>
                {this.state.vegetations.length === 0 ? "" :
                  <VegetationCards vegetations={this.state.vegetations} onReadMore={this.onReadMore}/>
                }
              </Container>
            </Section1>

            <Section2>
              <Container>
                <SectionIntro>
                  <H1>Precipitation</H1>
                  <IntroP>
                    Download EO rainfall estimate data products for your area of interest. Five rainfall estimate products
                    are available for download: CHIRPS, FEWSNET, TAMSAT, Arc2-Rain and ECMWF. These products are developed
                    by different institutions using different methodology check documentation.
                  </IntroP>
                </SectionIntro>
                {this.state.precipitations.length === 0 ? "" :
                  <PrecipitationCards precipitations={this.state.precipitations} onReadMore={this.onReadMore}/>
                }
              </Container>
            </Section2>

            <Section3>
              <Container>
                <SectionIntro>
                  <H1>Fire</H1>
                  <IntroP>
                    Two fire products are available for download: Active Fires and Burnt Area.
                  </IntroP>
                </SectionIntro>
                {this.state.fires.length === 0 ? "" :
                  <FireCards fires={this.state.fires} onReadMore={this.onReadMore}/>
                }

              </Container>
            </Section3>

            <Section4>
              <Container>
                <SectionIntro>
                  <H1>Others</H1>
                  <IntroP>
                    Download EO Temperature, Evapotranspiration and Soil moisture content for your area of interest. The temperature and
                    evapotranspiration data are from LSASAF while the soil moisture is from CPC-NOAA.
                  </IntroP>
                </SectionIntro>

                {this.state.others.length === 0 ? "" :
                  <OthersCards others={this.state.others}  onReadMore={this.onReadMore}/>
                }
              </Container>
            </Section4>

          </Left>
          <Right showPDF={this.state.showPDF}>

            <span data-tip="Close"><IoIosCloseCircle onClick={this.onReadMoreClose} color="#ffffff" size={25} style={{position:"absolute", left: 0, top: 0, cursor: 'pointer', zIndex: 1 }}/></span><ReactTooltip place="right" type="light" />
            <a data-tip="Download" target="_blank" rel="noopener noreferrer" href={`${config.ROOT_URL}/media/${this.state.pdf}`}><IoMdDownload color="#ffffff" size={25} style={{position:"absolute", left: 0, top: 35, cursor: 'pointer', zIndex: 1 }}/></a><ReactTooltip place="right" type="light" />

            <PDFDisplay pdf={this.state.pdf}/>
          </Right>
        </CenterContainer>
      </Layout>
    )
  }
}
function mapStateToProps(state) {
  return {
    viewport: state.viewport,
  };

}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(viewportActions, dispatch)
  };
}

export default  connect(mapStateToProps, mapDispatchToProps)(DataCenterPage);

function getQuery() {
  return `
  query{
    vegetations:allData(dataType_Slug:"vegetation"){
      edges{
        node{
          id,
          name,
          abbr,
          slug,
          shortcode,
          dataType{
            id,
            name,
            image
          },
          timeSince,
          spatialRes,
          temporalRes,
          readmore

        }
      }
    },
    precipitations:allData(dataType_Slug:"precipitation"){
      edges{
        node{
          id,
          name,
          abbr,
          slug,
          shortcode,
          dataType{
            id,
            name,
            image
          },
          timeSince,
          spatialRes,
          temporalRes,
          readmore

        }
      }
    },
    fires:allData(dataType_Slug:"fire"){
      edges{
        node{
          id,
          name,
          abbr,
          slug,
          shortcode,
          dataType{
            id,
            name,
            image
          },
          timeSince,
          spatialRes,
          temporalRes,
          readmore

        }
      }
    },
    others:allData(dataType_Slug:"other"){
      edges{
        node{
          id,
          name,
          abbr,
          slug,
          shortcode,
          dataType{
            id,
            name,
            image
          },
          timeSince,
          spatialRes,
          temporalRes,
          readmore

        }
      }
    }
  }
`
}


