import React, {Component} from 'react';
import styled from "styled-components";
import {Container} from "../../../../theme/grid";
import {loadLegend} from "../../../../actions/loadCSV";

const Legend =styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: row;
padding: 0.125rem;
align-items: center;
`;
const Color =styled.div`
width: 25px;
height: 15px;
`;
const Name =styled.div`
width: 100%;
padding-left: 0.25rem;
font-size: 0.75rem;
`;
class LandCoverChangeLegend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      errors: []
    }
  }
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState){
    if (prevProps.coverChangeLegend !== this.props.coverChangeLegend) {
      this.getData();
    }
  }

  getData=()=>{
    loadLegend(this.props.coverChangeLegend).then((results)=>{
      this.setState({results: results.data});
      this.setState({errors: results.errors});
    })
  };


  render() {
    return (
      <Container style={{padding: '1rem'}}>
        {this.state.results ?
          this.state.results.map((legend, index) => {
            return <Legend key={index}><Color
              style={{backgroundColor: `${legend.color}`}}/><Name>{legend.name}</Name></Legend>
          })
          : ""
        }
      </Container>
    )
  }

}

export default LandCoverChangeLegend;
