import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({name, label, rows, onChange, placeholder, value, error}) => {

  return (
    <div className="text-area">
      {label ? <label>{label}</label>: ""}
      <div className="field">
        <textarea
          name={name}
          className="area-input"
          placeholder={placeholder}
          value={value}
          rows={rows}
          onChange={onChange}/>
        {error && <span className="input-error">{error}</span>}
      </div>
    </div>
  );
};

TextArea.propTypes = {
  name:PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  rows: PropTypes.number
};

export default TextArea;
