import React from 'react';
import PropTypes from 'prop-types';
import {FluidContainer, Row, Col2, Col4, Col5, Col12 } from "../../theme/grid";
import TextInput from '../input/TextInput';
import TextArea from '../input/TextArea';
import PasswordInput from '../input/PasswordInput';
import PasswordRegistration from '../input/PasswordRegistration';
import {Submit, LineSubmit} from "../buttons"
import config from "../../config";
import styled from "styled-components";
import ReactSelect from "../input/ReactSelect";


const H3 = styled.h3`
    font-size: 1.3rem;
    margin: 0;
    font-weight: 300;
    text-decoration: underline;
`;

const dataOptions =(options) =>{
  return options.map(option =>{
    return{
      value: option.node.pk,
      label: option.node.name
    }
  })
};

const RegistrationForm = ({registration, country, countries, regFail, onRegistration,onCountryChange, onPasswordChange, onLoginForm, onChange, loading, errors}) => {
  return (
    <FluidContainer>
      <form style={{fontSize: '0.85rem'}}>
        <Row style={{justifyContent: 'center'}}>
        <H3>Registration</H3>
          <p style={{textAlign: 'center', margin: '0.5rem 0', fontSize: '0.85rem'}}>Kindly provide us with the following details, an email will be sent to you with an ftp link
            from where you can download the requested data</p>
        </Row>
        <hr />
        <Row style={{justifyContent: 'space-between', padding: '0.3rem 0'}}>
          <Col4>
            <TextInput
              name="first_name"
              label="First Name"
              value={registration.first_name}
              onChange={onChange}
              error={errors.first_name}/>
          </Col4>
          <Col4>
            <TextInput
              name="last_name"
              label="Last Name"
              value={registration.last_name}
              onChange={onChange}
              error={errors.last_name}/>
          </Col4>
          <Col4>
            <TextInput
              name="email"
              label="Email"
              value={registration.email}
              onChange={onChange}
              error={errors.email}/>
          </Col4>
        </Row>
        <Row style={{justifyContent: 'space-between', padding: '1rem 0'}}>
          <Col5>
            <TextInput
              name="organization"
              label="Organization"
              value={registration.organization}
              onChange={onChange}
              error={errors.organization}/>
          </Col5>
          <Col5>
            <ReactSelect
              onChange={onCountryChange}
              name="country"
              value={country}
              options={dataOptions(countries)}
              label="Country"
            />
            {errors.country && <span className="input-error">{errors.country}</span>}
          </Col5>
        </Row>
        <Row style={{justifyContent: 'space-between', padding: '0.3rem 0'}}>

        </Row>

        <Row style={{justifyContent: 'center', padding: '0.3rem 0'}}>

          <Col12>
            <TextArea
              name="data_use"
              label="How you intend to use the data"
              value={registration.data_use}
              onChange={onChange}
              error={errors.data_use}/>
          </Col12>
        </Row>

        <Row style={{justifyContent: 'space-between', padding: '1rem 0'}}>
          <Col5>
            <PasswordRegistration
              name="password"
              label="Password"
              onChange={onPasswordChange}
              error={errors.password} />
          </Col5>
          <Col5>
            <PasswordInput
              name="password_confirm"
              label="Confirm Password"
              value={registration.password_confirm}
              onChange={onChange}
              error={errors.password_confirm}/>
          </Col5>
          <Col12 style={{textAlign: 'center', padding: '0.3rem'}}>
            {errors.passwords ?  <span className="errors" >{errors.passwords}</span>:""}
          </Col12>
        </Row>
        <hr />

        <Row style={{justifyContent: 'center', padding: '0.3rem 0'}}>
          {regFail && <span className="errors">{regFail}</span>}
        </Row>
        <Row style={{justifyContent: 'space-evenly'}}>
          <Col2>
            <LineSubmit onClick={onLoginForm} text="Login" />
          </Col2>
          <Col2>
            {loading ? <img src={`${config.PUBLIC_URL}/img/button-loading.gif`} alt="Button Loading" height={35} width={35}/>
              :
              <Submit onClick={onRegistration} text="Register" />
            }
          </Col2>

        </Row>
      </form>
    </FluidContainer>
  );
};

RegistrationForm.propTypes = {
  registration: PropTypes.object.isRequired,
  onRegistration: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  regFail: PropTypes.string,
  loading: PropTypes.bool,
  errors: PropTypes.object

};

export default RegistrationForm;
