import React from 'react';
import styled from 'styled-components';
import {Row, Col12 } from "../../../theme/grid";
import { RadioInput } from "../../../components/input";

const Select = styled(Col12)`
  padding-bottom: 1.5rem;
  font-size: 0.85rem;
`;
const DataCategory = styled.div`
  padding-bottom: 0.25rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  border-bottom: 1px solid #dddddd;
  font-weight: 600;
`;
const InputContainer = styled.div`
  padding: 0 1rem;
  font-weight: 600;
  color: #888888;
  font-size: 0.8rem !important;
`;
const dataOptions =(options) =>{
  return options.map(option =>{
    return{
      value: option.node.slug,
      label: option.node.shortcode,
      category: option.node.dataType.slug
    }
  })
};
// const getDataFilters=(options)=> {
//   if (this._isMounted) {
//     let dataSets = this.state.allData;
//     console.log(dataSets);
//     const dataSet = dataSets.filter(set => set.node.dataType.slug === 'vegetation');
//     console.log(dataSet);
//   }
// }


const DataFilter =({onChange, dataType, options})=>{
  return(
    <Row>
      <Select>
        <DataCategory>Vegetation</DataCategory>
        {dataOptions(options).filter(option => option.category ==="vegetation").map( data => {
          return(
            <InputContainer key={data.value}>
              <RadioInput
                onChange={onChange}
                name="dataType"
                defaultChecked={dataType === data.value}
                label={data.label}
                style={{borderRadius: '3px'}}
                value={data.value}/></InputContainer>
          )})}
        <DataCategory>Precipitation</DataCategory>
        {dataOptions(options).filter(option => option.category ==="precipitation").map( data => {
          return(
            <InputContainer key={data.value}>
              <RadioInput
                onChange={onChange}
                name="dataType"
                defaultChecked={dataType === data.value}
                label={data.label}
                style={{borderRadius: '3px'}}
                value={data.value}/></InputContainer>
          )})}
        <DataCategory>Fire</DataCategory>
        {dataOptions(options).filter(option => option.category ==="fire").map( data => {
          return(
            <InputContainer key={data.value}>
              <RadioInput
                onChange={onChange}
                name="dataType"
                defaultChecked={dataType === data.value}
                label={data.label}
                style={{borderRadius: '3px'}}
                value={data.value}/></InputContainer>
          )})}
        <DataCategory>Other</DataCategory>
        {dataOptions(options).filter(option => option.category ==="other").map( data => {
          return(
            <InputContainer key={data.value}>
              <RadioInput
                onChange={onChange}
                name="dataType"
                defaultChecked={dataType === data.value}
                label={data.label}
                style={{borderRadius: '3px'}}
                value={data.value}/></InputContainer>
          )})}

      </Select>
    </Row>
  )
};

export default DataFilter;
