import React from "react";
import {Row} from "../../../theme/grid";
import EOCard from "./EOCard";



const  OthersCards =({others, onReadMore}) =>(
  <Row style={{justifyContent: 'center'}}>
    {others.map(other =>(
      <EOCard
        key={other.node.id}
        eodata={other.node.shortcode}
        color="#20B4FF"
        title={other.node.name}
        since={other.node.timeSince}
        spatial_res={other.node.spatialRes}
        temporal_res={other.node.temporalRes}
        image={other.node.dataType.image}
        link={other.node.slug}
        onReadMore={()=>onReadMore(other.node.readmore)}
      />
    ))}

  </Row>
);

export default OthersCards;
