import JSZip from "jszip";
import {slugify} from "./slugify";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";


export default function zipDownload(links, name){
  let zip = new JSZip();
  let count = 0;
  let zipFilename = `${slugify(name)}.zip`;
  links.forEach(function (url, i) {
    let parts = url.split('/');
    let filename = parts.pop() || parts.pop();
    // filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpssequenceimagestaging.blob.core.windows.netretouch","");
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        throw err;
      }
      zip.file(filename, data, { binary: true });
      count++;
      if (count === links.length) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          FileSaver.saveAs(content, zipFilename);
        });
      }
    });
  });
};
