import React, {Component, Fragment} from 'react';
import Layout from "../../layout";
import styled from "styled-components";
import {Container, Row, Col4, Col8, Col12} from "../../theme/grid";
import {requestConfig} from "../../utils/requestConfig";
import config from "../../config";
import axios from "axios";
import moment from "moment";
import parse from "html-react-parser";
import {isEmpty} from "../../utils/isEmpty";
import {DownloadPDF, FBShare, TwitterShare} from "../../components/buttons";
import {LazyImage} from "../../utils/Image";



const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 10vh;
`;

const H3 = styled.h3`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;

const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.4rem;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;


const Title = styled.h5`
  color: ${props => props.theme.blackColor};
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;

const Category = styled.h6`
  color: ${props => props.theme.blackColor};
  font-family: ${props => props.theme.bodyFont};
  font-size: 0.85rem;
  font-weight: 600;
  span{
    color: ${props => props.theme.darkgreyColor};
  }
`;
const Date = styled.h6`
  color: ${props => props.theme.darkgreyColor};
  font-family: ${props => props.theme.bodyFont};
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

class SingleNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {}
    };

  }
  componentDidMount(){
    let slug = this.props.match.params.slug;
    this.getContent(slug);
  }

  componentDidUpdate(prevProps){
    let slug = this.props.match.params.slug;
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.getContent(slug);
    }

  }


  getContent(slug){
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(slug), requestConfig)
      .then((resp)=>{
        let content = resp.data.data.content;
        this.setState({content: content});
      }).catch(error => {
        throw(error);
      });
  }
  render() {
    const content = this.state.content;
    let page ={
      url: this.props.location.pathname,
      title: `${content.title}`,
      description: `${content.content}`
    };
    return (
      <Layout page={page}>

        {isEmpty(this.state.content) ?
          <Loading />
          :
          <Fragment>
            <PageBanner>
              <Container style={{textAlign: "center"}}>
                <PageTitle>News</PageTitle>
              </Container>
            </PageBanner>
            <Container style={{marginTop: '1rem', marginBottom: '2rem'}}>

              <Title>{content.title}</Title>
              <Date>{moment(content.timestamp).format('DD MMM YYYY')}</Date>

              <Row>
                <Col4>
                  <LazyImage src={`${config.ROOT_URL}/media/${content.image}`} alt={`${content.title} cover`} width="100%" height="auto" style={{objectFit: 'contain'}}/>
                </Col4>
                <Col8 style={{ display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
                  <Content>{parse(`${content.summary}`)}</Content>
                  <Row >
                    <Col12 style={{height: '2rem', marginTop: '0.5rem', marginBottom: '1rem', display: 'flex', alignItems: 'center'}}>
                      <Category>Tags: <span>{`${content.service.title} ${content.type.title}`}</span></Category>
                    </Col12>

                  </Row>
                  <Row>
                    <Col8  style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                      {content.pdf ?
                        <DownloadPDF
                          to={`${config.ROOT_URL}/media/${content.pdf}`}
                          text="Download Document"/>:
                        ""
                      }
                      <FBShare title={content.title} to={this.props.location.pathname}/>
                      <TwitterShare title={content.title} to={this.props.location.pathname}/>
                    </Col8>
                  </Row>
                </Col8>
              </Row>

              <Row style={{marginTop: '1rem'}}>
                <Col12>
                  <Content>{parse(`${content.content}`)}</Content>
                </Col12>
              </Row>

            </Container>
          </Fragment>
        }
      </Layout>
    )
  }
}
function getQuery(slug) {
  return `
  query{
    content:news(slug:"${slug}"){
      id,
      title,
      timestamp,
      image,
      slug,
      summary,
      pdf,
      content,
      service{
        title
      },
      type{
        title
      }

    }
  }
`
}
export default SingleNews;

