import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Row} from "../../../theme/grid";
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const Section = styled(Row)`
  border: 1px solid #eeeeee;
  padding: 1rem;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;

`;

const TimeFilter = ({onChange, startDate, endDate, onStartDateChange, onEndDateChange, timeSince, endTimeSince}) =>{
  return(
    <Section style={{justifyContent: "space-evenly"}}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            margin="normal"
            id="start-date-picker"
            label="Start Date"
            inputVariant="outlined"
            views={["year", "month"]}
            minDate={new Date(timeSince)}
            maxDate={new Date()}
            value={startDate}
            onChange={onStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            margin="normal"
            id="end-date-picker"
            label="End Date"
            inputVariant="outlined"
            views={["year", "month"]}
            minDate={new Date(endTimeSince)}
            maxDate={new Date()}
            value={endDate}
            onChange={onEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </Section>
  )

};

export default TimeFilter;
