import React, {Component} from 'react';
import Layout from "../../layout";
import styled, { css } from "styled-components";
import {IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline} from 'react-icons/io'
import {Container, Row, Col6} from "../../theme/grid";
import {MetaButton} from "../../components/buttons";
import {withRouter} from "react-router-dom";
import axios from "axios";
import config from "../../config";


const ActivationContainer = styled(Container)`
    min-height: 70vh;
`;
const H1 = styled.h1`
  color: ${props => props.theme.greenColor};
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  ${props => props.error && css`
    color: ${props => props.theme.brownColor};
  `}
`;
const H4 = styled.h4`
  font-family: ${props => props.theme.headerFont};
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem;
  ${props => props.error && css`
    color: ${props => props.theme.brownColor};
  `}
`;


class AccountActivationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasError: false,
      error: {},
    };
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.activateAccount();
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState){

  }
  activateAccount =()=>{
    let activate = this.props.match.params;
    return axios.post(`${config.ROOT_URL}/accounts/activation/${activate.uid}/${activate.token}`, activate)
      .then( () => {
      }).catch(error => {
        this.setState({ hasError: true });
        this.setState({ error: error.response.data });
        throw (error);
      });
  };

  toHomePage =()=>{
    const { history } = this.props;
    history.push(`/`);
  };

  render() {
    let page ={
      title: "Account Activation"
    };
    return (
      <Layout page={page}>
        <ActivationContainer>
          <Row style={{justifyContent: 'center'}}>
            <Col6 style={{padding: '2rem', textAlign: 'center'}}>
              {this.state.hasError ? <IoIosCloseCircleOutline size={100} color='#A52A2A' />:<IoIosCheckmarkCircleOutline size={100} color='#0B612D' />}

              <H1 error={this.state.hasError}>Account Activation</H1>
              <H4 error={this.state.hasError}>{this.state.hasError ? "There was an error in activating your account":"You have successfully activated your account"}</H4>
              {this.state.hasError ? <span>Details<H4 error={this.state.hasError}>{this.state.error.detail}</H4></span>:""}
              <MetaButton onClick={this.toHomePage} text='Go To Homepage' />
            </Col6>
          </Row>
        </ActivationContainer>
      </Layout>
    )
  }
}

export default withRouter(AccountActivationPage);
