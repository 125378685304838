import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store/configureStore';
import { ThemeProvider } from 'styled-components';
import {Theme, GlobalStyle} from './theme';
import routes from "./routes";
import ScrollToTop from "./utils/ScrollToTop";
import {setViewPort} from "./actions/viewportActions";
import {refreshToken} from "./actions/authActions";
import PageTracker from "./utils/PageTracker";


const store = configureStore();

store.dispatch(setViewPort());

if(localStorage.ACCESS_TOKEN){
  let refresh = {"refresh": localStorage.REFRESH_TOKEN};
  store.dispatch(refreshToken(refresh));
}

history.listen(location => {
  const { hash } = location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(
      () => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      },
      0
    );
  }
});

class App extends Component {
    render(){
        return(
          <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={Theme}>
                  <PageTracker/>
                    <ScrollToTop>
                        <Switch>
                            {routes.map((route, i) =><Route key={i} {...route} />)}
                        </Switch>

                        <GlobalStyle/>
                    </ScrollToTop>
                </ThemeProvider>
            </ConnectedRouter>
          </Provider>
        )
    }
}

export default App;
