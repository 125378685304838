import React, {Component} from 'react';
import styled from "styled-components";
import {Container} from "../../../../theme/grid";
import {ecoLegends, loadLegend} from "../../../../actions/loadCSV";


const Legend =styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: ${props => props.horizontal ? "column" : "row"};
`;
const Color =styled.div`
width: 40px;
height: 15px;
`;
const Name =styled.div`
  width: 100%;
  font-size: 0.75rem;
  text-align: center;
  padding-top: ${props => props.horizontal ? "0.25rem" : "0.1rem"};
}
`;
const Block =styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    flex-direction: ${props => props.horizontal ? "column" : "row"};
`;
class RFELegend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      errors: []
    }
  }
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    this.getData();
  }
  componentWillUnmount(){
    this._isMounted = false;
  }
  getData=()=>{
    ecoLegends().then((data)=>{
      loadLegend(data.rfe).then((results)=>{
        if(this._isMounted) {
          this.setState({results: results.data});
          this.setState({errors: results.data.errors});
        }
      })
    })

  };

  render() {
    return (
      <Container style={{padding: '0.5rem', alignSelf: 'center'}}>
        <Block  horizontal={!this.props.horizontal}>
          {this.state.results ?
            this.state.results.map((legend, index) => {
              return <Legend key={index} horizontal={this.props.horizontal}>
                <Color style={{backgroundColor: `${legend.color}`}}/>
                <Name style={this.props.style}>{legend.label}</Name>
              </Legend>
            })
            : ""
          }
        </Block>
      </Container>
    )
  }
}

export default RFELegend;
