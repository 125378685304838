import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ajaxCallsInProgress from './ajaxStatusReducer';
import isAuthenticated from './authenticatedReducer';
import accessToken from './accessTokenReducer';
import refreshToken from './refreshTokenReducer';
import user from './userReducer';
import modal from './authModalReducer';
import viewport from './viewportReducer';
import country from './countryReducer';
import areaType from './areaTypeReducer';
import area from './areaReducer';
import format from './formatReducer';
import pa_area from './paAreaReducer';
import admin_area from './adminAreaReducer';




export const rootReducer =(history)=> combineReducers({
  router: connectRouter(history),
  ajaxCallsInProgress,
  isAuthenticated,
  accessToken,
  refreshToken,
  user,
  modal,
  viewport,
  country,
  areaType,
  area,
  format,
  pa_area,
  admin_area
});
