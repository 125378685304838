import LandingPage from "../pages/LandingPage";
import AboutPage from "../pages/AboutPage";
import PolicyPage from "../pages/PolicyPage";
import GetInTouch from "../pages/GetInTouch"; 
import NotFound from "../pages/NotFound";
import WhatWeDoPage from "../pages/WhatWeDoPage";
import BulletinsPage from "../pages/BulletinsPage";
import LandCoverFirePage from "../pages/landcoverfire/LandCoverFirePage";
import EcoClimaticPage from "../pages/ecoclimatic/EcoClimaticPage";
import PublicationsPage from "../pages/publications/PublicationsPage";
import VacanciesPage from "../pages/VacanciesPage";
import TendersPage from "../pages/TendersPage";
import NewsPage from "../pages/news/NewsPage";
import SingleNews from "../pages/news/SingleNews";
import DataCenterPage from "../pages/datacenter/DataCenterPage";
import DashboardPage from "../pages/DashBoardPage";
// Download Pages
import DownloadPage from "../pages/download/DownloadPage";
// import TestPage from "../pages/TestPage";
import AccountActivationPage from "../pages/account/AccountActivationPage";
import PasswordResetPage from "../pages/account/PasswordResetPage";
import CropForagePage from "../pages/cropforage/CropForagePage";



const routes = [
  {path: "/", component: LandingPage, exact: true},
  {path: "/about-us", component: AboutPage},
  {path: "/what-we-do/:slug", component: WhatWeDoPage},
  {path: "/publications", component: PublicationsPage},
  {path: "/data-center", component: DataCenterPage, exact: true},
  {path: "/data-center/:slug", component: DownloadPage},

  {path: "/dashboard", component: DashboardPage, exact: true},
  {path: "/dashboard/land-cover-fires", component: LandCoverFirePage},
  {path: "/dashboard/bulletins", component: BulletinsPage},

  {path: "/dashboard/eco-climatic-analysis", component: EcoClimaticPage},
  {path: "/dashboard/crop-forage-conditions", component: CropForagePage},
  {path: "/work-with-us/vacancies", component: VacanciesPage},
  {path: "/work-with-us/tenders", component: TendersPage},
  {path: "/news", component: NewsPage, exact: true},
  {path: "/news/:slug", component: SingleNews},
  {path: "/policy", component: PolicyPage},
  {path: "/get-in-touch", component: GetInTouch},
  {path: "/account/activation/:uid/:token", component: AccountActivationPage},
  {path: "/account/reset-password/:uid/:token", component: PasswordResetPage},
  // {path: "/test", component: TestPage},
  {path: "/404", component: NotFound},
  {component: NotFound}
];
export default routes;

