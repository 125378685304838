import React, {Component} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

class NotAvailable extends Component{

  render(){
    return(
      <Container>
        <h1>Not Available</h1>
        <h5><strong>The data is not available</strong> </h5>
      </Container>
    )
  }
}

export default NotAvailable;
