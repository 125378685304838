import React from 'react';
import PropTypes from 'prop-types';
import {Col2, Col8, FluidContainer, Row} from "../../theme/grid";
import TextInput from '../input/TextInput';
import {LineSubmit, Submit} from "../buttons"
import styled from "styled-components";
import config from "../../config";

const H3 = styled.h3`
    font-size: 1.3rem;
    margin: 0;
    font-weight: 300;
    text-decoration: underline;
`;

const ForgotPasswordForm = ({forgot, onForgot, forgotFail, onChange, loading, errors, onLoginForm}) => {
  return (
    <FluidContainer>
      <form style={{fontSize: '0.85rem'}}>
        <Row style={{justifyContent: 'center'}}>
          <H3>Forgot Password</H3>
        </Row>
        <hr />
        <Row style={{justifyContent: 'center'}}>
          <Col8 style={{padding: '1rem 0'}}>
            <TextInput
              name="email"
              label="Email"
              value={forgot.email}
              onChange={onChange}
              error={errors.email}/>
          </Col8>
        </Row>

        <Row style={{justifyContent: 'center'}}>
          {forgotFail && <span className="errors">{forgotFail}</span>}
        </Row>
        <Row style={{justifyContent: 'space-evenly'}}>
          <Col2>
            <LineSubmit onClick={onLoginForm} text="Login" />
          </Col2>
          <Col2>
            {loading ? <img src={`${config.PUBLIC_URL}/img/button-loading.gif`} alt="Button Loading" />
              :
              <Submit onClick={onForgot} text="Submit" />
            }
          </Col2>
        </Row>
      </form>
    </FluidContainer>
  );
};

ForgotPasswordForm.propTypes = {
  forgot: PropTypes.object.isRequired,
  onForgot: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  forgotFail: PropTypes.string,
  loading: PropTypes.bool,
  errors: PropTypes.object

};

export default ForgotPasswordForm;
