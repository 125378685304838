import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Row, Col3, Col9} from '../../theme/grid'
import {LineButton} from "../../components/buttons";
import config from "../../config";
import {LazyImage} from "../../utils/Image";
import parse from "html-react-parser";


const NewsRow = styled(Row)`
  margin-bottom: 35px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  border-radius: 0.2rem;
  box-shadow: 2px 1px 25px 5px rgba(166, 166, 166, 0.2);
  border: 1px solid #f0f0f0;
`;
const Title = styled.h5`
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
`;

const Content = styled.div`
  background: #ffffff;
  padding: 1rem;

  p{
    font-size: 0.85rem;
  }

`;
const Text = styled.div`
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 400;
`;
const Date = styled(Text)`
  color: ${props => props.theme.greenColor};
  padding: 0.35rem;
  border-radius: 0.2rem;
`;

const NewsItem = ({ publication })=>{
  return(
    <NewsRow className='no-gutters'>
      <Col3>
        <LazyImage src={`${config.ROOT_URL}/media/${publication.node.coverImage}`} alt={`${publication.node.title}`} width="100%" height="250" style={{objectFit: 'cover', borderRight: '1px solid #ddd'}}/>
      </Col3>
      <Col9>
        <Content>
          <Title className="heading_title">{publication.node.title}</Title>
          <Date>{moment(publication.node.date).format('DD MM YYYY')}</Date>
          {parse(`${publication.node.description}`)}
        </Content>
        <Row style={{ justifyContent: 'flex-end', marginBottom: '1rem'}}>
          <Col3>
            <LineButton
              text="Download"
              link={{ pathname: `${config.ROOT_URL}/media/${publication.node.publication}`}}
            />
          </Col3>
        </Row>
      </Col9>
    </NewsRow>
  )
};

export default NewsItem;
