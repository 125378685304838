import React from 'react';
import styled  from 'styled-components';

const Section = styled.div`
display: flex;
margin: 0.5rem 0 0.5rem 0;
border-bottom: 1px solid ${props => (props.theme.greyColor)};
`;
const Tabs = styled.div`
  display: flex;
  flex-direction: row;
`;
const Tab = styled.div`
  font-family: ${props => (props.theme.headerFont)};
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  border-left: 1px solid #f1f1f1;
  text-decoration: none;
  color: ${props => (props.theme.greyColor)};
  background: #ffffff;
  cursor: pointer;
`;
const ActiveTab = styled(Tab)`
  color: #ffffff;
  background: ${props => (props.theme.greyColor)};
`;
const VariableFilter = ({variable, onAll, onNDVI, onRFE, onDMP, onFAPAR, onSM}) => {
  const AllTab = variable === "all" ? ActiveTab : Tab;
  const NDVITab = variable === "ndvi" ? ActiveTab : Tab;
  const RFETab = variable === "rfe" ? ActiveTab : Tab;
  const DMPTab = variable === "dmp" ? ActiveTab : Tab;
  const FAPARTab = variable === "fapar" ? ActiveTab : Tab;
  const SMTab = variable === "sm" ? ActiveTab : Tab;
  return (
    <Section>
      <Tabs>
        <AllTab onClick={onAll}>All</AllTab>
        <NDVITab onClick={onNDVI}>NDVI</NDVITab>
        <RFETab onClick={onRFE}>RFE</RFETab>
        <DMPTab onClick={onDMP}>DMP</DMPTab>
        <FAPARTab onClick={onFAPAR}>FAPAR</FAPARTab>
        <SMTab onClick={onSM}>SM</SMTab>
      </Tabs>

    </Section>
  );
};

export default VariableFilter;
