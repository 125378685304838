import React, { Component } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { IoIosLogIn } from 'react-icons/io';
import {bindActionCreators} from "redux";
import * as authActions from "../actions/authActions";
import {connect} from "react-redux";
import {requestConfig} from "../utils/requestConfig";
import config from '../config';
import Layout from "../layout";
import styled from "styled-components";
import {CardButton} from '../components/buttons';
import {Container, Row, Col3, Col4, Col12, FluidContainer} from "../theme/grid";
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import FrontPartners  from "../components/partners/FrontPartners";
import FrontLatestNews from "./news/front/FrontLatestNews";
import YouTubeFeed from "./news/front/youtubeFeed";
import {Link} from "react-router-dom";
import TwitterFeed from './news/front/twitterFeed';


const Slider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: calc(75vh - 2rem);
`;
const Slide = styled.div`
  min-height: 100%;
  min-width: 100%;
`;

const SlideContent = styled(Col12)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`;
const SlideTitle = styled.div`
  font-family: ${props => props.theme.headerFont};
  font-weight: 900;
  font-size: 5rem;
  color: #ffffff;
  padding-top: 20px;
`;

const SlideDescription = styled.div`
  font-family: ${props => props.theme.bodyFont};
  font-weight: 500;
  color: #ffffff;
  padding-top: 0.5rem;
  padding-bottom: 20px;
  p{
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;
const SlideInfo = styled.div`
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  width: fit-content;
`;
const SlideButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1rem 0;
  width: 50%;
`;
const Button = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: ${props => props.theme.yellowColor};
  padding: 0.5rem 1rem;
  margin: 1rem;
  font-size: 1rem;
  border-radius: 0.2rem;
  text-decoration: none;
  font-weight: 600;
  font-family: ${props => props.theme.bodyFont};
  &:hover {
    transition: .2s all ease-out;
    color: white !important;
  }
`;
const SignUpButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: ${props => props.theme.yellowColor};
  padding: 0.5rem 1rem;
  margin: 1rem 1rem 1rem 0;
  font-size: 1rem;
  border-radius: 0.2rem;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  font-family: ${props => props.theme.bodyFont};
  &:hover {
    transition: .2s all ease-out;
    color: white !important;
  }
`;
const Section = styled.section`
  width: 100%;
  padding: 3rem 0;
  position: relative;
`;
const Section1 = styled(Section)`
  background-color: #ffffff;
  padding: 0;
  position: relative;
`;
const Section2 = styled(Section)`

`;
const Section3 = styled(Section)`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.sectionBackground};
`;
const Section4 = styled(Section)`
    background-color: #FFFFFF;

`;
const Section5 = styled(Section)`
    background-color: ${props => props.theme.sectionBackground};
    padding: 4rem 0;
    justify-content: space-between;
    .section-intro{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      h1{
        font-family: ${props => props.theme.headerFont};
        font-size: 2rem;
        font-weight: 800;
        color: ${props => props.theme.blackColor};
        margin-top: 0;
        margin-bottom: 0;
      }
      p{
        font-size: 1.1rem;
        line-height: 1.4rem;
        width: 90%;
        text-align: center;
      }
    }
    .eod-container{
        padding: 10px;
        margin: 0.5rem;
        border: 1px solid #eeeeee;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
        height: 200px;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        color: ${props => props.theme.blackColor};
      &:hover{
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
      }
    .eod-content{
      width: 100%;
      height: 100%;
      h4{
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0.5rem;
      }
      ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
          font-size: 1rem;
          padding-bottom: 0.5rem;
        }
      }
    }
    }
`;
const Section6 = styled(Section)`
    background-color: #ffffff;
    padding-bottom: 0;
`;
const NewsSection = styled(Section)`
  background-color: #ffffff;
`;
const PartnerLogos = styled(Section)`
    background-color: #FFF;
    padding: 2rem 0;
    `;
const H1 = styled.h1`
  font-weight: 700;
  color: ${props => props.theme.blackColor};
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.8rem;
`;

const SiteIntro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 3rem;
  h1{
    font-weight: 700;
    color: ${props => props.theme.blackColor};
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.8rem;
  }
  p{
      font-size: 1.1rem;
    line-height: 1.6;
    font-weight: 400;
    text-align: center;
    width: 65%;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: ${props => props.theme.blackColor};
    @media (max-width: 768px){
      width: 100%;
    }
  }

`;
const IntroP = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  font-weight: 400;
  text-align: center;
  width: 65%;
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: ${props => props.theme.blackColor};
  @media (max-width: 768px){
    width: 100%;
  }
`;
const SectionIntro = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
  p{
    font-size: 1.1rem;
    line-height: 1.6;
    font-weight: 400;
    text-align: center;
    width: 65%;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: ${props => props.theme.blackColor};
    @media (max-width: 768px){
    width: 100%;
    }
  }
`;
const H1Intro = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;
const H1Title = styled.div`
  font-family: ${props => props.theme.headerFont};
  font-size: 2rem;
  font-weight: 800;
  color: ${props => props.theme.blackColor};
  margin-top: 0;
  margin-bottom: 0;
`;

const Area = styled.div`
  margin: 1rem;
  width: 250px;
  height: 300px;
  position: relative;
  padding: 15px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
`;
const AreaImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 270px;
`;

const AreaContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;
const AreaTitle = styled.h1`
  font-weight: 800;
  font-size: 1.6rem;
  color: #ffffff;
  text-align: center;
  span{
    font-size: 1.4rem;
    font-weight: 600;
  }
`;
const AreaButtons = styled.div`
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 13px;
`;
const Feed = styled.div`
  // display: flex;
  // gap: 20px;
  // width: 100%;
  // justify-content: center;
  // align-items: center;
  `

const ProductContainer = styled(Col4)`
  background: rgba(11,97,45, 0.7);
  margin-left: 10rem;
  color: #ffffff;
  padding: 2rem;
  @media (max-width: 768px){
    margin-left: 0;
  }
`;
const ProductList = styled.div`
  ul{
   list-style: none;
  }
  li{
    border-bottom: 1px solid #1e7e34;
    width: fit-content;
    padding: 0.2rem 0;
    margin: 0.5rem 0;
  }
`;

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sliders: [],
      partners: [],
      news: [],
      workareas: [],
      page: {}
    };
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getContent();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  getContent(){
    this.setState({loading: true});
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(), requestConfig)
      .then((resp)=> {
        let page = resp.data.data.page;
        let partners = resp.data.data.partners.edges;
        let news = resp.data.data.news.edges;
        let workareas = resp.data.data.workareas.edges;
        if (this._isMounted) {
          this.setState({page: page});
          this.setState({partners: partners});
          this.setState({news: news});
          this.setState({workareas: workareas});
          this.setState({loading: false});
        }
      }).catch(error => { 
        this.setState({loading: false});
        throw(error);
      });
  }
  onRegister=()=>{
    this.props.actions.onAuthModal()
  };
  render() {
    let page={
      title: "Global Monitoring for Environment and Security and Africa",
      description: "The Global Monitoring for Environment and Security and Africa (GMES & Africa) Support Programme is " +
        "the result of the longstanding cooperation between Africa and Europe in the area of space science & technology, " +
        "which is one of the key priorities of the long-term EU-Africa Joint Strategy"
    };
    return (
      this.state.loading ?
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src={`${config.PUBLIC_URL}/img/preview.gif`} alt="" />
        </div>
        :
        <Layout page={page} landing>
          <Section1>
            <Slider>
              <Slide
                style={{background: `url(${config.ROOT_URL}/media/${this.state.page.slideImg}) center center/cover no-repeat fixed`}}>
                <Container style={{height: '100%'}}>
                  <Row style={{height: '100%'}}>
                    <SlideContent>
                      <SlideInfo>
                        <SlideTitle>{this.state.page.slideTitle}</SlideTitle>
                        <SlideDescription>
                          {parse(`${this.state.page.slideDescription}`)}
                        </SlideDescription>
                      </SlideInfo>
                      <SlideButtons>
                        <SignUpButton href="https://icpac.net/subscribe/" target="_blank">Sign up <IoIosLogIn size={30}/> </SignUpButton>
                        <Button to={`/#news-section`}>Latest Updates</Button>
                      </SlideButtons>
                    </SlideContent>
                  </Row>
                </Container>
              </Slide>
            </Slider>
          </Section1>

          <Section2>
            <SiteIntro>
              {parse(`${this.state.page.siteIntro}`)}
            </SiteIntro>
          </Section2>

          <Section3>
            <Container>
              <SectionIntro>
                <H1>Areas of Work</H1>
                <IntroP>We focus on four services</IntroP>
              </SectionIntro>

              <Row style={{padding: "2rem 0", justifyContent: "space-between"}}>
                <Area>
                  <AreaImage src={`${config.PUBLIC_URL}/img/front/area-1.jpg`} alt="Natural Habitats Monitoring"/>
                  <AreaContent>
                    <AreaTitle>Natural Habitats <br/><span>Monitoring</span></AreaTitle>
                    <AreaButtons>
                      <CardButton text={`Read More`} link={`/what-we-do/natural-habitats`}/>
                      <CardButton text={`View Data`} link={`/data-center`}/>
                    </AreaButtons>
                  </AreaContent>
                </Area>

                <Area>
                  <AreaImage src={`${config.PUBLIC_URL}/img/front/area-2.jpg`} alt="Forest Monitoring"/>
                  <AreaContent>
                    <AreaTitle>Forest <br/><span>Monitoring</span></AreaTitle>
                    <AreaButtons>
                      <CardButton text={`Read More`} link={`/what-we-do/tropical-forest`}/>
                      <CardButton text={`View Data`} link={`/data-center`}/>
                    </AreaButtons>
                  </AreaContent>
                </Area>

                {/* <Area style={{position: "relative"}}>
                  <AreaImage src={`${config.PUBLIC_URL}/img/front/area-3.jpg`} alt="Agriculture Monitoring"/>
                  <AreaContent>
                    <AreaTitle>Agriculture <br/><span>Monitoring</span></AreaTitle>
                    <AreaButtons>
                      <CardButton text={`Read More`} link={`/what-we-do/agriculture-monitoring`}/>
                      <CardButton text={`View Data`} link={`/data-center`}/>
                    </AreaButtons>
                  </AreaContent>
                </Area> */}

                <Area>
                  <AreaImage src={`${config.PUBLIC_URL}/img/front/area-4.jpg`} alt="Rangeland Monitoring"/>
                  <AreaContent>
                    <AreaTitle>Rangeland <br/><span>Monitoring</span></AreaTitle>
                    <AreaButtons>
                      <CardButton text={`Read More`} link={`/what-we-do/rangeland-monitoring`}/>
                      <CardButton text={`View Data`} link={`/data-center`}/>
                    </AreaButtons>
                  </AreaContent>
                </Area>
              </Row>

            </Container>
          </Section3>

          <Section4>
            <Container>
              <H1Intro>
                <H1Title>How We Do It</H1Title>
              </H1Intro>
              {parse(`${this.state.page.howWedoit}`)}
            </Container>
          </Section4>

          <Section5>
            <Container>
              {parse(`${this.state.page.accessToEodata}`)}
            </Container>
          </Section5>

          <Section6>
            <Container>
              <Row>
                <Col12>
                  <SectionIntro>
                    <H1>EO Products</H1>
                    {parse(`${this.state.page.eoIntro}`)}
                  </SectionIntro>
                </Col12>
              </Row>
            </Container>
            <FluidContainer
              style={{background: `#e4e8ea url(${config.PUBLIC_URL}/img/front/product-background.jpg) no-repeat center center/cover fixed`}}>
              <ProductContainer>
                <ProductList>
                  {parse(`${this.state.page.eoProducts}`)}
                </ProductList>
              </ProductContainer>
            </FluidContainer>
          </Section6>
          <Section id="news-section" />
          <NewsSection>
        <FluidContainer>
          <SectionIntro>
            <H1>Latest News</H1>
          </SectionIntro>
          <Row style={{ justifyContent: 'center' }}>
            <FrontLatestNews news={this.state.news} />
            
            {/* <Col3>
              <TwitterFeed />
            </Col3> */}
            {/* <Col3>
              <YouTubeFeed />
            </Col3> */}
            
          </Row>
        </FluidContainer>
      </NewsSection>
      <NewsSection>
        <FluidContainer>
          <SectionIntro>
            <H1>Latest Updates</H1>
          </SectionIntro>
          <Row style={{ justifyContent: 'center' }}>
            {/* <FrontLatestNews news={this.state.news} /> */}
            
            <Col3>
              <TwitterFeed />
            </Col3>
            <Col3>
              <YouTubeFeed />
            </Col3>
            
          </Row>
        </FluidContainer>
      </NewsSection>

          <PartnerLogos>
            <Container>
              <SectionIntro>
                <H1>Partners</H1>
              </SectionIntro>
              <FrontPartners partners={this.state.partners}/>
            </Container>
          </PartnerLogos>
        </Layout>

    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.isAuthenticated,
    modal: state.modal
  };

}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

function getQuery() {
  return `
  query{
    page:landing(pageId: 1){
      slideTitle,
      slideDescription,
      slideImg,
      siteIntro,
      howWedoit,
      eoIntro,
      accessToEodata,
      eoProducts
    }
    partners{
      edges{
        node{
          id,
          title,
          image,
          link
        }
      }
    }

    news:allNews(first: 4, isFeatured: true){
      edges{
        node{
          id,
          title,
          slug,
          timestamp,
          image,
          summary
          pdf,
        }
      }
    }

    workareas:allWorkareas{
      edges{
        node{
          id,
          title,
          slug,
          description,
          topImage,
          pageIntro,
        }
      }
    }

  }
`
}



