import React, {useState, useEffect, useRef} from 'react';
import config from '../config'
import axios from 'axios';
import styled from 'styled-components';
import icpacLogo from './publications/bulletinfiles/images/igad_green.png'; 
import gmesLogo from './publications/bulletinfiles/images/gmes.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import DownloadButton from './publications/download';
import twitter from './publications/bulletinfiles/images/twitter.svg';
import facebook from './publications/bulletinfiles/images/facebook.svg';
import linkedIn from './publications/bulletinfiles/images/linkedin.svg';
import instagram from './publications/bulletinfiles/images/instagram.svg';
import Layout from '../layout';




const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 769px) {
    padding: 10px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  max-width:100%;

  // @media (min-width: 950px) {
  //   flex-direction: row;
  //   justify-content: space-between;
  // }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  max-width:100%;
  // @media (min-width: 769px)  {
  //   justify-content: flex-start;
  // }
`;

const Title = styled.h1`
  margin: 0;
  text-align: center;
  // @media (min-width: 769px){
  //   text-align: left;
  // }
`;

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  border-top: 2px solid orange;
  border-bottom: 2px dotted orange;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;

  // @media (min-width: 769px) {
  //   justify-content: flex-start;
  // }
`;

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  
  @media (min-width: 796px) and (max-width: 1200px) {
    flex-direction: column;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;
const Fdiv = styled.div`
    flex:2;  
    display: flex;
    flex-direction: column;
    gap:30px;
    @media (min-width: 768px) and (max-width:1200px) {
       flex-direction: row;
     }
    @media (min-width:1200px){
      flex-direction: row;
    }
    `

const SituationOverviewContainer = styled.section`
  background-color: #eceef0;
  padding: 10px;
  text-align: left;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  
`;

const SituationOverviewTitle = styled.h2`
  background-color: #e3e8f7;
  margin: -10px -10px 10px -10px;
  padding: 10px;
  color: black;
  box-sizing: border-box;
`;

const StyledList = styled.ul`
  list-style-type: disc;
  padding: 0;
  margin: 0;
  padding-left: 20px;
`;

const StyledListItem = styled.li`
  border-bottom: 1px solid grey;
  padding-top: 20px;
`;

const SectionUpper = styled.section`
`;

const GreenContainer = styled.div`
  background: #eceef0;
  padding: 10px;
  flex: 1.3;
`
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  @media (min-width: 769px) {
    flex-direction: row;
    
    align-items: flex-start;
  }
`;

const ParagraphsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;


const BottomSection = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  @media (min-width: 768px) and (max-width:1200px){
  flex-direction: column;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;
const Trial = styled.div`
    max-width: 100%;
    @media (min-width: 768px){
    max-width: 50%;
    }
    }`
const Sdiv = styled.div`
    flex:2;  
    display: flex;
    flex-direction: column;
    gap:30px;
    @media (min-width: 768px) and (max-width:1200px) {
       flex-direction: row;
     }
    @media (min-width:1200px){
      flex-direction: row;
    }
    `

const KeyMessagesContainer = styled.section`
  background-color: #eceef0;
  padding: 10px;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  flex: 1;

`;

const KeyMessagesTitle = styled.h2`
  background-color: #e3e8f7;
  margin: -10px -10px 10px -10px;
  padding: 10px;
  color: black;
`;

const KeyMessagesList = styled.ul`
  padding: 0;
  margin: 0;
`;

const KeyMessagesListItem = styled.li`
  margin-bottom: 10px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;
const Section = styled.div`
padding: 10px;
flex:1.3;
`
 const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  border-top: 1px solid blue;
  gap: 30px;

  @media (min-width: 769px) {
    flex-direction: row;
  }
  `
  const Contact = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media (min-width: 769px) {
    align-items: flex-end;
  }`

  const FooterImg = styled.div`
  display: flex;
  gap: 5px;`

  const BulletinsPage = () => {
    const [data, setData] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const bulletinRef = useRef(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${config.ROOT_URL}/api/bulletin-data/`);
          setData(response.data); // Set data received from API
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData(); // Fetch initial data when component mounts
    }, []); 
    useEffect(() => {
      if (data ) {
        setCurrentIndex(data.length - 1); // Set currentIndex to last index after data is fetched
      }
    }, [data]);
    if (!data) {
      return <div>Loading...</div>;
    }

  const incrementIndex = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1); // Increment currentIndex if not at the end
    }
  };

  const decrementIndex = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1); // Decrement currentIndex if not at the beginning
    }
  };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

   
    const handleDownloadPDF = async () => {
      const bulletinPage = bulletinRef.current;
  
      // Ensure images are loaded before converting to canvas
      const loadImages = (element) => {
        const images = element.getElementsByTagName('img');
        const promises = [];
  
        for (let img of images) {
          if (!img.complete) {
            promises.push(
              new Promise((resolve) => {
                img.onload = img.onerror = resolve;
              })
            );
          }
        }
  
        return Promise.all(promises);
      };
  
      await loadImages(bulletinPage);
  
      html2canvas(bulletinPage, {
        useCORS: true, // Use CORS to load images from other domains
        scale: 2, // Increase scale for higher resolution
        logging: true, // Enable logging for troubleshooting
        
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // A4 width in mm (210mm)
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('bulletin_page.pdf');
      });
    };
  
    
  return (
    <Layout page={{}}>
    <div>
      <div ref={bulletinRef}>
    <Container>
      <Header>
       
        <TitleContainer>
          <Title> GMES AND AFRICA <span style={{ color: 'orange' }}>Newsletter</span>
          <span style={{paddingLeft: '40px', fontSize: '15px'}}>Date:{`${formatDate(data[currentIndex].date)}`}</span>
          <span style={{paddingLeft:'50px', gap:'10px'}}><button onClick={decrementIndex}><strong>&lt;</strong></button>
          <button style={{marginLeft:'10px'}} onClick={incrementIndex}><strong>&gt;</strong></button></span>
          <button style={{background:'#e3e8f7', marginLeft:'30px', border:"none"}} onClick={handleDownloadPDF}><img width="25" height="25" src="https://img.icons8.com/pastel-glyph/64/download--v1.png" alt="download--v1"/>pdf</button>
          </Title>
          <IconsContainer>
          <IconTextContainer>
            <img src={`${data[currentIndex].forest_cover_icon}`} alt="Forest Cover Icon" height="50" />
            <div>
              <strong style={{ color: 'orange' }}>{data[currentIndex].forest_cover_value}</strong>
              <p>{data[currentIndex].forest_cover_text}</p>
            </div>
          </IconTextContainer>
          <IconTextContainer>
            <img src={`${data[currentIndex].deforestation_icon}`} alt="Deforested Area Icon" height="50" />
            <div>
              <strong style={{ color: 'orange' }}>{data[currentIndex].deforestation_value}</strong>
              <p>{data[currentIndex].deforestation_text}</p>
            </div>
          </IconTextContainer>
          <IconTextContainer>
            <img src={`${data[currentIndex].estimated_forest_cover_icon}`} alt="Estimated Forest Cover Icon" height="50" />
            <div>
              <strong style={{ color: 'orange' }}>{data[currentIndex].estimated_forest_cover_value}</strong>
              <p>{data[currentIndex].estimated_forest_cover_text}</p>
            </div>
          </IconTextContainer>
          <IconTextContainer>
            <img src={`${data[currentIndex].usd_needed_icon}`} alt="USD Needed Icon" height="50" />
            <div>
              <strong style={{ color: 'orange' }}>{data[currentIndex].usd_needed_value}</strong>
              <p>{data[currentIndex].usd_needed_text}</p>
            </div>
          </IconTextContainer>
        </IconsContainer>
      </TitleContainer>
    </Header>
    <Body>
      <TopSection>
        <Fdiv>
          <SituationOverviewContainer>
            <SituationOverviewTitle>Situation Overview</SituationOverviewTitle>
            <StyledList>
            {data[currentIndex].situation_overview.split('\n').map((text, index) => (
        
              <StyledListItem key={index}>{text}</StyledListItem>
            ))}
              
            </StyledList>
          </SituationOverviewContainer>
          <SectionUpper>
            <h2>Rainfall Probabilistic Forecast</h2>
            <Image src={`${data[currentIndex].rain_forecast_img}`} alt="Rainfall Forecast" />
            <DownloadButton imgSrc={`${data[currentIndex].rain_forecast_img}`} imgAlt="Rainfall Forecast" />
            <p>{data[currentIndex].rain_forecast_text}</p>
          </SectionUpper>
        </Fdiv>
        <GreenContainer> 
          <SituationOverviewTitle>Green cover</SituationOverviewTitle>
          <ImageContainer>
            <div style={{maxWidth : '450px'}}>
              <Image src={`${data[currentIndex].green_cover_img}`} alt="Green Cover" />
              <DownloadButton imgSrc={`${data[currentIndex].green_cover_img}`} imgAlt="Green Cover" />
            </div>
            <ParagraphsContainer>
              {data[currentIndex].green_cover_texts.split('\n').map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </ParagraphsContainer>
          </ImageContainer>
        </GreenContainer>
      </TopSection>
      <BottomSection>
        <Sdiv>
          <KeyMessagesContainer>
            <KeyMessagesTitle>Key Messages</KeyMessagesTitle>
            <KeyMessagesList>
              {data[currentIndex].key_messages.split('\n').map((message, index) => (
                <KeyMessagesListItem key={index}>{message}</KeyMessagesListItem>
              ))}
            </KeyMessagesList>
          </KeyMessagesContainer>
          <Trial >
            <h2>Graph</h2>
            <Image src={`${data[currentIndex].graph_img}`} alt="Graph" />
            <DownloadButton imgSrc={`${data[currentIndex].graph_img}`} imgAlt="Graph" />
          </Trial>
        </Sdiv>
        <Section>
          <h2>Distribution</h2>
          <Image src={`${data[currentIndex].distribution_img}`} alt="Distribution" />
          <DownloadButton imgSrc={`${data[currentIndex].distribution_img}`} imgAlt="Distribution" />
          <p>{data[currentIndex].distribution_text}</p>
        </Section>
      </BottomSection>
        <Footer>
         <p style={{flex: '1'}}>
          The use of political boundaries, geographical names, related information, and potentioal considerations for
           response are for reference, not warranted to be error free or implying offical endorsement by the IGAD Disaster Operations Center(IDOC) or from ICPAC Member Countries.
         </p>
         <p style={{flex: '1'}}>
           East Africa Drought Watch: <a href="http://droughtwatch.icpac.net">http://droughtwatch.icpac.net</a> 
         </p>
         <Contact>
          <FooterImg>
            <a href='https://www.facebook.com/ICPACigad/' ><img src={facebook} alt='facebook' height='40'/> </a>
            <a href='https://twitter.com/ICPAC_GMES?ref_src=twsrc%5Etfw'><img src={twitter} alt='twitter' height='40'/> </a>
            <a href='https://www.instagram.com/icpac_climatecenter/'><img src={instagram} alt='instagram' height='40'/> </a>
            <a href='https://ke.linkedin.com/company/igad-climate-prediction-and-aplication-center-icpac-'><img src={linkedIn} alt='linkedIn' height='40'/> </a>
          

          </FooterImg>
           <p>Contact: <a href='disaster-risk-management@igad.int'>disaster-risk-management@igad.int</a></p>
         </Contact>
        </Footer>
      </Body>
    </Container>
    </div>
    
    </div>
    </Layout>
  );
}

export default BulletinsPage;