import React from 'react';

const DownloadButton = ({ imgSrc, imgAlt }) => {
  return (
    <a href={imgSrc} download={imgAlt ? `${imgAlt}.jpg` : ''} style={{ textDecoration: 'none' }}>
      <button style={{ padding: '10px', background: 'orange', border: 'none', borderRadius: '5px', color: 'white', cursor: 'pointer' }}>
        View Image
      </button>
    </a>
  );
};


export default DownloadButton;
