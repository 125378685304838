import React, {Component, Fragment} from 'react';
import axios from 'axios';
import Layout from "../layout";
import styled from "styled-components";
import parse from 'html-react-parser';
import config from "../config";
import {Container, Row, Col5, Col4, FluidContainer} from "../theme/grid";
import {isEmpty} from "../utils/isEmpty";
import {requestConfig} from "../utils/requestConfig";
import WhatWedoPartners from "../components/partners/WhatWedoPartners";

const PageBanner = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  min-height: 350px;
`;
const BannerTitle = styled.div`
  width: fit-content;
  height: 100%;
  font-family: ${props => props.theme.headerFont};
  text-align: center;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 800;
  color: #ffffff;
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  padding: 1rem 0;
`;
const PageIntro = styled.div`
  z-index: 1;
  padding: 1rem;
  margin-top: -5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #f7f7f7;
  box-shadow: 0 5px 30px 0 rgba(39,39,39,0.15);
  background: #ffffff;
`;
const PageIntroContent = styled.div`
  background: #ffffff;
  top: -50px;
  padding: 2.5rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const PageTitle = styled.div`
  width: 100%;
  height: 100%;
  font-family: ${props => props.theme.headerFont};
  color: ${props => props.theme.blackColor};
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
`;
const PageSubTitle = styled.div`
  font-family: ${props => props.theme.bodyFont};
  font-weight: 400;
  text-align: center;
  width: 85%;
  color: ${props => props.theme.darkgreyColor};
  p{
    font-size: 1.1rem;
    font-weight: 400;
  }
  @media (max-width: 768px){
    width: 100%;
  }
`;
const Section = styled.section`
    width: 100%;
    padding: 2rem 0;
    background-color: #ffffff;
`;
const Section1 = styled(Section)`
  position: relative;
  margin-top: 2rem;

`
;
const Section2 = styled(Section)`
`;

const H3 = styled.h3`
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0;
`;
const H4 = styled.h4`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;


const SectionTitle = styled(H3)`
  color: ${props => props.theme.blackColor};
   font-size: 1.4rem;
  font-weight: 700;
  padding-bottom: 3rem;
  text-align: center;
`;
const SectionImg = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;
`;
const List = styled.div`
ul{
  list-style: circle outside none;
}
  li{
    width: fit-content;
    padding: 0.2rem 0;
    margin: 0.5rem 0;
  }

`;

const Loading = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HoWIpactTitle = styled(H4)`
  text-align: center;
  font-size: 1.2rem;
`;
const ProductContainer = styled(Col4)`
  background: rgba(255,255,255, 0.9);
  margin-left: 10rem;
  color: ${props => props.theme.blackColor};
  padding: 4rem 2rem;
  @media (max-width: 768px){
    margin-left: 0;
  }
`;

class WhatWeDoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {}
    };
  }
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
    let slug = this.props.match.params.slug;
    this.getContent(slug);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps){
    let slug = this.props.match.params.slug;
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.getContent(slug);
    }

  }
  getContent(slug){
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(slug), requestConfig)
      .then((resp)=>{
        let content = resp.data.data.content;
        this.setState({content: content});
      }).catch(error => {
        throw(error);
      });
  }

  render() {
    let page = {
      title: `${this.state.content.title}`,
      description: `${this.state.content.description}`,
      keywords: `${this.state.content.title}`
    };
    return (
      <Layout page={page} >
        {isEmpty(this.state.content) ?
          <Loading>
            <img src={`${config.PUBLIC_URL}/img/preview.gif`} alt="Loading"/>
          </Loading>
          :
          <Fragment>
            <PageBanner style={{
              backgroundImage: `url(${config.ROOT_URL}/media/${this.state.content.topImage})`,
              minHeight: '400px',
              backgroundAttachment: 'fixed',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}>
              <Container>
                <Row style={{ justifyContent: 'center'}}>
                  <BannerTitle>{this.state.content.title}</BannerTitle>
                </Row>
              </Container>
            </PageBanner>
            <PageIntro>
              <PageIntroContent>
                <PageTitle>
                  {this.state.content.description}
                </PageTitle>
                <PageSubTitle>
                  {parse(`${this.state.content.pageIntro}`)}
                </PageSubTitle>
              </PageIntroContent>
            </PageIntro>

            <Section1>
              <Container>
                <Row style={{justifyContent: 'space-around'}}>
                  <Col5>
                    <HoWIpactTitle>
                      How we do it
                    </HoWIpactTitle>
                    <List>
                      {parse(`${this.state.content.how}`)}
                    </List>
                  </Col5>

                  <Col5>
                    <HoWIpactTitle>
                      Impact
                    </HoWIpactTitle>
                    <List>
                      {parse(`${this.state.content.impact}`)}
                    </List>
                  </Col5>
                </Row>
              </Container>
            </Section1>
            <Section2 id="products">
              <Container>
                <SectionTitle>Service Products</SectionTitle>
              </Container>
              <FluidContainer style={{ background: `#e4e8ea url(${config.ROOT_URL}/media/${this.state.content.productImg}) no-repeat center center/cover fixed`}}>
                <ProductContainer>
                  <List>
                    {parse(`${this.state.content.products}`)}
                  </List>
                </ProductContainer>
              </FluidContainer>
            </Section2>
            {isEmpty(this.state.content.sections.edges) ?
              "":
              this.state.content.sections.edges.map( ({node}) =>{
                return <Section key={node.id}>
                  <SectionTitle>{node.title}</SectionTitle>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-5">
                      <SectionImg src={`${config.ROOT_URL}/media/${node.sectionImage}`} alt={node.title}/>
                      </div>
                      <div className="col-md-7">{parse(`${node.content}`)}</div>
                    </div>

                  </div>
                </Section>
                }
              )
              }
            <Section>
              <Container>
                <SectionTitle>Our Partners & Users</SectionTitle>
                <WhatWedoPartners partners={this.state.content.partners.edges}/>
              </Container>
            </Section>
          </Fragment>
        }
      </Layout>
    )
  }
}
function getQuery(slug) {
  return `
    query{
      content:workarea(slug:"${slug}"){
        title,
        description,
        topImage,
        productImg,
        pageIntro,
        how,
        impact,
        products,
      partners: workareapartnerSet{
        edges{
          node{
            id,
            partner{
              id,
              image,
              link
            }
          }
        }
      },
      sections: sectionSet{
        edges{
          node{
            id,
            title,
            sectionImage,
            content
          }
        }
      },
      eodatas:eodataSet{
          edges{
            node{
              id,
              name,
              eoImage
            }
          }
        }

      }
    }
    `
}
export default WhatWeDoPage;

