import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function areaReducer(state = initialState.areaType, action) {
  switch  (action.type) {

    case types.ON_AREA_TYPE_CHANGE:
      return action.areaType;


    default:
      return state;
  }
}
