import React, {Component} from 'react';
import Layout from "../../layout";
import styled from "styled-components";
import { Container } from "../../theme/grid";



const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 10vh;
`;

const H3 = styled.h3`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;
const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.4rem;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  text-align: center;
  p{
    font-size: 1.3rem;
    font-weight: 400;
  }
`;


class CropForagePage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }
  componentDidMount(){

  }


  render() {
    let page ={
      title: "Crop and Forage Conditions",
      description: "ICPAC - GMES & Africa Crop and Forage Conditions"
    };
    return (
      <Layout page={page}>
        <PageBanner>
          <Container style={{textAlign: "center"}}>
            <PageTitle>Crop and Forage Conditions</PageTitle>
          </Container>
        </PageBanner>
        <Container>
          <PageContent>
            <h2>Maps and graphs coming soon!</h2>
            <p>Check out our publications on &nbsp;&nbsp;
              <a href="https://www.icpac.net/publications/?project=263&service=7#results">Agriculture & Rangelands</a>
            </p>
          </PageContent>
        </Container>
      </Layout>
    )
  }
}


export default CropForagePage;

