import React, {Component} from 'react';
import Layout from "../../layout";
import styled from "styled-components";
import PublicationItem from "./PublicationItem";
import PublicationItem2 from "./PublicationItem2";
import {Container, Row, Col3, Col9, Col6} from "../../theme/grid";
import {requestConfig} from "../../utils/requestConfig";
import config from "../../config";
import axios from "axios";
import ServiceFilter from "./filters/ServiceFilter";
import TypeFilter from "./filters/TypeFilter";
import SearchInput from "../../components/input/SearchInput";
import {isEmpty} from "../../utils/isEmpty";
import DisplayChanger from "../../utils/DisplayChanger";
import FeaturedPublications from "./FeaturedPublication";



const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 10vh;
`;

const H3 = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin: 2rem 0;
`;

const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.4rem;
`;
const PublicationsItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

class PublicationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      filter: {title: "", service: "", type: ""},
      services: [],
      types: [],
      publications: [],
      featured: [],
      search:"",
      display: 'list'
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);

  }

  onChange=(event)=>{
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let filter = this.state.filter;
    this.setState({ filter: { ...filter, [name]: value } });
    // this.getContent();
  };


  componentDidMount(){
    this.getContent();
    this.getFilterContent();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.filter !== this.state.filter){
      this.getContent();
    }
  }

  onSearchChange(event){
    this.setState({search: event.target.value});
    let filter = this.state.filter;
    this.setState({ filter: { ...filter, title: event.target.value } });
  }

  searchFormError() {
    let formIsValid = true;
    let errors = {};

    if(isEmpty(this.state.filter.title)) {
      errors.search = 'Search item is required';
      formIsValid = false;
    }

    this.setState({errors: errors});
    return formIsValid;
  }
  onDisplayChange=(value)=>{this.setState({display: value});};

  onSearchClick(){
    // if(!this.searchFormError()) {return;}
    // this.getContent(this.state.filter.title);
    // console.log(this.state.filter.title);

  }
  onSubmit=()=>{
    // if(!this.searchFormError()) {return;}
    this.getContent(this.state.filter.title);
    console.log(this.state.filter.title);
  };
  handleKeyPress=(e)=> {
    // if(!this.searchFormError()) {return;}
    if (e.key === 'Enter') {
      let filter = this.state.filter;
      this.setState({ filter: { ...filter, title: e.target.value } });
    }
  };

  getContent(){
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(this.state.filter), requestConfig)
      .then((resp)=>{
        let publications = resp.data.data.publications.edges;
        this.setState({publications : publications });
        let featured = resp.data.data.featured.edges;
        this.setState({featured : featured });
      }).catch(error => {
        throw(error);
      });
  }
  getFilterContent(){
    return axios.post(`${config.ROOT_URL}/q/` , getFilterQuery(), requestConfig)
      .then((resp)=>{
        let services = resp.data.data.services.edges;
        this.setState({services : services });
        let types = resp.data.data.types.edges;
        this.setState({types : types });
      }).catch(error => {
        throw(error);
      });
  }


  render() {
    let page ={
      title: "Publications",
      description: "Latest Publications"
    };
    console.log(this.props.location.type)
    return (
      <Layout page={page}>

        <PageBanner>
          <Container style={{textAlign: "center"}}>
            <PageTitle>Latest Publications</PageTitle>
          </Container>
        </PageBanner>
        <FeaturedPublications publications={this.state.featured} />



        <Container style={{padding: "2rem"}}>
          <H3>All Publications</H3>
          <Row style={{justifyContent: "center"}}>
            <Col6>
              <SearchInput
                onClick={this.onSearchClick}
                onSubmit={this.onSubmit}
                iconSize='30' value={this.state.search}
                onChange={this.onSearchChange }
                onKeyPress={this.handleKeyPress}
                placeholder="Publications Search"
              />
            </Col6>
            <Col3>
              <Row style={{justifyContent: "center"}}>
                <DisplayChanger onDisplayChange={this.onDisplayChange} display={this.state.display} />
              </Row>
            </Col3>
          </Row>
        </Container>

        <Container style={{ marginBottom: '5rem'}}>
          <Row>
            <Col3>
              <ServiceFilter onChange={this.onChange} filter={this.state.filter} services={this.state.services}/>
              <TypeFilter onChange={this.onChange} filter={this.state.filter} types={this.state.types}/>
            </Col3>
            <Col9>
              <PublicationsItems>
                {this.state.publications.map(publication =>{
                  return this.state.display === 'list' ?
                    <PublicationItem key={publication.node.id} publication={publication} />
                    :
                    <PublicationItem2 key={publication.node.id} publication={publication} />
                })}
              </PublicationsItems>
            </Col9>
          </Row>
        </Container>
      </Layout>
    )
  }
}
function getQuery(filter) {
  return `
  query{
    publications:allPublications(title:"${filter.title}", service_Slug:"${filter.service}", type_Slug:"${filter.type}"){
      edges{
        node{
          id,
          title,
          date,
          coverImage,
          slug,
          description,
          publication,
          service{
            title
          },
          type{
            title
          }
        }
      }
    },
   featured:allPublications(first: 4, isFeatured:true){
      edges{
        node{
          id,
          title,
          date,
          coverImage,
          slug,
          description,
          publication,
          service{
            title
          },
          type{
            title
          }
        }
      }
    }
  }
`
}
function getFilterQuery() {
  return `
  query{
    services:allPublicationService{
      edges{
        node{
          id,
          title,
          slug
        }
      }
    },
    types:allPublicationType{
      edges{
        node{
          id,
          title,
          slug
        }
      }
    }
  }
`
}
export default PublicationsPage;

