import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function accessTokenReducer(state = initialState.accessToken, action) {
  switch  (action.type) {
    case types.STORE_ACCESS_TOKEN:
      return action.accessToken;

    case types.TOKEN_REFRESH_SUCCESS:
      return[
        ...state,
        Object.assign({}, action.accessToken)
      ];

    default:
      return state;
  }
}
