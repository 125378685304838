import React, {Component} from 'react';
import Layout from "../layout";
import styled from "styled-components";
// import TendersList from "../components/tenders/TendersList";
import axios from "axios";
import { Container } from "../theme/grid";
import {requestConfig} from "../utils/requestConfig";
import config from "../config";



const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 10vh;
`;

const H3 = styled.h3`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;

const H5 = styled.h5`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;


const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.4rem;
`;
const PageSubTitle = styled(H5)`
  color: #ffffff;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  text-align: center;
  p{
    font-size: 1.3rem;
    font-weight: 400;
  }
`;


class TendersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenders:[]
    };

  }
  componentDidMount(){
    this.getContent();
  }

  getContent(){
    return axios.post(`${config.ROOT_URL}/q/` , getQuery(), requestConfig)
      .then((resp)=>{
        let tenders = resp.data.data.tenders.edges;
        this.setState({tenders: tenders});
      }).catch(error => {
        throw(error);
      });
  }

  render() {
    let page ={
      title: "Tenders",
      description: "ICPAC - GMES & Africa Tenders"
    };
    return (
      <Layout page={page}>
        <PageBanner>
          <Container style={{textAlign: "center"}}>
            <PageTitle>Tenders</PageTitle>
            <PageSubTitle>Work with us</PageSubTitle>
          </Container>
        </PageBanner>
        <Container>
          {/*<TendersList tenders={this.state.tenders}/>*/}
          <PageContent>
            <p>We do not have any opportunities within the GMES project at the moment, however, visit the ICPAC website to view other opportunities in the organization&nbsp;&nbsp;
              <a href="https://icpac.net/tenders/">icpac.net/tenders/</a>
            </p>
          </PageContent>
        </Container>
      </Layout>
    )
  }
}

function getQuery() {
  return `
  query{
    tenders:allTenders{
      edges{
        node{
          id,
          name,
          deadline,
          document
        }
      }
    }
  }
`
}
export default TendersPage;

