import isServer from "../utils/isServer";


export function setViewPort(){
  return function () {
    if(!isServer){
      if (window.matchMedia("(max-width: 575.98px)").matches) {
        return 'xSmall'
      } else if (window.matchMedia("(min-width: 576px)").matches && (window.matchMedia("(max-width: 767.98px)").matches)){
        return 'small'
      } else if (window.matchMedia("(min-width: 768px)").matches && (window.matchMedia("(max-width: 991.98px)").matches)){
        return 'medium'
      } else if (window.matchMedia("(min-width: 992px)").matches && (window.matchMedia("(max-width: 1199.98px)").matches)){
        return 'large'
      } else {
        return 'xLarge'
      }
    } else {
      return null;
    }
  };
}
