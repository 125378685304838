import config from "../config";
import axios from "axios";
import {requestConfig} from "../utils/requestConfig";


export function regionFAPAR(Q){
  return axios.post(`${config.ROOT_URL}/q/` , regionFAPARQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.eco.edges[0].node;
      return{
        name: "Eastern Africa Region",
        fapars: data.fapar.edges ? data.fapar.edges:[],
        fapars_info: data.fapar.edges ? data.fapar.pageInfo:{},
        fapar: data.fapar_mon.edges[0] ? data.fapar_mon.edges[0].node: {},
        fapar_anom: data.fapar_mon_anom.edges[0] ? data.fapar_mon_anom.edges[0].node: {},
        fapar_mon: data.fapar_mon.edges ? data.fapar_mon.edges: [],
        fapar_mon_info: data.fapar_mon.edges ? data.fapar_mon.pageInfo: {},
        fapar_anoms: data.fapar_mon_anom.edges ? data.fapar_mon_anom.edges: [],
        fapar_anoms_info: data.fapar_mon_anom.edges ? data.fapar_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function regionFAPARQuery(Q){
  return`
  query{
     eco:allRegions{
      edges{
        node{
        id,
        name,
        fapar:ecoclimaticSet(isRegion: true, isCountry: false, isDekad: true, isAdmin: false, isPa: false, isAnom: false, fapar: true, first: 36, before: "${Q.faparBefore}", after:"${Q.faparAfter}"){
          edges{
            node{
              id,
              dekad,
              fapar,
              rfe,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      fapar_mon:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, fapar: true, first: 12, before: "${Q.faparMonBefore}", after:"${Q.faparMonAfter}"){
        edges{
          node{
            id,
            dekad,
            fapar,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon_anom:ecoclimaticSet(isRegion: true, isCountry: false, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, fapar: true, first: 12, before: "${Q.faparAnomBefore}", after:"${Q.faparAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            fapar,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
    }
    }
  `
}

export function countryFAPAR(Q){
  return axios.post(`${config.ROOT_URL}/q/` , countryFAPARQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.country;
      return{
        fapars: data.fapar.edges ? data.fapar.edges:[],
        fapars_info: data.fapar.edges ? data.fapar.pageInfo:{},
        fapar: data.fapar_mon.edges[0] ? data.fapar_mon.edges[0].node: {},
        fapar_anom: data.fapar_mon_anom.edges[0] ? data.fapar_mon_anom.edges[0].node: {},
        fapar_mon: data.fapar_mon.edges ? data.fapar_mon.edges: [],
        fapar_mon_info: data.fapar_mon.edges ? data.fapar_mon.pageInfo: {},
        fapar_anoms: data.fapar_mon_anom.edges ? data.fapar_mon_anom.edges: [],
        fapar_anoms_info: data.fapar_mon_anom.edges ? data.fapar_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function countryFAPARQuery(Q){
  return`
  query{
    country(id:"${Q.country}"){
      id,
      name,
        fapar:ecoclimaticSet(isRegion: false, isCountry: true, isDekad: true, isAdmin: false, isPa: false, isAnom: false, fapar: true, first: 36, before: "${Q.faparBefore}", after:"${Q.faparAfter}"){
          edges{
            node{
              id,
              dekad,
              fapar,
              rfe,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      fapar_mon:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: false, fapar: true, first: 12, before: "${Q.faparMonBefore}", after:"${Q.faparMonAfter}"){
        edges{
          node{
            id,
            dekad,
            fapar,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon_anom:ecoclimaticSet(isRegion: false, isCountry: true, isAdmin: false, isPa: false, isMon: true, isDekad: false, isAnom: true, fapar: true, first: 12, before: "${Q.faparAnomBefore}", after:"${Q.faparAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            fapar,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}

export function paAreaFAPAR(Q){
  return axios.post(`${config.ROOT_URL}/q/` , paAreaFAPARQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.area;
      return{
        fapars: data.fapar.edges ? data.fapar.edges:[],
        fapars_info: data.fapar.edges ? data.fapar.pageInfo:{},
        fapar: data.fapar_mon.edges[0] ? data.fapar_mon.edges[0].node: {},
        fapar_anom: data.fapar_mon_anom.edges[0] ? data.fapar_mon_anom.edges[0].node: {},
        fapar_mon: data.fapar_mon.edges ? data.fapar_mon.edges: [],
        fapar_mon_info: data.fapar_mon.edges ? data.fapar_mon.pageInfo: {},
        fapar_anoms: data.fapar_mon_anom.edges ? data.fapar_mon_anom.edges: [],
        fapar_anoms_info: data.fapar_mon_anom.edges ? data.fapar_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function paAreaFAPARQuery(Q){
  return`
  query{
    area:protectedArea(id:"${Q.pa}"){
      id,
      name,
      country{
        id,
        name
      }
        fapar:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: false, isPa: true, isAnom: false, fapar: true, first: 36, before: "${Q.faparBefore}", after:"${Q.faparAfter}"){
          edges{
            node{
              id,
              dekad,
              fapar,
              rfe,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      fapar_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: false, fapar: true, first: 12, before: "${Q.faparMonBefore}", after:"${Q.faparMonAfter}"){
        edges{
          node{
            id,
            dekad,
            fapar,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: false, isPa: true, isMon: true, isDekad: false, isAnom: true, fapar: true, first: 12, before: "${Q.faparAnomBefore}", after:"${Q.faparAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            fapar,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}

export function adminAreaFAPAR(Q){
  return axios.post(`${config.ROOT_URL}/q/` , adminAreaFAPARQuery(Q), requestConfig)
    .then((resp)=>{
      let data = resp.data.data.area;
      return{
        fapars: data.fapar.edges ? data.fapar.edges:[],
        fapars_info: data.fapar.edges ? data.fapar.pageInfo:{},
        fapar: data.fapar_mon.edges[0] ? data.fapar_mon.edges[0].node: {},
        fapar_anom: data.fapar_mon_anom.edges[0] ? data.fapar_mon_anom.edges[0].node: {},
        fapar_mon: data.fapar_mon.edges ? data.fapar_mon.edges: [],
        fapar_mon_info: data.fapar_mon.edges ? data.fapar_mon.pageInfo: {},
        fapar_anoms: data.fapar_mon_anom.edges ? data.fapar_mon_anom.edges: [],
        fapar_anoms_info: data.fapar_mon_anom.edges ? data.fapar_mon_anom.pageInfo: {}
      };
    }).catch(error => {
      throw(error);
    });
}

export function adminAreaFAPARQuery(Q){
  return`
  query{
    area:adminArea(id:"${Q.admin}"){
      id,
      name,
      country{
        id,
        name
      }
        fapar:ecoclimaticSet(isRegion: false, isCountry: false, isDekad: true, isAdmin: true, isPa: false, isAnom: false, fapar: true, first: 36, before: "${Q.faparBefore}", after:"${Q.faparAfter}"){
          edges{
            node{
              id,
              dekad,
              fapar,
              rfe,
              dmp,
              fapar,
              isDekad,
              isMon,
              isAnom,
              date,
              file
            }
          },
          pageInfo{
            endCursor,
            startCursor,
            hasNextPage,
            hasPreviousPage
          }
        },
      fapar_mon:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: false, fapar: true, first: 12, before: "${Q.faparMonBefore}", after:"${Q.faparMonAfter}"){
        edges{
          node{
            id,
            dekad,
            fapar,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      },
      fapar_mon_anom:ecoclimaticSet(isRegion: false, isCountry: false, isAdmin: true, isPa: false, isMon: true, isDekad: false, isAnom: true, fapar: true, first: 12, before: "${Q.faparAnomBefore}", after:"${Q.faparAnomAfter}"){
        edges{
          node{
            id,
            dekad,
            fapar,
            rfe,
            dmp,
            fapar,
            isMon,
            isAnom,
            date,
            file
          }
        },
        pageInfo{
          endCursor,
          startCursor,
          hasNextPage,
          hasPreviousPage
        }
      }

    }
    }
  `
}
