import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';



const FeedContainer = styled.div`
  max-height: 280px;
  overflow-y: auto;
  margin-left: 30px;
  padding-left: 20px;
  background: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  margin-top:10px;
`;

const VideoContainer = styled.div`
  margin: 0;
  background: #fff;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const VideoTitle = styled.h5`
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0.5rem 0;
`;

const VideoIframe = styled.iframe`
  width: 100%;
  height: 200px; /* Adjust height as needed */
  border-radius: 10px;
`;
 
const Header = styled.div`
  position: sticky;
  background:#f9f9f9;
  top: 0;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid #cccccc; /* Grey border-bottom */
  font-size: 1.4rem;
  text-align: left;
  font-weight: bold;
  
`;
const VideoDescription = styled.p`
  font-size: 0.85rem;
  color: #333;
`;

const fetchPlaylistItems = async (setVideos) => {
  const API_KEY = 'AIzaSyAgZ2cTG6NgHOYtqrrumMeRIy6YVZUKBiQ'; // Replace with your YouTube API Key
  const playlistId = 'PLIspb6d-RoVr1kBf0CzW1IKiU4K4G67Nw'; // Replace with the desired YouTube channel ID
  const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&key=${API_KEY}&maxResults=5`;

  try {
    const response = await axios.get(url);
    setVideos(response.data.items);
  } catch (error) {
    console.error('Error fetching YouTube videos:', error);
  }
};

const YouTubeFeed = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetchPlaylistItems(setVideos);
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text
    return text.slice(0, maxLength) +'...'
  }
  return (
    <FeedContainer>
    <Header>Posts from ICPAC GMES&Africa</Header>
    {videos.map(video => (
      <VideoContainer key={video.snippet.resourceId.videoId}>
        <VideoIframe
          src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
          title={video.snippet.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></VideoIframe>
        <VideoTitle>{video.snippet.title}</VideoTitle>
        <VideoDescription>{truncateText(video.snippet.description, 100)}</VideoDescription>
      </VideoContainer>
    ))}
  </FeedContainer>
  );
};

export default YouTubeFeed;

