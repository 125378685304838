import React, {Component} from 'react';
import Layout from "../layout";
import styled from 'styled-components';
import {Container} from "../theme/grid";

const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 10vh;
`;

const H3 = styled.h3`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;

const H5 = styled.h5`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;


const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
`;
const PageSubTitle = styled(H5)`
  color: #ffffff;
`;


class PolicyPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }


  render() {
    let page ={
      title: "Privacy Policy",
      description: "The Global Monitoring for Environment and Security and Africa (GMES & Africa) Support Programme is " +
        "the result of the longstanding cooperation between Africa and Europe in the area of space science & technology, " +
        "which is one of the key priorities of the long-term EU-Africa Joint Strategy",
      keywords:"GMES and AFRICA policy page"
    };
    return (
      <Layout page={page}>
        <PageBanner>
          <Container style={{textAlign: "center"}}>
            <PageTitle>Privacy Policy</PageTitle>
            <PageSubTitle>This page explains our approach to privacy on our website and how it affects you.</PageSubTitle>
          </Container>
        </PageBanner>
        <Container>
          <p>This Privacy policy only concerns ICPAC - GMES & Africa customers and users of our online services.
            ICPAC - GMES & Africa works to ensure that your privacy is protected when using our services. We therefore have a
            policy setting out how your personal data will be processed and protected. Any changes we have made to
            this privacy policy can be found by visiting us online.</p>

          <h4>In Short</h4>
          <p>We collect anonymous statistics about your visit to our website, like which of our pages you viewed.
            If you sign up with us we take great care to keep your information safe and we’ll never share it with others
            without your express permission. We never share your data with 3rd parties except to help us deliver
            our own services.</p>

          <h4>Third-party cookies</h4>
          <p>We use third-party cookies to collect statistics in aggregate form in analysis tools such as <a href="https://www.facebook.com/policies/cookies/">Facebook</a>, <a href="https://www.google.com/policies/technologies/ads/">Google AdSense</a>, <a href="https://www.google.com/policies/privacy/partners/">Google Analytics</a> and <a href="https://help.twitter.com/rules-and-policies/twitter-cookies">Twitter</a>.
            The cookies used are both permanent and temporary cookies (session cookies). The permanent cookies are
            stored on your computer or mobile device for no longer than 24 months.</p>

          <h4>Measuring our visitors</h4>
          <p>We measure visitors to our websites and products using Google Analytics. This service records what pages you
            view within our site, how you arrived at our site and some basic information about your computer.</p>
          <p>We anonymise the data in Google Analytics – so we don’t know who you are; just that somebody visited our site.</p>
          <p>The information we collect helps us understand what parts of our sites are doing well, how people
            arrive at our site and so on. Like most websites, we use this information to make our website better.</p>
          <p>You can learn more about Google Analytics or opt out if you wish. Google Analytics data is
            automatically deleted 26 months after your last visit.</p>
        </Container>
      </Layout>
    )
  }
}


export default  PolicyPage;
