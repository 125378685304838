import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function accessTokenReducer(state = initialState.refreshToken, action) {
  switch  (action.type) {
    case types.STORE_REFRESH_TOKEN:
      return action.refreshToken;

    // case types.TOKEN_REFRESH_SUCCESS:
    //   return[
    //     ...state,
    //     Object.assign({}, action.refreshToken)
    //   ];

    default:
      return state;
  }
}
