import React from 'react';
import Modal from "react-modal";
import {IoIosCloseCircle, IoMdDownload} from 'react-icons/io';
import {Col12, Row} from "../../../../theme/grid";
import styled from "styled-components";
import PropTypes from "prop-types";
import htmlToImage from 'html-to-image';
import download from 'downloadjs';
import {slugify} from "../../../../utils/slugify";
import {LazyImage} from "../../../../utils/Image";
import config from "../../../../config";

const Title = styled.div`
  font-family: ${props => (props.theme.headerFont)};
  font-size: 0.85rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  text-align: center;
  span{
    font-weight: 400;
    font-size: 0.9rem;
  }
`;
const ModalImage = styled(LazyImage)`
  border: 1px solid #eeeeee;
`;

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : '1000'
  }
};
Modal.setAppElement('#root');

const makePNG = (modalId, name)=>{
  return htmlToImage.toPng(document.getElementById(modalId))
    .then(function (dataUrl) {
      download(dataUrl, `${name}.png`);
    });
};
const renderVariable = (ndvi, rfe, dmp, fapar) =>{
  if(ndvi){
    return 'Normalized Difference Vegetation Index (NDVI)'
  }else if(rfe){
    return 'Rainfall Estimate Data'
  } else if(dmp){
    return 'Dry Matter Productivity (DMP)'
  }else if(fapar){
    return 'Fraction of Absorbed Photosynthetically Active Radiation(FAPAR)'
  }
};
const nameVariable = (ndvi, rfe, dmp, fapar) =>{
  if(ndvi){return 'ndvi'}else if(rfe){return 'chirps'} else if(dmp){return 'dmp'}else if(fapar){return 'fapar'}
};

const imageName = (location, nameVar, isAnom, date)=>{
  return isAnom ? slugify(location+'-'+nameVar+'-anomaly-'+date): slugify(location+'-'+nameVar+'-'+date);
};
const EcoModal=({ modal, modalIsOpen, afterOpenModal, closeModal, location })=>{
  let modalId = modal.id;
  let nameVar = nameVariable(modal.ndvi, modal.rfe, modal.dmp, modal.fapar);
  let name = imageName(location, nameVar, modal.isAnom, modal.date);
  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={modal.title}
    >
      <IoIosCloseCircle onClick={closeModal} size={25} color='#A52A2A' style={{position:"absolute", margin: '0.2rem', right: 0, top: 0, cursor: 'pointer' }}/>
      <Row id={modalId} style={{backgroundColor: "#ffffff"}}>

        <Col12>
          <div style={{paddingLeft: "1rem"}}>
            <Title style={{fontWeight: '500', fontSize: '1.1rem'}}>{renderVariable(modal.ndvi, modal.rfe, modal.dmp, modal.fapar)}</Title>
            {modal.isAnom ? <Title style={{fontWeight: '500'}}>Anomaly</Title>: ""}
            <Title style={{fontWeight: '500'}}>{location}</Title>
          </div>
          <ModalImage
            src={`${config.ROOT_URL}/media/${modal.file}`}
            alt={modal.title}
            style={{objectFit: 'contain'}}
            width="500"
            height="500"
          />

        </Col12>
      </Row>
      <IoMdDownload onClick={()=> makePNG(modalId, name)} size={25} color='#000000' style={{position:"absolute",  margin: '0.2rem', right: '2rem', top: 0, cursor: 'pointer' }}/>
    </Modal>
  )
};

EcoModal.propTypes = {
  modal: PropTypes.object.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  afterOpenModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};
export default EcoModal;


