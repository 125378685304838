import React, {Component} from 'react';
import styled from "styled-components";
import PublicationItem2 from "./PublicationItem2";
import {Container, Row, Col12} from "../../theme/grid";


const H3 = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin: 2rem 0;
`;

const PublicationsItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

class FeaturedPublications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }


  render() {
    return this.props.publications ?
          <Container style={{marginBottom: '1rem'}}>
            <H3>Featured Publications</H3>
            <Row>
              <Col12>
                <PublicationsItems>
                  {this.props.publications.map(publication => {
                      return <PublicationItem2 key={publication.node.id} publication={publication}/>
                    }
                  )}
                </PublicationsItems>
              </Col12>
            </Row>
          </Container>: "";


  }
}
export default FeaturedPublications;

