import React from 'react';
import {Row, Col12} from "../../../theme/grid";
import {RadioInput} from "../../../components/input";
import styled from "styled-components";


const Section = styled.div`
  border: 1px solid #eeeeee;
  padding: 1rem;
  margin: 1rem 0;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;

`;
const Title = styled.h5`
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin: 0;
`;
const TypeFilter = ({onChange, filter, types}) =>{
  return(
    <Section>
      { types ?
        <Row>
          <Title>News Type</Title>
          {types.map(type =>{
            return(
              <Col12 key={type.node.id} style={{ display: "flex", flexDirection: "column"}}>
                <RadioInput
                  onChange={onChange}
                  name="type"
                  defaultChecked={filter.type === type.node.slug}
                  label={type.node.title}
                  style={{borderRadius: '3px'}}
                  value={type.node.slug}/>
              </Col12>
            )
          })}
        </Row>: ""}
    </Section>
  )

};

export default TypeFilter;
