import React, { Fragment } from 'react'
import Select from 'react-select'

const ReactSelect = ({name, label, onChange, isClearable, placeholder, value, options}) => (
  <Fragment>
    {label ? <label>{label}</label>: ""}
    <Select
      isClearable={isClearable}
      value={value}
      name={name}
      type="select"
      placeholder={placeholder}
      onChange={onChange}
      options={options} />
  </Fragment>
);
export default ReactSelect;
