import React, { Component } from "react";
import Chart from 'react-apexcharts';
import {IoMdDownload} from "react-icons/io";
import {loadCSV, loadLegend, parseCoverChart} from "../../../../actions/loadCSV";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import {slugify} from "../../../../utils/slugify";


const DownloadIcon =styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  height: 3rem;
  width: 3rem;
  display: none;
  justify-content: center;
  align-items: center;
`;
class CoverChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location,
      chart: {},
      chart_error: []
    }
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState){
    if (prevProps.coverTypeChart !== this.props.coverTypeChart) {
      this.getData();
    }
  }
  getData=()=>{
    loadCSV(this.props.coverChart).then((results)=>{
      let data = parseCoverChart(results.data);
      if (this._isMounted) {
        this.setState({chart: data});
        this.setState({chart_error: results.errors});
      }
    });
    loadLegend(this.props.coverLegend).then((results)=>{
      let data = this.getLegendColors(results.data);
      if (this._isMounted) {
        this.setState({legends: data});
      }
    })
  };
  getLegendColors=(options) =>{
    return options.map((option)=>{
      return option.color;
    })
  };

  loadOptions(categories, legends, location){
    return {
      colors: legends,
      title: {
        text: 'Cover Type Coverage',
        align: 'center',
        style: {
          fontSize:  '14px',
          fontWeight:  'normal',
          color:  '#263238'
        },
      },
      chart: {
        id: `${location+"-cover-chart"}`,
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '100%'
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          fontWeight: 'normal',
          colors: ['#fff']
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      grid: {
        padding: {
          left: 0,
          right: 0
        }
      },
      xaxis: {
        categories: categories,

      },
      yaxis: {
        title: {
          text: "Percentage Area",
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 400,
          },
        },
        tickAmount: 5,
        min: 0,
        max: 100,
        labels: {
          formatter: function (val) {
            return val.toFixed(0)
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'right',
        offsetY: 50
      },
    }
  }

  render() {
    let location = slugify(this.props.landcover.name+"_"+this.props.landcover.country.name);
    return (
      <div id="cover-type-chart"  style={{paddingTop: '1rem', position: 'relative'}}>
        <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={30}/></p><ReactTooltip /></DownloadIcon>
        {this.state.chart.series && this.state.legends !== undefined ?
          <Chart
            options={this.loadOptions(this.state.chart.categories, this.state.legends, location)}
            series={this.state.chart.series}
            type="bar" height="300"
          />
          :""}
      </div>
    )
  }
}
export default CoverChart;
