import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import config from "../../config";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: transparent;
  padding: 0.5rem;
`;

const Partners = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  min-height: 5rem;
`;
const PartnerLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
`;
const PartnerImg = styled.img`
  filter: grayscale(100%);
  height: 6.5rem;
  width: auto;
  max-width: 235px;
  object-fit: contain;
  :hover{
  filter: none;
  }
`;
const WhatWedoPartners = ({partners}) => {
  return (
    <Container>
      <Partners>
        {partners.map(partner =>
          <PartnerLink key={partner.node.id} href={partner.node.partner.link} target="_blank">
            <PartnerImg src={`${config.ROOT_URL}/media/${partner.node.partner.image}`} alt={partner.node.partner.title} />
          </PartnerLink>
        )}
      </Partners>
    </Container>
  );
};
WhatWedoPartners.propTypes = {
  partners: PropTypes.array.isRequired
};
export default WhatWedoPartners;
