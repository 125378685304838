import React, {Component, Fragment} from "react";
import styled from "styled-components";
import {loadCSV} from "../../../../actions/loadCSV";


const Title = styled.div`
  font-family: ${props => (props.theme.headerFont)};
  font-size: 0.85rem;
  padding: 0.5rem;
  font-weight: 500;
  text-align: center;
  margin-top: 0.5rem;
  span{
    font-weight: 400;
    font-size: 0.9rem;
  }
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
// const THead = styled.thead``;
const TBody = styled.tbody``;
const TH = styled.th`
  text-transform: capitalize;
  text-align: left;
  height: 30px;
  background: #ffffff;
`;
const TR = styled.tr`
  height: 30px;
  color: ${props => (props.theme.blackColor)};
  :first-child {
    text-transform: capitalize;
    text-align: left;
    height: 30px;
    color: #f1f1f1;
    font-weight: 400;
    background-color: ${props => (props.theme.mainColor)};
    border: 1px solid #888888;
    td{
      border: 1px solid #f1f1f1;
    }
  }
`;
const TD = styled.td`
  border: 1px solid #888888;
  height: 20px;
  text-align: right;
  padding-right: 0.3rem;
  :first-child {
    text-transform: capitalize;
    text-align: left;
    height: 30px;
  }
`;

const RenderRow = (props) =>{
  return props.keys.map((key, index)=>{
    return <TD key={index}>{props.data[key]}</TD>
  })
};

class CoverTypeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      chart: {},
      errors: []
    }
  }
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.coverTypeChart !== this.props.coverTypeChart) {
      this.getData();
    }
  }

  getData=()=>{
    loadCSV(this.props.coverTypeChart).then((results)=>{
      this.setState({results: results.data});
      this.setState({errors: results.data.errors});
    })
  };
  getKeys =()=>{
    return Object.keys(this.state.results[0]);
  };

  getHeader =()=>{
    let keys = this.getKeys();
    return keys.map((key, index)=>{
      return <TH key={key}>{key.toUpperCase()}</TH>
    })
  };

  getRowsData =()=>{
    let items = this.state.results;
    let keys = this.getKeys();
    return items.map((row, index)=>{
      return <TR key={index}><RenderRow key={index} data={row} keys={keys}/></TR>
    })
  };

  render() {
    return (
      <Fragment>
        {this.state.results.length !== 0 ?
          <Fragment>
            <Title>Area ( Km&sup2; ) Per Land Cover</Title>
            <div style={{marginTop: '1rem' }}>
              <Table>
                <TBody>
                  {this.getRowsData(this.state.results)}
                </TBody>
              </Table>
            </div>
          </Fragment>
          : ""}
      </Fragment>
    )
  }
}
export default CoverTypeTable;
