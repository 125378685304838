import React, {Component, Fragment} from 'react';
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import {IoMdDownload, IoIosArrowRoundBack, IoIosArrowRoundForward} from "react-icons/io";
import ReactTooltip from 'react-tooltip';
import {Container, Col4, Col8} from "../../../../theme/grid";
import config from "../../../../config";
import {grid} from "styled-components-grid";
import NotAvailable from "../../../../utils/NotAvailable";
import {isEmpty} from "../../../../utils/isEmpty";
import {LazyImage} from "../../../../utils/Image";
import EcoModal from "../modals/EcoModal";
import zipDownload from "../../../../utils/zipDownload";
import {regionRFE, countryRFE,paAreaRFE, adminAreaRFE} from "../../../../actions/rfeActions";


export const Col1 = styled.div`
  ${grid.unit({ size: { lg: 1 / 12 } })}
`;
export const Col2 = styled.div`
  ${grid.unit({ size: { lg: 2 / 12} })}
`;
export const ColSpace = styled.div`
  flex-basis: 4%;
  max-width: 4%;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;


const Wrapper = styled(Container)`
    box-shadow: 0 5px 30px 0 rgba(39,39,39,0.15);
    border-radius: 0.3rem;
    margin-bottom: 2rem;
    position: relative;

`;
const HeaderBar = styled(Row)`
   display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  line-height: 1.3;
  font-weight: 400;
  color: #ffffff;
  padding-left: 5%;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background: ${props => (props.theme.mainColor)};
`;
const MonthTitle = styled.div`
  position: absolute;
  transform: rotate(-90deg) scaleX(-1);
  transform-origin: bottom;
  width: 200px;
  height: 50px;
`;
const Map = styled(Col2)`
  width: 100%;
  height: auto;
  margin: 0.5rem 0;
`;
const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
    &:hover{
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  }
`;
const MapImage = styled(LazyImage)`
  border: 1px solid #eeeeee;
  //padding: 0.5rem;
`;
const DownloadIcon =styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  height: 2rem;
  width: 2rem;
  display: none;
  justify-content: center;
  align-items: center;
  ${ImageContainer}:hover & {
    display: flex;
  }
`;
const ZipnDownload =styled.div`
  cursor: pointer;
  width: fit-content;
  padding: 0 0.5rem;
  p{
    padding: 0;
    margin: 0;
  }
`;
const Pagination =styled.div`
display: flex;
flex-direction: row;
margin: 0 1rem;
`;
const NextBackButton =styled.div`
  margin: 0 0.5rem;
  cursor: pointer;
  p{
    padding: 0;
    margin: 0;
  }
`;
const TitleText = styled.div`
transform: scaleX(-1);
`;

const Period = styled(Col2)`
  text-align: center;
  margin: 0.3rem 0;
  font-size: 0.9rem;
`;

const VariableHead = styled(Row)`
  justify-content: center;
  width: 100%;
  position: sticky;
  top: 8.75rem;
  background: #ffffff;
  z-index: 1;
`;

class RFE extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: this.props.data,
      rfes: [],
      rfe_mon: [],
      rfe_mon_anom: [],
      modalIsOpen: false,
      modal: {},
      location:""
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.loadDekads();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.loadDekads();
    }
    if(prevProps.data !== this.props.data){
      this.setState({data: this.props.data});
    }
  }

  loadDekads=()=>{
    let data = [];
    for(let i = 0; i < this.state.data.rfes.length; i++){
      data.push(this.state.data.rfes[i].node);
    }
    let obj = _.groupBy(data, function(element) {
      return element.date.substring(0,7);
    });
    let rfes = [];
    for (let [key, value] of Object.entries(obj)) {
      let maps = _.sortBy(value, ['date']);
      rfes.push({
        date: key,
        maps: maps
      });
    }
    this.setState({rfes: rfes});
  };
  dataOptions =(options) =>{
    return options.map(option =>{
      return{
        id: option.node.id,
        ndvi: option.node.ndvi,
        rfe: option.node.rfe,
        dmp: option.node.dmp,
        fapar: option.node.fapar,
        isMon: option.node.isMon,
        isAnom: option.node.isAnom,
        date: option.node.date,
        file: option.node.file

      }
    })
  };
  getDekad=(maps, dekad)=>{
    const map = maps.filter(map => (map.dekad === dekad));
    if (!isEmpty(map[0])) return <Map onClick={()=>this.onOpenModal(map[0])}>
      <ImageContainer><MapImage src={`${config.ROOT_URL}/media/${map[0].file}`} alt="" height="200" width="165" style={{objectFit: 'contain'}}/>
        <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
      </ImageContainer>

    </Map>;
    return <Map><MapImage src={`${config.PUBLIC_URL}/img/n_a.png`} alt=""  height="200" width="165" style={{objectFit: 'contain'}}/></Map>;
  };
  getMonthly(d){
    let date = moment(d).format('YYYY-MM-DD');
    let data = this.state.data.rfe_mon || [];
    let maps = this.dataOptions(data);
    const map = maps.filter(map => map.date === date);
    if (!isEmpty(map[0])) return <Map onClick={()=>this.onOpenModal(map[0])}>
      <ImageContainer><MapImage src={`${config.ROOT_URL}/media/${map[0].file}`} alt=""  height="200" width="165" style={{objectFit: 'contain'}}/>
        <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
      </ImageContainer>

    </Map>;
    return <Map><MapImage src={`${config.PUBLIC_URL}/img/n_a.png`} alt=""  height="200" width="165" style={{objectFit: 'contain'}}/></Map>;
  }
  getAnomaly(d){
    let date = moment(d).format('YYYY-MM-DD');
    let data = this.state.data.rfe_anoms || [];
    let maps = this.dataOptions(data);
    const map = maps.filter(map => map.date === date);
    if (!isEmpty(map[0])) return <Map onClick={()=>this.onOpenModal(map[0])}>
      <ImageContainer><MapImage src={`${config.ROOT_URL}/media/${map[0].file}`} alt=""  height="200" width="165" style={{objectFit: 'contain'}}/>
        <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
      </ImageContainer>
    </Map>;
    return <Map><MapImage src={`${config.PUBLIC_URL}/img/n_a.png`} alt=""  height="200" width="165" style={{objectFit: 'contain'}}/></Map>;
  }
  openModal() {
    this.setState({modalIsOpen: true});
  }
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  onOpenModal =(node)=>{
    this.setState({modal: node});
    this.openModal();
  };
  dataFiles =(options) =>{
    return options.map(option =>{
      return `${config.ROOT_URL }/media/${option.node.file}`
    })
  };

  onBackClick=()=>{
    console.log('back');
    let country = this.props.country ? this.props.country.value.id: null;
    let admin_area = this.props.admin_area ? this.props.admin_area.value.id: null;
    let pa_area = this.props.pa_area ? this.props.pa_area.value.id: null;
    let Q = {country: country, pa: pa_area, admin: admin_area,
      rfeBefore: this.state.data.rfes_info.startCursor, rfeMonBefore: this.state.data.rfe_mon_info.startCursor, rfeAnomBefore: this.state.data.rfe_anoms_info.startCursor};
    this.getRFEData(Q);
  };
  onNextClick=()=>{
    console.log('next');
    let country = this.props.country ? this.props.country.value.id: null;
    let admin_area = this.props.admin_area ? this.props.admin_area.value.id: null;
    let pa_area = this.props.pa_area ? this.props.pa_area.value.id: null;
    let Q = {country: country, pa: pa_area, admin: admin_area,
      rfeAfter: this.state.data.rfes_info.endCursor,
      rfeMonAfter: this.state.data.rfe_mon_info.endCursor, rfeAnomAfter: this.state.data.rfe_anoms_info.endCursor};
    this.getRFEData(Q);
  };
  getRFEData=(Q)=>{
    if(this.props.area === "region"){
      regionRFE(Q).then((resp)=>{
        if(this._isMounted) {
          if(resp.rfes.length !== 0) {
            this.setState({data: resp});
          }
        }
      }).catch(error => {
        throw(error);
      })
    } else if(this.props.area === "country"){
      countryRFE(Q).then((resp)=>{
        if(this._isMounted) {
          if(resp.rfes.length !== 0) {
            this.setState({data: resp});
          }
        }
      }).catch(error => {
        throw(error);
      })
    } else if (this.props.area === "pa_area" && this.props.area !== "country"){
      paAreaRFE(Q).then((resp)=>{
        if(this._isMounted) {
          if(resp.rfes.length !== 0) {
            this.setState({data: resp});
          }
        }
      }).catch(error => {
        throw(error);
      })
    }else if (this.props.area === "admin_area"){
      adminAreaRFE(Q).then((resp)=>{
        if(this._isMounted) {
          if(resp.rfes.length !== 0) {
            this.setState({data: resp});
          }
        }
      }).catch(error => {
        throw(error);
      })
    }
  };


  render() {
    let links=[...this.dataFiles(this.state.data.rfes), ...this.dataFiles(this.state.data.rfe_mon), ...this.dataFiles(this.state.data.rfe_anoms)];
    let name = this.props.data.name+"-rfe";
    return (
      <Fragment>
        <Wrapper>
          <HeaderBar>
            <Col8>
              <span>Rainfall Estimate (RFE)</span>
            </Col8>
            <Col4 style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
              <Pagination>
                <NextBackButton>
                  <p data-tip="Back"><IoIosArrowRoundBack onClick={this.onBackClick} color="#ffffff" size={30}/></p><ReactTooltip />
                </NextBackButton>
                <NextBackButton>
                  <p data-tip="Next"><IoIosArrowRoundForward onClick={this.onNextClick} color="#ffffff" size={30}/></p><ReactTooltip />
                </NextBackButton>
              </Pagination>

              <ZipnDownload>
                <p data-tip="Zip and Download all images"><IoMdDownload onClick={() => zipDownload(links, name) } color="#ffffff" size={30}/></p><ReactTooltip />
              </ZipnDownload>
            </Col4>
          </HeaderBar>
          {this.state.rfes.length !== 0 ?
            <Fragment>
              <VariableHead>
                <Col1 />
                <Period>1st Dekad</Period>
                <Period>2nd Dekad</Period>
                <Period>3rd Dekad</Period>
                <ColSpace />
                <Period>Monthly</Period>
                <Period>Anomaly</Period>
              </VariableHead>
              {this.state.rfes.map((month) =>{
                return <Row key={month.date} style={{justifyContent: "center", width: "100%"}}>
                  <Col1><MonthTitle><TitleText>{moment(month.date).format('MMM-YYYY')}</TitleText></MonthTitle></Col1>
                  {this.getDekad(month.maps, 'A_1')}
                  {this.getDekad(month.maps, 'A_11')}
                  {this.getDekad(month.maps, 'A_21')}
                  <ColSpace />
                  {this.getMonthly(month.date)}
                  {this.getAnomaly(month.date)}
                </Row>
              })}
            </Fragment>
            : <NotAvailable/>
          }
          <EcoModal
            closeModal={this.closeModal}
            modalIsOpen={this.state.modalIsOpen}
            afterOpenModal={this.afterOpenModal}
            location={this.props.data.name}
            modal={this.state.modal}
          />
        </Wrapper>
      </Fragment>
    )
  }
}

export default RFE;

