export default {
  fontColor: '#1D1D1B',
  mainColor: 'rgb(46,139,87)',
  brownColor: '#A52A2A',
  blueColor: '#2F3592',
  greenColor: '#0B612D',
  yellowColor: '#f7a600',
  orangeColor: '#F5A601',
  blackColor: '#1D1D1B',
  darkgreyColor: '#646463',
  greyColor: '#9D9D9C',
  lightgreyColor: '#D0D0D0',
  sectionBackground: '#F8F9FB',
  bodyFont: "'Manjari', sans-serif",
  headerFont: "'Raleway', sans-serif",
  numberFont: "'Questrial', sans-serif",
  shadow: "rgba(0,0,0,0.7)",
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
}
