import React from 'react';
import PropTypes from "prop-types";

const TextInput = ({name, onChange, value, placeholder, label, error }) => (
  <div>
    {label ? <label>{label}</label>: ""}
    <input
      required
      className="text-input input-a"
      type="password"
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {error && <span className="input-error">{error}</span>}
  </div>
);

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string

};

export default TextInput;
