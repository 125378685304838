import React, {Component, Fragment} from 'react';
import Toggle from 'react-toggle';
import {Col4, Container} from "../../../../theme/grid";
import Compare from "./compare";
import styled from "styled-components";
import config from "../../../../config";



const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;
const Section = styled.div`
  padding-bottom: 1rem;
  background: #ffffff;
`;

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  line-height: 1.3;
  font-weight: 400;
  padding: 0.5rem 2rem;
  width: 100%;
  color: #ffffff;
  text-align: center;
  @media (max-width: 768px){
    width: 100%;
  }
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background: ${props => (props.theme.mainColor)};
`;
const ResultsContainer = styled(Container)`
box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
border-radius: 0.3rem;
`;
const ToogleText = styled.div`
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 0.3rem;
`;
const Image = styled.img`
  height: auto;
  width: 100%;
  padding: 1rem;
`;



class ClimaticIndicators extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ecoClimatic: true,
      changeStats: true,
      fire: true,
      trends: true,
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  toggleEcoClimatic =()=>{this.setState({ecoClimatic: !this.state.ecoClimatic})};
  toggleChangeStats =()=>{this.setState({changeStats: !this.state.changeStats})};
  toggleFire =()=>{this.setState({fire: !this.state.fire})};
  toggleTrends =()=>{this.setState({trends: !this.state.trends})};

  render() {
    return (
        <Fragment>
          <Section>
            <ResultsContainer>
              <Row>
                <SectionTitle><span>Compare eco-climatic indicators</span>
                  <Toggle
                    checked={this.state.ecoClimatic}
                    icons={{
                      checked: <ToogleText>On</ToogleText>,
                      unchecked: <ToogleText>Off</ToogleText>,
                    }}
                    name='ecoclimatic'
                    value='yes'
                    onChange={this.toggleEcoClimatic}/>
                </SectionTitle>
              </Row>
              {this.state.ecoClimatic ? <Compare data={this.props.data}/> : ""}
            </ResultsContainer>
          </Section>
          <Section>

            <ResultsContainer>
              <Row>
                <SectionTitle><span>Conditions for the past year</span>
                  <Toggle
                    checked={this.state.trends}
                    icons={{
                      checked: <ToogleText>On</ToogleText>,
                      unchecked: <ToogleText>Off</ToogleText>,
                    }}
                    name='trends'
                    value='yes'
                    onChange={this.toggleTrends}/>
                </SectionTitle>
              </Row>
              {this.state.trends ?
                <Row style={{justifyContent: "space-evenly"}}>
                  <Col4><Image src={`${config.PUBLIC_URL}/img/dashboard/ndvi_current_lt_tsavo.png`} alt=""/></Col4>
                  <Col4><Image src={`${config.PUBLIC_URL}/img/dashboard/rfe_current_lt_tsavo.png`} alt=""/></Col4>
                  <Col4><Image src={`${config.PUBLIC_URL}/img/dashboard/rfe_lta_tsavo.png`} alt=""/></Col4>
                </Row> : ""}
            </ResultsContainer>

          </Section>
        </Fragment>




    )
  }
}

export default ClimaticIndicators;
