import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";

const Container = styled(Link)`
  display: inline-block;
  position: relative;
  background-color: transparent;
  color: ${props => props.theme.darkgreyColor};
  font-family: ${props => props.theme.bodyFont};
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1.4px;
  line-height: normal;
  text-decoration: none;
  padding: 0 0 5px
  border: 0;
  transition: 0.3s ease-out;
  &:after{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: ${props => props.theme.darkgreyColor};
    transition: 0.3s all;
  }
  &:hover{
    background-color: transparent;
    &:after{
      background-color: transparent;
    }
  }

`;

const LineButtonDark = ({text, link})=>(
  <Container to={link}>
    {text}
  </Container>
);

export default LineButtonDark;
