import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function formatReducer(state = initialState.format, action) {
  switch  (action.type) {

    case types.ON_FORMAT_CHANGE:
      return action.format;


    default:
      return state;
  }
}
