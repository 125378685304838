import React, {Component, Fragment} from 'react';
import styled, {keyframes} from "styled-components";
import {IoMdDownload} from "react-icons/io";
import {Row, Col2, Col3} from "../../../../theme/grid";
import config from "../../../../config";
import LandCoverChangeLegend from "../legends/LandCoverChangeLegend";
import MapModal from "../modals/MapModal";
import {slugify} from "../../../../utils/slugify";
import {LazyImage} from "../../../../utils/Image"
import ReactTooltip from "react-tooltip";
import {MetaButton} from "../../../../components/buttons";
import MetaModal from "../modals/MetaModal";




const Map = styled(Col3)`
position: relative;
width: 100%;
height: 300px;
margin: 0.5rem;
cursor: pointer;
`;

const Period = styled.div`
  text-align: center;
  padding-top: 0.5rem;
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
    &:hover{
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }
`;

const LegendContainer = styled(Col2)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: space-around;
`;
const MapImage = styled(LazyImage)`
  object-fit: contain;
  border: 1px solid #eeeeee;
`;

const placeHolderShimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const ImageLoading = styled.div`
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 245px 245px;
    height: 245px;
    position: relative;
    overflow: hidden;
`;
const DownloadIcon =styled.div`
  position: absolute;
  top: 0;
  right: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  height: 2rem;
  width: 2rem;
  display: none;
  justify-content: center;
  align-items: center;
  ${Map}:hover & {
    display: flex;
  }

`;


class CoverChangeMaps extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modalIsOpen: false,
      modal: {},
      metaModal: false,
      meta: {},
      location:""
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }
  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  closeMetaModal=()=>{
    this.setState({metaModal: false});
  };
  onOpenModal =(node)=>{
    this.setState({modal: node});
    this.setState({location: slugify(this.props.landcover.name+"_"+this.props.landcover.country.name)});
    this.openModal();
  };

  onMetaClick =()=>{
    let meta = {file: this.props.landcover.coverChangeMeta};
    this.setState({meta: meta});
    this.setState({metaModal: true});
  };

  render(){
    let landcover = this.props.landcover;
    return (
      <Fragment>
        <Row style={{justifyContent: 'center'}}>
          {landcover.coverchange_maps ?
            landcover.coverchange_maps.edges.map((cover_map) => {
              return (cover_map ? <Map key={cover_map.node.id} onClick={()=>this.onOpenModal(cover_map.node)}>
                  <ImageContainer>
                    <MapImage
                      src={`${config.ROOT_URL}/media/${cover_map.node.image}`}
                      alt=""
                      height="245"
                      width="245"
                      style={{objectFit: 'contain'}}
                    />
                  </ImageContainer>
                  <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
                  <Period>{cover_map.node.title}</Period>
                </Map>:<ImageLoading />
              )
            })
            : ""
          }
          <LegendContainer>
            <LandCoverChangeLegend coverChangeLegend={landcover.coverChangeLegend}/>
            <div style={{padding: '1rem'}}> <MetaButton text="Metadata"  onClick={this.onMetaClick} /></div>
          </LegendContainer>
        </Row>
        <MapModal
          closeModal={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          afterOpenModal={this.afterOpenModal}
          landcover={landcover}
          legend={landcover.coverChangeLegend}
          location={this.state.location+"-cover-change-"}
          modal={this.state.modal}
        />
        <MetaModal
          modalIsOpen={this.state.metaModal}
          modal={this.state.meta}
          closeModal={this.closeMetaModal} />
      </Fragment>

    )
  }
}

export default CoverChangeMaps;
