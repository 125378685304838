import React from "react";
import styled from 'styled-components';
import {Link} from "react-router-dom";

const Section = styled(Link)`
  display: inline-block;
  position: relative;
  padding: 0.4rem 0.8rem;
  border-width: 1px;
  border-style: solid;
  outline: none;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  font-family: ${props => props.theme.bodyFont};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.4px;
  line-height: normal;
  transition: 0.3s ease-out;
  border-color: #f7a600;
  background-color: #f7a600;
  :hover{
    color: #f7a600;
    background-color: #ffffff;
  }
`;



const CardButton = ({text, link}) => (
  <Section to={link} >
    {text}
  </Section>
);

export default CardButton;
