import styled from 'styled-components';

export const Container = styled.div.attrs(props => ({
  className: "container",
}))`
`;
export const FluidContainer = styled.div.attrs(props => ({
  className: "container-fluid",
}))`
`;
export const Row = styled.div.attrs(props => ({
  className: "row",
}))`
`;
export const Col1 = styled.div.attrs(props => ({
  className: "col-md-1",
}))`
`;
export const Col2 = styled.div.attrs(props => ({
  className: "col-md-2",
}))`
`;
export const Col3 = styled.div.attrs(props => ({
  className: "col-md-3",
}))`
`;
export const Col4 = styled.div.attrs(props => ({
  className: "col-md-4",
}))`
`;
export const Col5 = styled.div.attrs(props => ({
  className: "col-md-5",
}))`
`;
export const Col6 = styled.div.attrs(props => ({
  className: "col-md-6",
}))`
`;
export const Col7 = styled.div.attrs(props => ({
  className: "col-md-7",
}))`
`;
export const Col8 = styled.div.attrs(props => ({
  className: "col-md-8",
}))`
`;
export const Col9 = styled.div.attrs(props => ({
  className: "col-md-9",
}))`
`;
export const Col10 = styled.div.attrs(props => ({
  className: "col-md-10",
}))`
`;
export const Col11 = styled.div.attrs(props => ({
  className: "col-md-11",
}))`
`;
export const Col12 = styled.div.attrs(props => ({
  className: "col-md-12",
}))`
`;
