import React, {Component, Fragment} from "react";
import {withRouter} from "react-router-dom";
import {setViewPort} from "../actions/setViewPort";
import Seo from "../seo";
import Copyright from "./footer/Copyright";
import Header from "./header";
import {Container} from "../theme/grid";
import styled from "styled-components";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as authActions from "../actions/authActions";
import {connect} from "react-redux";
import AuthModal from "../components/auth/AuthModal";
import SideMenu from "./header/SideMenu";

setViewPort();

const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  position: fixed;
  top: 6rem;
  width: 100%;
  height: 60px;
  z-index: 100;
`;
const H3 = styled.h3`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;

`;

const H5 = styled.h5`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const PageTitle = styled(H3)`
  color: #ffffff;
  font-size: 1.2rem;
`;
const PageSubTitle = styled(H5)`
  color: #ffffff;
`;
const Side = styled.div`
z-index: 1000;
position: relative;
flex-direction: row;
justify-content: flex-end;
background-color: #ffffff;
display: ${props => props.show ? "flex" : "none"};
height: ${props => props.show ? "100vh" : "0"};
width: ${props => props.show ? "100%" : "0"};
`;

class DownloadLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenu: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  sideMenuToggle=()=>{
    this.setState({ sideMenu: !this.state.sideMenu })
  };
  render() {
    return (
      <Fragment>
        <Seo title={this.props.page.title} description={this.props.page.description} path={this.props.location.pathname}/>
        <Header sideMenuToggle={this.sideMenuToggle} sideMenu={this.state.sideMenu}/>
        <Side show={this.state.sideMenu}>
          <SideMenu sideMenuToggle={this.sideMenuToggle}/>
        </Side>
        <main>
          <PageBanner>
            <Container style={{textAlign: "center"}}>
              <PageTitle>{this.props.page.title}</PageTitle>
              <PageSubTitle>{this.props.page.description}</PageSubTitle>
            </Container>
          </PageBanner>
          {this.props.children}
        </main>
        <Copyright/>
        <AuthModal
          closeModal={this.props.actions.onAuthModal}
          modalIsOpen={this.props.modal}
          afterOpenModal={this.afterOpenModal}
        />
      </Fragment>
    )
  }
}

DownloadLayout.propTypes = {
  actions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    isAuthenticated: state.isAuthenticated,
    modal: state.modal
  };

}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRouter(DownloadLayout)));
