import React, { Component } from "react";
import Chart from 'react-apexcharts';
import {loadCSV, parseNDVIChart} from "../../../../actions/loadCSV";


class NDVIChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: {},
      chart_error: []
    }
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getNDVIData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState){
    if (prevProps.ndviChart !== this.props.ndviChart) {
      this.getNDVIData();
    }
  }
  getNDVIData=()=>{
    loadCSV(this.props.ndviChart).then((results)=>{
      let data = parseNDVIChart(results.data);
      if (this._isMounted) {
        this.setState({chart: data});
        this.setState({chart_error: results.errors});
      }
    })
  };
  processChart(data){
    let months = data.map((item) => item.month).filter((item, index, array) => array.indexOf(item) === index);
    let ndviData = data.reduce((obj, curr) => {
      if(!obj[curr.name]){
        obj[curr.name] = []
      }
      obj[curr.name][months.indexOf(curr.month)] = parseFloat(curr.ndvi);
      return obj
    }, {});
    const seriesNDVI = Object.entries(ndviData).map(([name, seriesArr]) => {
      return {
        name: 'NDVI',
        data: months.map((month, monthIndex) => {
          if(!seriesArr[monthIndex]){
            return 0
          } else {
            return seriesArr[monthIndex]
          }

        })
      }
    });
    let mean = data.reduce((obj, curr) => {
      if(!obj[curr.name]){
        obj[curr.name] = []
      }
      obj[curr.name][months.indexOf(curr.month)] = parseFloat(curr.mean);
      return obj
    }, {});
    const seriesMean = Object.entries(mean).map(([name, seriesArr]) => {
      return {
        name: 'Mean',
        data: months.map((month, monthIndex) => {
          if(!seriesArr[monthIndex]){
            return 0
          } else {
            return seriesArr[monthIndex]
          }

        })
      }
    });
    let min = data.reduce((obj, curr) => {
      if(!obj[curr.name]){
        obj[curr.name] = []
      }
      obj[curr.name][months.indexOf(curr.month)] = parseFloat(curr.min);
      return obj
    }, {});
    const seriesMin = Object.entries(min).map(([name, seriesArr]) => {
      return {
        name: 'Min',
        data: months.map((month, monthIndex) => {
          if(!seriesArr[monthIndex]){
            return 0
          } else {
            return seriesArr[monthIndex]
          }

        })
      }
    });
    let max = data.reduce((obj, curr) => {
      if(!obj[curr.name]){
        obj[curr.name] = []
      }
      obj[curr.name][months.indexOf(curr.month)] = parseFloat(curr.max);
      return obj
    }, {});
    const seriesMax = Object.entries(max).map(([name, seriesArr]) => {
      return {
        name: 'Max',
        data: months.map((month, monthIndex) => {
          if(!seriesArr[monthIndex]){
            return 0
          } else {
            return seriesArr[monthIndex]
          }

        })
      }
    });
    if (this._isMounted) {
      this.setState({categories: months});
      this.setState({seriesNDVI: seriesNDVI[0]});
      this.setState({seriesMean: seriesMean[0]});
      this.setState({seriesMin: seriesMin[0]});
      this.setState({seriesMax: seriesMax[0]});
    }
  }

  loadOptions(categories){
    return {
      colors : ['rgb(0, 185, 6)', 'rgb(240, 138, 93)', 'rgb(249, 237, 105)', 'rgb(0, 144, 158)'],
      title: {
        text: 'Last 12 Months NDVI Vs Long Term',
        style: {
          fontSize:  '14px',
          fontWeight:  'normal',
          color:  '#263238'
        }
      },
      chart: {
        id: `${this.props.location+"-ndvi-chart"}`,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%'
        },
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        markers: {
          radius: 0
        }
      },
      stroke: {
        show: true,
        width: 2,
        dashArray: [0, 0,0,0]
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: {
          text: "NDVI",
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: "'Raleway', sans-serif",
            fontWeight: 400,
          },
        },
        labels: {
          formatter: function (val) {
            return val ? val.toFixed(1): null
          }
        }
      },
      fill: {
        opacity: 1
      }
    }
  }
  render() {
    return (
      <div id="ndvi-chart" style={{paddingTop: '2rem'}}>
        {this.state.chart.series ?
        <Chart
            options={this.loadOptions(this.state.chart.categories)}
            series={this.state.chart.series}
          type="line" height="300"/>
          :<p>Loading......</p>}
      </div>
    )
  }
}
export default NDVIChart;
