import React, { useEffect, useRef } from 'react';

const TwitterFeed = () => {
  const twitterRef = useRef(null);

  useEffect(() => {
    // Check if the Twitter script is already included
    if (window.twttr) {
      window.twttr.widgets.load(twitterRef.current);
    } else {
      // Create a script element for the Twitter widgets script
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.async = true;
      script.onload = () => {
        window.twttr.widgets.load(twitterRef.current);
      };
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
    
      <div ref={twitterRef}>
        <a
          className="twitter-timeline"
          data-height="280"
          href="https://twitter.com/ICPAC_GMES?ref_src=twsrc%5Etfw"
        >
        </a>
      </div>
    </div>
  );
};



export default TwitterFeed;
