import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import { IoIosHome, IoIosPerson} from 'react-icons/io';
import {Row, Col6, FluidContainer} from "../../theme/grid";
import config from "../../config";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as authActions from "../../actions/authActions";
import {connect} from "react-redux";


const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    display: none;
  }
`;
const Links = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  height: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ListItem = styled.li`
  line-height: 30px;
  position: relative;
  > a{
    //color: ${props => props.landing ? "#ffffff": props => props.theme.darkgreyColor};
    color: ${props => props.theme.darkgreyColor};
    font-family: ${props => props.theme.bodyFont};
    &:hover{
      color: ${props => props.theme.fontColor};
    }
    transition: 0.3s ease-out;
  &:after{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    content: '';
    background-color: transparent;
    transition: 0.3s all;
  }
  &:hover{
    &:after{
      background-color: ${props => props.theme.fontColor};
    }
  }
  &:active{
    &:after{
      background-color: ${props => props.theme.fontColor};
    }
  }
  }

`;
const Home = styled(ListItem)`
svg{
  margin-bottom: 0.5rem;
}

`;
const MenuLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  padding: 1.5rem 0.25rem;
  margin: 0 0.2rem;
  text-decoration: none;
  text-transform: uppercase;

`;
const DropItem = styled(ListItem)`

`;
const ProductDropItem = styled(ListItem)`

`;
const DropMenuLink = styled(MenuLink)`
  width: 100%;
  padding: 0.3rem 0.5rem;
  margin: 0.1rem 0;
  justify-content: flex-start;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1rem;
  font-family: ${props => props.theme.bodyFont};
  color: ${props => props.theme.fontColor};
  &:hover{
    color: ${props => props.theme.blackColor};
   }
  &:after{
    display: none;
  }
`;
const Dropdown = styled.ul`
  list-style: none;
  position: absolute;
  background-color: #ffffff;
  border-radius: 0.1rem;
  border: 1px solid ${props => props.theme.lightgreyColor};
  top: 100%;
  left: 0;
  padding: 10px 0;
  width: 15rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.08);
  display: none;
  ${DropItem}:hover & {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    display: block;
  }
`;
const ProductDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: -150px;
  height: 200px;
  width: max-content;
  background-color: #ffffff;
  border-radius: 0.3rem;
  padding-top: 1rem;
  border: 1px solid ${props => props.theme.lightgreyColor};
  display: none;
  ${ProductDropItem}:hover & {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    display: block;
  }
`;
const ProductItem = styled(Col6)`
  padding: 0.5rem;
  &:hover{
    background: #f1f1f1;
  }
  a{
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: ${props => props.theme.fontColor};
  }
`;
const Text = styled.div`
    font-family: ${props => props.theme.bodyFont};
    width: 250px;
    font-weight: 700;
    font-size: 1rem;
    padding-left: 0.5rem;
    color: ${props => props.theme.fontColor};
    ${ProductItem}:hover & {
      color: ${props => props.theme.blackColor};
    }
    p{
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      color: ${props => props.theme.fontColor};
    }
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #eeeeee;
  border: 1px solid #d6d6d6;
  ${ProductItem}:hover & {
    background: #d6d6d6;
    border: 1px solid #bebebe;
  }
`;
const ImageIcon = styled.img`
  height: 30px;
  width: 30px;
  object-fit: contain;
  ${ProductItem}:hover & {

  }

`;
const User = styled.div`
  height: 6rem;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

`;

const Person = styled.div`
  border: 1px solid #646463;
  color: #646463;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 0.1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

`;
const UserMenu = styled.div`
  position: absolute;
  top: 6rem;
  background: #ffffff;
  height: auto;
  width: 150px;

  border: 1px solid #eeeeee;
  box-shadow: 0 5px 30px 0 rgba(39,39,39,0.15);
  border-radius: 0.3rem;
  display: none;
  ${User}:hover & {
    display: block;
  }
`;
const UserMenuItem = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
  margin: 0.1rem 0;
  justify-content: flex-start;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1rem;
  cursor: pointer;
  font-family: ${props => props.theme.bodyFont};
  color: ${props => props.theme.fontColor};
  &:hover{
    color: ${props => props.theme.blackColor};
    background: #f1f1f1;
   }
  &:after{
    display: none;
  }
`;

class MainMenu extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onLogin=()=>{
    this.props.actions.onAuthModal()
  };
  onRegister=()=>{
    this.props.actions.onAuthModal()
  };
  onLogout=()=>{
    this.props.actions.userLogout()
  };
  render() { 
    return (
      <Section>
        <Nav>
          <Links>
            <Home landing={this.props.landing}><MenuLink to="/"><IoIosHome size={25} /></MenuLink></Home>

            <DropItem  landing={this.props.landing}><MenuLink to="#">What we do</MenuLink>
              <Dropdown>
                <ListItem><DropMenuLink to="/what-we-do/natural-habitats">Natural Habitats</DropMenuLink></ListItem>
                <ListItem><DropMenuLink to="/what-we-do/tropical-forest">Tropical Forest</DropMenuLink></ListItem>
                {/* <ListItem><DropMenuLink to="/what-we-do/agriculture-monitoring">Agriculture
                  Monitoring</DropMenuLink></ListItem> */}
                <ListItem><DropMenuLink to="/what-we-do/rangeland-monitoring">Rangeland
                  Monitoring</DropMenuLink></ListItem>
              </Dropdown>
            </DropItem>
            <ProductDropItem landing={this.props.landing}><MenuLink to="#">EO Products</MenuLink>
              <ProductDropdown>
                <FluidContainer>
                  <Row>
                    <ProductItem>
                      <Link to="/dashboard/land-cover-fires">
                        <ImageContainer>
                          <ImageIcon src={`${config.PUBLIC_URL}/icons/menu-fire-grey.png`} alt=""/>
                        </ImageContainer>
                        <Text>Land Cover & Fires <br/><p>Land use land cover change analysis and near-real time fire
                          monitoring</p></Text>
                      </Link>
                    </ProductItem>
                    <ProductItem>
                      <Link to="/dashboard/eco-climatic-analysis">
                        <ImageContainer>
                          <ImageIcon src={`${config.PUBLIC_URL}/icons/menu-climate-grey.png`} alt=""/>
                        </ImageContainer>
                        <Text>Eco-climatic Analysis<br/><p>10 day monitoring of climate and vegetation conditions</p>
                        </Text>
                      </Link>
                    </ProductItem>
                  </Row>
                  <Row>
                    <ProductItem>
                      <Link to="/dashboard/crop-forage-conditions">
                        <ImageContainer>
                          <ImageIcon src={`${config.PUBLIC_URL}/icons/crops-grey.png`} alt=""/>
                        </ImageContainer>
                        <Text>Rangeland Conditions<br/><p>Seasonal monitoring of rangeland conditions</p>
                        </Text>
                      </Link></ProductItem>
                    <ProductItem>
                      <Link to="/dashboard/bulletins">
                        <ImageContainer>
                          <ImageIcon src={`${config.PUBLIC_URL}/icons/menu-bulletin-grey.png`} alt=""/>
                        </ImageContainer>
                        <Text>Bulletins<br/><p>Bulletins/Reports on various analysis and monitoring data collected</p>
                        </Text>
                      </Link>
                    </ProductItem>
                  </Row>
                </FluidContainer>
              </ProductDropdown>
            </ProductDropItem>
            <ListItem landing={this.props.landing}><MenuLink to="/data-center">Data Center</MenuLink></ListItem>

            <DropItem landing={this.props.landing}><MenuLink to="#">About Us</MenuLink>
              <Dropdown>
                <ListItem><DropMenuLink to="/about-us">GMES & Africa</DropMenuLink></ListItem>
                <ListItem><DropMenuLink to="/news">Latest News</DropMenuLink></ListItem>
                <ListItem><DropMenuLink to={{ pathname: "https://icpac.net/events/" }} target="_blank">Events</DropMenuLink></ListItem>
                <ListItem><DropMenuLink to="/work-with-us/vacancies">Vacancies</DropMenuLink></ListItem>
                <ListItem><DropMenuLink to="/work-with-us/tenders">Tenders</DropMenuLink></ListItem>
                <ListItem><DropMenuLink to={{ pathname: "http://icpac.net" }} target="_blank">Visit ICPAC Website</DropMenuLink></ListItem>
              </Dropdown>
            </DropItem>
            <ListItem landing={this.props.landing}><MenuLink to="/publications">Publications</MenuLink></ListItem>
            <ListItem landing={this.props.landing}><MenuLink to="/get-in-touch">Contact Us</MenuLink></ListItem>
          </Links>

          <User>
            <Person landing={this.props.landing}><IoIosPerson size={30} /></Person>
            <UserMenu>
              {this.props.isAuthenticated ?
                <UserMenuItem onClick={this.onLogout}>Logout</UserMenuItem>
                :
                <UserMenuItem onClick={this.onLogin}>Login</UserMenuItem>
              }
            </UserMenu>
          </User>
        </Nav>

      </Section>
    );
  }
}

MainMenu.propTypes = {
  actions: PropTypes.object.isRequired,
  landing: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    modal: state.modal,
    isAuthenticated: state.isAuthenticated
  };

}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainMenu));
