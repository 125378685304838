import React, {Component, Fragment} from "react";
import LatestItem from "./LatestItem";
import config from "../../../config";


class FrontLatestNews extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){

  }

  // static initialAction(){
  //   return loadStations(getQuery());
  // }

  render () {
    return(
      <Fragment>
        {!this.props.news ? "" :
          this.props.news.map(item => (
              <LatestItem
                key={item.node.id}
                title={item.node.title}
                date={item.node.timestamp}
                summary={item.node.summary}
                pdf={item.node.pdf}
                img={`${config.ROOT_URL}/media/${item.node.image}`}
                readlink={`/news/${item.node.slug}`}
              />
            )
          )}
      </Fragment>
    );
  }
}
FrontLatestNews.propTypes = {

};

export default FrontLatestNews;
