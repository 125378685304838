import React from 'react';
import PropTypes from 'prop-types';



const AreaInput = ({name, label, onChange, value, error, options, disabled}) => {
  return (
    <div>
      {label ? <label>{label}</label>: ""}
      <select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className="text-input input-a">
        <option key="" value="">Select Area</option>
        {options.map((option) => {
          return <option key={option.id} value={option.slug}>{option.name}</option>;
        })
        }
      </select>
      {error && <span className="input-error">{error}</span>}
    </div>
  );
};
AreaInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object)
};


export default AreaInput;
