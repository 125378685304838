import React from "react";
import {Row} from "../../../theme/grid";
import EOCard from "./EOCard";


const  VegetationCards =({vegetations, onReadMore}) =>(
  <Row style={{justifyContent: 'center'}}>
    {vegetations.map(vegetation =>(
      <EOCard
        key={vegetation.node.id}
        eodata={vegetation.node.shortcode}
        color="#F5A601"
        title={vegetation.node.name}
        since={vegetation.node.timeSince}
        spatial_res={vegetation.node.spatialRes}
        temporal_res={vegetation.node.temporalRes}
        image={vegetation.node.dataType.image}
        link={vegetation.node.slug}
        onReadMore={()=>onReadMore(vegetation.node.readmore)}
      />
    ))}

  </Row>
);

export default VegetationCards;
