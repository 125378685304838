import React from 'react';
import styled from 'styled-components';
import config from "../../config";



const Logos = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`;
const Item = styled.div`
  margin: 1rem;
  padding: 0.5rem;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.img`
  height: 80px;
  width: auto;
`;

const ConsortiumMembers = ({logos}) => {
  return(
    <Logos>
      {logos.map( logo =>{
        return <Item key={logo.id}><Logo src={`${config.ROOT_URL}/media/${logo.image}`} alt={logo.title}/></Item>
      })}
    </Logos>
  )
};

export default ConsortiumMembers;
