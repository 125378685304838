import React from 'react';
import Modal from "react-modal";
import {IoIosCloseCircle, IoMdDownload} from 'react-icons/io';
import {Col12, Row} from "../../../../theme/grid";
import PropTypes from "prop-types";
import PDFDisplay from "../../../../components/pdf/PDFDisplay";
import config from "../../../../config";



const customStyles = {

  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    width                 : '50%',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : '1000'
  }
};
Modal.setAppElement('#root');


const MetaModal=({modal, modalIsOpen, afterOpenModal, closeModal})=>{
  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={modal.file}
    >
      <IoIosCloseCircle onClick={closeModal} size={25} color='#A52A2A' style={{position:"absolute", margin: '0.2rem', right: 0, top: 0, cursor: 'pointer', zIndex: 1 }}/>
      <a href={`${config.ROOT_URL}/media/${modal.file}`}
         target="_blank" rel="noopener noreferrer"
         style={{position:"absolute", margin: '0.2rem', right: '2rem', top: 0, cursor: 'pointer' }}>
        <IoMdDownload size={25} color='#000000' />
      </a>

      <Row id="meta" style={{backgroundColor: "#ffffff"}}>
        <Col12 style={{padding: "1rem"}}>
          <PDFDisplay pdf={modal.file}/>
        </Col12>
      </Row>
    </Modal>
  )
};

MetaModal.propTypes = {
  modal: PropTypes.object.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  afterOpenModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired
};
export default MetaModal;


