import React from "react";
import styled from "styled-components";
import {Link} from 'react-router-dom';
import {Container, Row, Col3} from "../../theme/grid";


const Section = styled.div`
  background: #ffffff;
  border-top: 1px solid #eeeeee;
`;
const Column = styled(Col3)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid #eeeeee;
  margin: 1rem 0;
  padding: 0 1rem;
  &:last-child{
    border-right: none;
  }
  @media (max-width: 768px) {
    border-right: none;
  }
`;
const FooterTitle = styled.div`
  font-family: ${props => props.theme.headerFont};
  color: ${props => props.theme.darkgreyColor};
  text-transform: uppercase;
  font-size: 0.83rem;
  padding: 5px;
  font-weight: 700;
`;
const FooterLink = styled(Link)`
  color: ${props => props.theme.darkgreyColor};
  font-size: 0.85rem;
  text-decoration: none;
  padding: 5px;
`;

const FooterMenu = ()=>(
  <Section>
    <Container>
      <Row>
        <Column>
          <FooterTitle>GMES</FooterTitle>
          <FooterLink to="/about-us">About US</FooterLink>
          <FooterLink to="/data-center">Data Center</FooterLink>
          <FooterLink to="/work-with-us/tenders">Tenders</FooterLink>
          <FooterLink to="/work-with-us/vacancies">Vacancies</FooterLink>
        </Column>
        <Column>
          <FooterTitle>Areas of Work</FooterTitle>
          <FooterLink to="/what-we-do/natural-habitats">Natural Habitats</FooterLink>
          <FooterLink to="/what-we-do/tropical-forest">Tropical Forest</FooterLink>
          {/* <FooterLink to="/what-we-do/agriculture-monitoring">Agriculture Monitoring</FooterLink> */}
          <FooterLink to="/what-we-do/rangeland-monitoring">Rangeland Monitoring</FooterLink>
        </Column>
        <Column>
          <FooterTitle>Resources</FooterTitle>
          <FooterLink to={{ pathname: "https://icpac.net/publications/" }} target="_blank">Publications</FooterLink>
          <FooterLink to={{ pathname: "/news" }}>News</FooterLink>
          <FooterLink to={{ pathname: "https://icpac.net/events/" }} target="_blank">Events</FooterLink>
        </Column>
        <Column>
          <FooterTitle>CONTACT US</FooterTitle>
          <FooterLink to="/get-in-touch">Get In Touch</FooterLink>
        </Column>
      </Row>
    </Container>

  </Section>

);

export default FooterMenu;
