import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Row, Col6, Col12} from '../../theme/grid'
import {LineButtonDark} from "../../components/buttons";
import config from "../../config";
import {LazyImage} from "../../utils/Image";
import trimText from "../../utils/trimText";


const NewsRow = styled(Row)`
  margin-bottom: 35px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  border-radius: 0.2rem;
  box-shadow: 2px 1px 25px 5px rgba(166, 166, 166, 0.2);
  border: 1px solid #f0f0f0;
  max-width: 250px;
`;
const Title = styled.h5`
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
`;
const Content = styled.div`
  background: #ffffff;
  padding: 1rem;

  p{
    font-size: 0.85rem;
  }

`;
const Text = styled.div`
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 400;
`;
const Date = styled(Text)`
  color: ${props => props.theme.greenColor};
  padding: 0.35rem;
  border-radius: 0.2rem;
`;

const NewsItem2 = ({title, image, timestamp, summary, slug})=>{
  return(
    <NewsRow className='no-gutters'>
      <Col12>
        <LazyImage src={`${config.ROOT_URL}/media/${image}`} alt={`${title} cover`} width="100%" height="220px" style={{objectFit: 'cover'}}/>
      </Col12>
      <Col12>
        <Content>
          <Title className="heading_title">{title}</Title>
          <Date>{moment(timestamp).format('DD MM YYYY')}</Date>

          <p>{trimText(summary,130)+"..."}</p>
        </Content>
        <Row style={{ justifyContent: 'flex-end', marginBottom: '1rem'}}>
          <Col6>
            <LineButtonDark
              text="Read More"
              link={{ pathname: `/news/${slug}` }}
            />
          </Col6>
        </Row>
      </Col12>

    </NewsRow>
  )
};

export default NewsItem2;
