import React, {Component} from 'react';
import Layout from "../layout";
import styled from "styled-components";
import {IoIosHome, IoIosMail, IoIosCube, IoIosCall} from 'react-icons/io'
import {Container, Row, Col3, Col4, Col9, Col12} from "../theme/grid";
import {TextInput, TextArea} from '../components/input';
import {Submit} from '../components/buttons';


const PageBanner = styled.div`
  background: ${props => (props.theme.mainColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 10vh;
`;
const H3 = styled.h3`
  color: #ffffff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.4rem;
`;
const H4 = styled.h4`
  font-family: ${props => props.theme.headerFont};
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem;
`;
const H5 = styled.h5`
  color: #ffffff;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;
const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin: 1.5rem 0;

`;
const ContactItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
`;
const ContactText = styled.div`
  font-family: ${props => props.theme.bodyFont};
  font-size: 0.9rem;
  padding-left: 0.5rem;
`;
const ContactPhone = styled(ContactText)`
  font-family: ${props => props.theme.numberFont};
`;
const ContactForm = styled.div`
  padding: 0 1rem;
  margin: 1.5rem 0;
`;

const Map = styled.iframe`
  height: 450px;
  width: 100%;
  border: 0;
`;
const Input = styled(Col4)`
  padding: 0 1rem;
`;
const InputArea = styled(Col12)`
  padding: 0 1rem;
`;
const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 1.5rem 0;
  padding: 0 1rem;
`;

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback:{name:'', email:'', phone:'', interest:'', message:''},
      interests:[]

    };

  }
  sendMessage =()=>{
    console.log('Send Message')
  };
  onChange=(event)=>{
    const field = event.target.name;
    let feedback = this.state.feedback;
    feedback[field] = event.target.value;
    return this.setState({feedback: feedback});
  };



  render() {
    let page ={
      title: "Contact Us",
      description: "The Global Monitoring for Environment and Security and Africa (GMES & Africa) Support Programme is " +
        "the result of the longstanding cooperation between Africa and Europe in the area of space science & technology, " +
        "which is one of the key priorities of the long-term EU-Africa Joint Strategy",
      keywords:"GMES and AFRICA contact page"
    };
    return (
      <Layout page={page}>
        <PageBanner>
          <Container style={{textAlign: "center"}}>
            <H3>Get in Touch</H3>
            <H5>Visit us or send us a message</H5>
          </Container>
        </PageBanner>
        <Container>
          <Row>
            <Col3>
              <ContactDetails>
                <H4 style={{paddingLeft: 0}}>Contact Details</H4>
                <ContactItem>
                  <IoIosHome size={35} />
                  <ContactText> IGAD Climate Prediction & Applications Centre (ICPAC)</ContactText>
                </ContactItem>
                <ContactItem>
                  <IoIosCube size={25} />
                  <ContactText>P.O. Box 10304, 00100 Nairobi, Kenya</ContactText>
                </ContactItem>
                <ContactItem>
                  <IoIosMail size={25} />
                  <ContactText>gmes-ea@icpac.net</ContactText>
                </ContactItem>
                <ContactItem>
                  <IoIosCall size={35} />
                  <ContactPhone>(+254)  020 351 4426, 0714 435 259, 0739 167 809</ContactPhone>
                </ContactItem>
              </ContactDetails>
            </Col3>
            <Col9>
              <ContactForm>
                <H4>Contact Form</H4>
                <Row>
                  <Input>
                    <TextInput onChange={this.onChange} value={this.state.feedback.name} name='name' placeholder='Name' />
                  </Input>
                  <Input>
                    <TextInput onChange={this.onChange} value={this.state.feedback.email} name='email' placeholder='Email'  />
                  </Input>
                  <Input>
                    <TextInput onChange={this.onChange} value={this.state.feedback.phone} name='phone' placeholder='Phone'  />
                  </Input>
                </Row>
                <Row>
                  <InputArea>
                    <TextArea onChange={this.onChange} value={this.state.feedback.message} name='message' placeholder='Message' rows={5} />
                  </InputArea>
                </Row>
                <Buttons>
                  <Submit text="Send Message" onClick={this.sendMessage}/>
                </Buttons>
              </ContactForm>
            </Col9>
          </Row>
        </Container>
        <Map
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d255280.7132603797!2d36.6613892!3d-1.3189983!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1d67a35401f9%3A0x5fc9df2f34c9b66!2sIGAD%20Climate%20Prediction%20and%20Applications%20Centre%2C%20Ngong%20Offices!5e0!3m2!1sen!2ske!4v1588934441764!5m2!1sen!2ske"
          frameBorder="0" allowFullScreen="" />
      </Layout>
    )
  }
}

export default ContactPage;

