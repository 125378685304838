import React from 'react';
import PropTypes from 'prop-types';

const CheckboxInput = ({ onChange, value, name, label, checked, style }) => {
  return (
    <label className="checkbox">
      {label}
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <span className="checkmark" style={style} />
    </label>
  );
};

CheckboxInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  style: PropTypes.object 
};

export default CheckboxInput;