import React from "react";
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {FaFacebookF} from 'react-icons/fa'
import config from "../../config";

const Section = styled(Link)`
  border-radius: 5px;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
  border-width: 1px;
  border-style: solid;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  font-family: ${props => props.theme.headerFont};
  font-weight: 500;
  letter-spacing: 1.4px;
  line-height: 0.8rem;
  transition: 0.3s ease-out;
  width: fit-content;
  border-color: ${props => props.theme.darkgreyColor};
  outline: none;
  color: ${props => props.theme.darkgreyColor};
  background-color: #ffffff;
  span{
    margin-left: 0.35rem;
    padding: 0px;
    font-size: 0.7rem;
  }
  :hover{
    color: #ffffff;
    background-color: ${props => props.theme.darkgreyColor};
  }
`;



const FBShare = ({title, to}) => (
  <Section to={{
    pathname: `https://www.facebook.com/share.php?u=url(${config.PUBLIC_URL}${to})&title=${title}`}}
           target="_blank">
    <FaFacebookF size={16}/>
    <span>Facebook</span>
  </Section>
);

export default FBShare;
