import React, {Component, Fragment} from 'react';
import styled from "styled-components";
import {IoMdDownload} from "react-icons/io";
import ReactTooltip from 'react-tooltip';
import {Row, Col3} from "../../../../theme/grid";
import config from "../../../../config";
import {isEmpty} from "../../../../utils/isEmpty";
import {LazyImage} from "../../../../utils/Image";
import EcoModal from "../modals/EcoModal";


const MapsHead = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  transform-origin: 0 0;
  transform: rotate(270deg);
  padding: 1rem;
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  bottom: 40%;
  left: 10px;
`;
const Map = styled(Col3)`
  padding: 1rem;
  cursor: pointer;
`;
const Header = styled(Col3)`
  padding: 0.3rem 1rem;
  text-align: center;
`;
const MapAndLegend = styled.div`
  display: flex;
  flex-direction: row;
`;
const Period = styled.div`
  text-align: center;
  padding-top: 0.5rem;
`;
const MapContainer = styled.div`
  position: relative;
  height: 300px;
  width: 240px;
  &:hover{
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }
`;
const DownloadIcon =styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  height: 2rem;
  width: 2rem;
  display: none;
  justify-content: center;
  align-items: center;
  ${MapContainer}:hover & {
    display: flex;
  }
`;
const MapImage = styled(LazyImage)`
  &[style] {
  object-fit: contain !important;
  border: 1px solid #eeeeee !important;
  }

`;

class Compare  extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modalIsOpen: false,
      modal: {},
      location:""
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }
  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  onOpenModal =(node)=>{
    this.setState({modal: node});
    // this.setState({location: slugify(this.props.data.name)});
    this.openModal();
  };
  render() {
    let data = this.props.data;
    return (
      <Fragment>
        <Row style={{justifyContent: "space-evenly", paddingRight: '1rem', width: '100%'}}>
          <MapsHead />
          <Header>NDVI</Header>
          <Header>RFE</Header>
          {/*<Header>DMP</Header>*/}
          <Header>Soil Moisture</Header>
        </Row>

        <Row style={{justifyContent: "space-evenly", paddingRight: '1rem', width: '100%'}}>
          <MapsHead><Title>Current</Title></MapsHead>
          <Map>
            <Period>{!isEmpty(data.ndvi) ? data.ndvi.date : <p/>}</Period>
            <MapAndLegend>
              {!isEmpty(data.ndvi) ?
                <MapContainer  onClick={()=>this.onOpenModal(data.ndvi)}>
                  <MapImage src={`${config.ROOT_URL}/media/${data.ndvi.file}`} alt="" height="300" width="240" style={{objectFit: 'contain'}}/>
                  <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
                </MapContainer> :
                <MapContainer><MapImage src={`${config.PUBLIC_URL}/img/na_1.png`} alt="" height="300" width="240"
                                        style={{objectFit: 'contain'}}/></MapContainer>}
            </MapAndLegend>
          </Map>

          <Map>
            <Period>{!isEmpty(data.rfe) ? data.rfe.date : <p/>}</Period>
            <MapAndLegend>
              {!isEmpty(data.rfe) ?
                <MapContainer onClick={()=>this.onOpenModal(data.rfe)}>
                  <MapImage src={`${config.ROOT_URL}/media/${data.rfe.file}`} alt="" height="300" width="240" style={{objectFit: 'contain'}}/>
                  <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
                </MapContainer> :
                <MapContainer><MapImage src={`${config.PUBLIC_URL}/img/na_1.png`} alt="" height="300" width="240"
                                        style={{objectFit: 'contain'}}/></MapContainer>}
            </MapAndLegend>
          </Map>


          {/*<Map>*/}
          {/*  <Period>{!isEmpty(data.dmp) ? data.dmp.date : <p/>}</Period>*/}
          {/*  <MapAndLegend>*/}
          {/*    {!isEmpty(data.dmp) ?*/}
          {/*      <MapContainer onClick={()=>this.onOpenModal(data.dmp)}>*/}
          {/*        <MapImage src={`${config.ROOT_URL}/media/${data.dmp.file}`} alt="" height="300" width="240" style={{objectFit: 'contain'}}/>*/}
          {/*        <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>*/}
          {/*      </MapContainer> :*/}
          {/*      <MapContainer><MapImage src={`${config.PUBLIC_URL}/img/na_1.png`} alt="" height="300" width="240"*/}
          {/*                              style={{objectFit: 'contain'}}/></MapContainer>*/}
          {/*    }*/}
          {/*    <DMPLegend/>*/}
          {/*  </MapAndLegend>*/}
          {/*</Map>*/}

          <Map>
            <Period>{!isEmpty(data.sm) ? data.sm.date : <p/>}</Period>
            <MapAndLegend>
              {!isEmpty(data.sm) ?
                <MapContainer onClick={()=>this.onOpenModal(data.sm)}>
                  <MapImage src={`${config.ROOT_URL}/media/${data.sm.file}`} alt="" height="300" width="240" style={{objectFit: 'contain'}}/>
                  <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
                </MapContainer> :
                <MapContainer><MapImage src={`${config.PUBLIC_URL}/img/na_1.png`} alt="" height="300" width="240"
                                        style={{objectFit: 'contain'}}/></MapContainer>
              }
            </MapAndLegend>
          </Map>
        </Row>

        <Row style={{justifyContent: "space-evenly", paddingRight: '1rem', width: '100%'}}>
          <MapsHead><Title>Anomalies</Title></MapsHead>

          <Map>
            <MapAndLegend>
              {!isEmpty(data.ndvi_anom) ?
                <MapContainer onClick={()=>this.onOpenModal(data.ndvi_anom)}>
                  <MapImage src={`${config.ROOT_URL}/media/${data.ndvi_anom.file}`} alt="" height="300" width="240" style={{objectFit: 'contain'}}/>
                  <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
                </MapContainer> :
                <MapContainer><MapImage src={`${config.PUBLIC_URL}/img/na_1.png`} alt="" height="300" width="240"
                                        style={{objectFit: 'contain'}}/></MapContainer>}
            </MapAndLegend>
          </Map>

          <Map>
            <MapAndLegend>
              {!isEmpty(data.rfe_anom) ?
                <MapContainer onClick={()=>this.onOpenModal(data.rfe_anom)}>
                  <MapImage src={`${config.ROOT_URL}/media/${data.rfe_anom.file}`} alt="" height="300" width="240" style={{objectFit: 'contain'}}/>
                  <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>
                </MapContainer> :
                <MapContainer><MapImage src={`${config.PUBLIC_URL}/img/na_1.png`} alt="" height="300" width="240"
                                        style={{objectFit: 'contain'}}/></MapContainer>}
            </MapAndLegend>
          </Map>
          {/*<Map>*/}
          {/*  <MapAndLegend>*/}
          {/*    {!isEmpty(data.dmp_anom) ?*/}
          {/*      <MapContainer onClick={()=>this.onOpenModal(data.dmp_anom)}>*/}
          {/*        <MapImage src={`${config.ROOT_URL}/media/${data.dmp_anom.file}`} alt="" height="300" width="240" style={{objectFit: 'contain'}}/>*/}
          {/*        <DownloadIcon><p data-tip="Download"><IoMdDownload color="#000000" size={20}/></p><ReactTooltip /></DownloadIcon>*/}
          {/*      </MapContainer> :*/}
          {/*      <MapContainer><MapImage src={`${config.PUBLIC_URL}/img/na_1.png`} alt="" height="300" width="240"*/}
          {/*                              style={{objectFit: 'contain'}}/></MapContainer>}*/}
          {/*    <DMPAnomLegend/>*/}
          {/*  </MapAndLegend>*/}
          {/*</Map>*/}
          <Map>
            <MapAndLegend>
                <MapContainer><MapImage src={`${config.PUBLIC_URL}/img/na_1.png`} alt="" height="300" width="240"
                                        style={{objectFit: 'contain'}}/></MapContainer>
            </MapAndLegend>
          </Map>
        </Row>
        <EcoModal
          closeModal={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          afterOpenModal={this.afterOpenModal}
          ecoclimatic={data}
          location={data.name}
          modal={this.state.modal}
        />

      </Fragment>

    )
  }
}

export default Compare;
