import axios from 'axios';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import * as types from './actionTypes';
import {beginAjaxCall, ajaxCallError} from './ajaxStatusActions';
import config from '../config';
import {ACCESS_TOKEN, REFRESH_TOKEN} from "./constants";


export function userAuthenticate() {
  return {type: types.USER_AUTHENTICATE};
}
export function setCurrentUser(user) {
  return{ type: types.SET_CURRENT_USER, user};
}
export function storeAccessToken(accessToken) {
  return{ type: types.STORE_ACCESS_TOKEN, accessToken};
}
export function storeRefreshToken(refreshToken) {
  return{ type: types.STORE_REFRESH_TOKEN, refreshToken};
}
export function userAuthenticated(token){
  return{ type: types.USER_AUTHENTICATED, token};
}
export function tokenRefreshSuccess(token) {
  return {type: types.TOKEN_REFRESH_SUCCESS, token};
}
export function onUserLogout() {
  return {type: types.USER_LOGOUT};
}
export function onAuthModal() {
  return {type: types.TOGGLE_AUTH_MODAL};
}

export function userRegistration(data) {
  return dispatch => {
    dispatch(beginAjaxCall());
    return axios.post(`${config.ROOT_URL}/auth/users/`, data)
      .then( response => {
      }).catch(error => {
        throw (error);
      });
  };
}
export function userActivate(data) {
  return () => {
    return axios.post(`${config.ROOT_URL}/accounts/activation/${data.uid}/${data.token}`, data)
      .then( resp => {
      }).catch(error => {
        throw (error);
      });
  };
}
export function userLogin(data) {
  return dispatch => {
    dispatch(beginAjaxCall());
    return axios.post(`${config.ROOT_URL}/auth/jwt/create`, data)
      .then( response => {
        let access = response.data.access;
        let refresh = response.data.refresh;
        localStorage.setItem(ACCESS_TOKEN, access);
        localStorage.setItem(REFRESH_TOKEN, refresh);
        setAuthorizationToken(access);
        dispatch(userAuthenticated(access));
        dispatch(storeAccessToken(access));
        dispatch(storeRefreshToken(refresh));
        window.location.reload();
      }).catch(error => {
        throw (error);
      });
  };
}
export function userForgotPassword(data) {
  return dispatch => {
    return axios.post(`${config.ROOT_URL}/auth/users/reset_password/`, data)
      .then( response => {
        console.log(response)
      }).catch(error => {
        throw (error);
      });
  };
}
export function refreshToken(data) {
  return dispatch => {
    return axios.post(`${config.ROOT_URL}/auth/jwt/refresh`, data)
      .then(response => {
        let access = response.data.access;
        dispatch(tokenRefreshSuccess(access));
        localStorage.setItem(ACCESS_TOKEN, access);
        setAuthorizationToken(access);
        dispatch(userAuthenticated(access));
        dispatch(storeAccessToken(access));
        dispatch(loadUser())
      }).catch(error => {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        throw (error);
      });
  };
}

export function verifyToken(data) {
  return dispatch => {
    return axios.post(`${config.ROOT_URL}/auth/jwt/verify`, data)
      .then(response => {
        let access = response.data.access;
        let refresh = response.data.refresh;
        dispatch(tokenRefreshSuccess(access));
        localStorage.setItem(ACCESS_TOKEN, access);
        localStorage.setItem(REFRESH_TOKEN, refresh);
        setAuthorizationToken(access);
        dispatch(userAuthenticated(access));
        dispatch(storeAccessToken(access));
        dispatch(storeRefreshToken(refresh));
        dispatch(ajaxCallError());
      }).catch(error => {
        throw (error);
      });
  };
}

export function loadUser() {
  return dispatch => {
    return axios.get(`${config.ROOT_URL}/auth/users/me/`)
      .then(response => {
        let user = response.data;
        dispatch(setCurrentUser(user));
      }).catch(error => {
        throw (error);
      });
  };

}

export function userLogout() {
  return dispatch => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
    dispatch(onUserLogout());
    window.location.reload();
  };
}
