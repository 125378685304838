import React, {Component } from 'react';
import ClimaticIndicators from "./compare";
import NDVI from "./variables/NDVI";
import RFE from "./variables/RFE";
import DMP from "./variables/DMP";
import FAPAR from "./variables/FAPAR";
import SM from "./variables/SM";
import styled from "styled-components";


const Page = styled.div`
  padding: 1rem 0;
`;

class EcoClimatic extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      countryId: "",
      region: {},
      data: {},
      isMain: false,
      isVariable: false
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderMaps() {
    if (this.props.filter.all) {
      return <ClimaticIndicators
        data={this.props.data}
        location={this.props.location}
        area={this.props.area}
        country={this.props.country}
        pa_area={this.props.pa_area}
        admin_area={this.props.admin_area}
      />
    } else if (this.props.filter.ndvi) {
      return <NDVI
        data={this.props.data}
        location={this.props.location}
        area={this.props.area}
        country={this.props.country}
        pa_area={this.props.pa_area}
        admin_area={this.props.admin_area}
      />
    } else if (this.props.filter.rfe) {
      return <RFE
        data={this.props.data}
        location={this.props.location}
        area={this.props.area}
        country={this.props.country}
        pa_area={this.props.pa_area}
        admin_area={this.props.admin_area}
      />
    } else if (this.props.filter.dmp) {
      return <DMP
        data={this.props.data}
        location={this.props.location}
        area={this.props.area}
        country={this.props.country}
        pa_area={this.props.pa_area}
        admin_area={this.props.admin_area}
      />
    } else if (this.props.filter.fapar) {
      return <FAPAR
        data={this.props.data}
        location={this.props.location}
        area={this.props.area}
        country={this.props.country}
        pa_area={this.props.pa_area}
        admin_area={this.props.admin_area}
      />
    }else if (this.props.filter.sm) {
      return <SM
        data={this.props.data}
        location={this.props.location}
        area={this.props.area}
        country={this.props.country}
        pa_area={this.props.pa_area}
        admin_area={this.props.admin_area}
      />
    }
  }

  render() {

    return (
      <Page>
        {this.props.data ?
        this.renderMaps()
          : <div className="loading"><div className="div-loading "><div/><div/><div/><div/></div></div>
        }
      </Page>


    )
  }
}

export default EcoClimatic;
