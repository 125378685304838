import React from 'react';
import Modal from "react-modal";
import {IoIosCloseCircle, IoMdDownload} from 'react-icons/io';
import {Col4, Col8, Row} from "../../../../theme/grid";
import config from "../../../../config";
import CoverLegend from "../legends/CoverLegend";
import styled from "styled-components";
import PropTypes from "prop-types";
import htmlToImage from 'html-to-image';
import download from 'downloadjs';


const Title = styled.div`
  font-family: ${props => (props.theme.headerFont)};
  font-size: 0.85rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  text-align: center;
  span{
    font-weight: 400;
    font-size: 0.9rem;
  }
`;
const ModalImage = styled.img`
  width: 500px;
  height: 500px;
  object-fit: contain;
  border: 1px solid #eeeeee;
`;

const customStyles = {

  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : '1000',
    width                 : '800px'
  }
};
Modal.setAppElement('#root');

const makePNG = (modalId, name)=>{
  return htmlToImage.toPng(document.getElementById(modalId))
    .then(function (dataUrl) {
      download(dataUrl, `${name}.png`);
    });
};
const MapModal=({modal, landcover, legend, modalIsOpen, afterOpenModal, closeModal, location})=>{
  let modalId = modal.id;
  let name = location+modal.title;
  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={modal.title}
    >
      <IoIosCloseCircle onClick={closeModal} size={25} color='#A52A2A' style={{position:"absolute", margin: '0.2rem', right: 0, top: 0, cursor: 'pointer', zIndex: 1 }}/>
      <Row id={modalId} style={{backgroundColor: "#ffffff"}} className="no-gutters">
        <Col8>
          <ModalImage
            src={`${config.ROOT_URL}/media/${modal.image}`}
            alt={modal.title}
          />
        </Col8>
        <Col4 style={{paddingLeft: "1rem", backgroundColor: "#ffffff"}}>
          <Title style={{fontWeight: '500', fontSize: '1.3rem'}}>Land Cover</Title>
          <Title style={{fontWeight: '500'}}>{landcover.cover_maps ? landcover.name+" | "+landcover.country.name: ""}</Title>
          <Title style={{fontWeight: '500'}}>{modal.title}</Title>
          <CoverLegend legend={legend} style={{fontWeight: '500', fontSize: '0.9rem'}}/>
        </Col4>
      </Row>
      <IoMdDownload onClick={()=> makePNG(modalId, name)} size={25} color='#000000' style={{position:"absolute", margin: '0.2rem', right: '2rem', top: 0, cursor: 'pointer' }}/>
    </Modal>
  )
};

MapModal.propTypes = {
  landcover: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  afterOpenModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};
export default MapModal;


