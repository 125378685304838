import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import DownloadLayout from "../../layout/DownloadLayout";
import styled from 'styled-components';
import {GoCloudDownload} from 'react-icons/go'
import {Container, FluidContainer, Row, Col2, Col10} from "../../theme/grid";
import ProductFilter from "./filter/ProductFilter";
import TimeFilter from "./filter/TimeFilter";
import FormatFilter from "./filter/FormatFilter";
import RegionFilter from "./filter/RegionFilter";
import DataFilter from "./filter/DataFilter";
import axios from "axios";
import config from "../../config";
import {requestConfig} from "../../utils/requestConfig";
import {bindActionCreators} from "redux";
import * as areaActions from "../../actions/areaActions";
import * as authActions from "../../actions/authActions";
import * as formatActions from "../../actions/formatActions";
import {connect} from "react-redux";
import {isEmpty} from "../../utils/isEmpty";
import moment from "moment";
import CustomScroll from 'react-custom-scroll';
import toastr from 'toastr';


const Side = styled(Col2)`
position: fixed;
left: 0;
top: calc(6rem + 60px);
height: 100%;
min-height: 60vh;
width: 100%;
border-right: 1px solid #eeeeee;
background-color: #ffffff;
padding: 0.5rem;
box-shadow: 0 5px 30px 0 rgba(39,39,39,0.15);
 .radio{
   font-size: 0.75rem;
   letter-spacing: 1px;
   margin-bottom: 0;
 }
`;
const Main = styled(Col10)`
  height: 100%;
  margin-top: calc(6rem + 60px);
  margin-left: 16.66%;
  @media (max-width: 1099px) {
    margin-top: calc(4rem + 60px);
  }
`;
const Section = styled.div`
  padding: 2rem 0;
`;
const Section1 = styled(Section)`
background: #ffffff;
`;
const Section2 = styled(Section)`
background: #ffffff;
`;
const Section3 = styled(Section)`
background: #ffffff;
`;
const Section4 = styled(Section)`
  background: #e9eaee;
  display: flex;
  justify-content: center;
`;
const IntroP = styled.div`
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: 400;
  padding: 0.5rem;
  width: 100%;
  color: #ffffff;
  text-align: center;
  @media (max-width: 768px){
    width: 100%;
  }
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background: ${props => (props.theme.mainColor)};
`;
const FilterContainer = styled.div`
box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
border-radius: 0.3rem;
`;

const DownloadButton = styled.div`
  background-color: #0B612D;
  color: #fff;
  z-index: 0;
  -webkit-box-shadow: 0 10px 20px 0 rgba(39, 39, 39, 0.15);
  box-shadow: 0 10px 20px 0 rgba(39, 39, 39, 0.15);
  padding: 12px 30px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  border: 0;
  position: relative;
  overflow: hidden;
  line-height: 1.5;
  display: flex;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:after{
    position: absolute;
    content: '';
    right: -70%;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    opacity: 0;
    -webkit-transform: skewX(0);
    transform: skewX(0);
    z-index: -1;
    -webkit-transition: all 0.4s cubic-bezier(0.82, 1.65, 0.54, 1.53);
    transition: all 0.4s cubic-bezier(0.82, 1.65, 0.54, 1.53);
  }
  &:hover{
    &:after{
      opacity: 0.2;
      transform: skewX(30deg);
      right: -50%;
    }
  }
`;

class DownloadPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filter_reload: false,
      submitting: false,
      isAuthenticated: true,
      slug: null,
      slugValue: {},
      allData: [],
      products: [],
      data: {},
      product: {},
      dekads: [],
      filter:{},
      timeSince: "",
      startDate: "",
      endDate: "",
      endTimeSince: "",
      mail: {email: ""},
      country: null,
      pa_area: null,
      admin_area: null,
      countries: [],
      areaType: "",
      format: {
        "png":false,
        "geotiff": false,
        "tabular": false,
      },
      dataType: this.props.match.params.slug,
      areas: [],
      adminAreas: [],
      paAreas: [],
      modalIsOpen: false,
      dataSet:[],
      errors: {}

    };
    this.onChange = this.onChange.bind(this);
  }
  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    this.getCountries();
    this.getAllData();
    this.getData();
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.country !== this.props.country) {
      this.props.actions.adminChange(null);
      this.props.actions.paChange(null);
      this.getAreas();
    }
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.getData();
    }
    if (prevState.startDate !== this.state.startDate) {
      this.checkDate();
    }
    if (prevState.areaType !== this.state.areaType) {
      this.props.actions.adminChange(null);
      this.props.actions.paChange(null);
    }
  }

  openModal =()=> {
    this.setState({modalIsOpen: true});
  };

  afterOpenModal=()=> {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  };
  closeModal=()=> {
    this.setState({modalIsOpen: false});
  };
  onOpenModal =(node)=>{
    this.setState({modal: node});
    // this.setState({location: slugify(this.props.data.name)});
    this.openModal();
  };

  onChange(event) {
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let filter = this.state.filter;
    this.setState({ filter: { ...filter, [name]: value } });
    this.getDataSet(name, value);
  }
  onFormatChange=(e)=> {
    const format = { ...this.state.format };
    format[e.target.value] = e.target.checked;
    this.setState({ format }, () => {
      this.props.actions.formatChange(this.state.format);
      console.log(this.state.format);
    });
    console.log(e.target.value)
  };
  
  onAreaTypeChange=(e)=> {
    this.setState({areaType: e.target.value});
    this.props.actions.areaTypeChange(e.target.value);
    // console.log(e.target.value)
    this.setState({area: null})
  };
  // onAreaTypeChange = (e) => {
  //   const { value, checked } = e.target;
  //   this.setState({ areaType: checked ? value : null }, () => {
  //     console.log("icpac selected:", this.state.areaType === "icpac");
  //   });
  //   this.props.actions.areaTypeChange(checked ? value : null)
  // };
  onDataTypeChange=(e)=> {
    const { history } = this.props;
    this.setState({dataType: e.target.value});
    history.push(`/data-center/${e.target.value}`);
  };
  onAdminChange=(e)=> {
    this.props.actions.paChange(null);
    this.props.actions.adminChange(e);
    if(this.props.country === null) {
      this.onAreaCountryChange(e);
    }
  };
  onPaChange=(e)=> {
    this.props.actions.adminChange(null);
    this.props.actions.paChange(e);
    if(this.props.country === null) {
      this.onAreaCountryChange(e);
    }
  };
  onAreaCountryChange(e){
      this.onCountryChange({value: e.value.country, label: e.value.country.name})
  }
  onCountryChange=(e)=> {
    this.setState({country: e});
    this.props.actions.countryChange(e);
  };
  onStartDateChange=(date)=> {
    this.setState({...this.state, startDate: date })
  };
  onEndDateChange=(date)=> {
    this.setState({...this.state, endDate: date })
  };

  getAllData(){
    return axios.post(`${config.ROOT_URL}/q/` , allDataQuery(), requestConfig)
      .then((resp)=>{
        let allData = resp.data.data.allData.edges;
        this.setState({allData: allData});
      }).catch(error => {
        throw(error);
      });
  }

  checkDate(){
    if(this.state.endDate <= this.state.startDate){
      this.setState({ endDate: this.state.startDate });
      this.setState({ endTimeSince: this.state.startDate });
    }else if (this.state.endTimeSince >  this.state.startDate){
      this.setState({ endTimeSince: this.state.startDate });
      // this.setState({ endDate: this.state.startDate });
    }
  }

  getData(){
    let slug = this.props.match.params.slug;
    return axios.post(`${config.ROOT_URL}/q/` , dataQuery(slug), requestConfig)
      .then((resp)=>{
        let data = resp.data.data.data;
        this.setState({data: data});
        this.setState({products: data.products.edges});
        this.setState({timeSince: new Date(data.timeSince)});
        this.setState({endTimeSince: new Date(data.timeSince)});
        this.setState({startDate: new Date(data.timeSince)});
        this.setState({endDate: new Date(data.timeSince)});
        this.setState({slugValue: {value: slug, label: data.name}});
      }).catch(error => {
        throw(error);
      });
  }

  getCountries(){
    return axios.post(`${config.ROOT_URL}/q/` , countryQuery(), requestConfig)
      .then((resp)=>{
        let data = resp.data.data.countries.edges;
        this.setState({countries: data});
        let paAreas = resp.data.data.paAreas.edges;
        this.setState({paAreas: paAreas});
        let adminAreas = resp.data.data.adminAreas.edges;
        this.setState({adminAreas: adminAreas});
      }).catch(error => {
        throw(error);
      });
  }

  getAreas(){
    if(this.props.country !== null) {
      return axios.post(`${config.ROOT_URL}/q/`, areaQuery(this.props.country.value.gid), requestConfig)
        .then((resp) => {
          let paAreas = resp.data.data.paAreas.edges;
          this.setState({paAreas: paAreas});
          let adminAreas = resp.data.data.adminAreas.edges;
          this.setState({adminAreas: adminAreas});
        }).catch(error => {
          throw(error);
        });
    }
  }

  dataOptions(options){
    return options.map(option =>{
      return{
        name: option.node.name,
        label: option.node.label,
        description: option.node.description
      }
    })
  };
  getDataSet(name, value){
    let dataSet = this.state.dataSet;
    if (value){
      let object = this.dataOptions(this.state.products).filter(option => option.name === name)[0];
      dataSet.push(object);
      this.setState({dataSet: dataSet});
    } else if(!value){
      let objects = this.state.dataSet.filter(option => option.name !== name);
      this.setState({dataSet: objects});
    }
  }
  requestData(){
    const {format} = this.state
    return{
      user: this.props.user.id,
      user_obj: this.props.user,
      name: this.state.data.name,
      abbr: this.state.data.abbr,
      start_date: moment(this.state.startDate).format('YYYY-MM-DD'),
      end_date: (moment(this.state.endDate).format('YYYY-MM-DD')),
      is_country: !!this.props.country.value.id,
      country: this.props.country.value.pk,
      country_obj: this.props.country.value,
      is_admin: this.props.admin_area ? !!this.props.admin_area.value.gid:false,
      admin: this.props.admin_area ?  this.props.admin_area.value.pk:"",
      admin_obj: this.props.admin_area ?  this.props.admin_area.value:{},
      is_pa: this.props.pa_area ? !!this.props.pa_area.value.wdpaid:false,
      pa: this.props.pa_area ? this.props.pa_area.value.pk:"",
      pa_obj: this.props.pa_area ? this.props.pa_area.value:{},
      datasets: this.state.dataSet,
      png: format.png,
      geotiff: format.geotiff,
      tabular: format.tabular,
      is_icpac: this.props.areaType === 'icpac',
      //is_icpac: format.is_icpac,
    }
  }

  requestValid() {
    let formIsValid = true;
    let errors = {};
    if(isEmpty(this.state.filter)) {
      errors.product = 'Select at least one Product';
      formIsValid = false;
    }
    if((this.state.areaType !== 'icpac') && 
      isEmpty(this.props.country) &&
      isEmpty(this.props.pa_area) &&
      isEmpty(this.props.admin_area)
    ) {
      errors.region = 'Select at least Country, Administrative Area or Protected Area';
      formIsValid = false;
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  sendDataRequest=(data)=>{
    console.log('Request Data Payload:', data)
    this.setState({submitting: true});
    axios.post(`${config.ROOT_URL}/sendy/datarequest`, data)
      .then((resp)=>{
        this.setState({submitting: false});
        toastr.success(`<strong>Success</strong> <br /> <em>You request for ${this.state.data.abbr}</em>&nbsp;&nbsp;has been submitted`);
      })
      .catch((error)=>{
        this.setState({submitting: false});
        toastr.error(`<strong>Error</strong> <br /> <em>There was an error when submitting you request  for ${this.state.data.shortcode}</em>`);
      })
    // const data = this.requestData();
    // console.log("Request Data Payload:", data); // Log the payload to the console
    // this.setState({ submitting: true });
    // axios.post(`${config.ROOT_URL}/sendy/datarequest`, data)
    //   .then((resp) => {
    //     this.setState({ submitting: false });
    //     toastr.success(`<strong>Success</strong> <br /> <em>You request for ${this.state.data.abbr}</em>&nbsp;&nbsp;has been submitted`);
    //   })
    //   .catch((error) => {
    //     this.setState({ submitting: false });
    //     toastr.error(`<strong>Error</strong> <br /> <em>There was an error when submitting you request for ${this.state.data.shortcode}</em>`);
    //   });
  };

  onDownloadData=()=>{
    let isAuthenticated = this.props.isAuthenticated;
    if(isAuthenticated){
      if (!this.requestValid()) {
        toastr.error(`<strong>Error</strong> <br /> <em>Fill in all the required fields</em>`);
        return;
      }
      this.sendDataRequest(this.requestData())
    }else{
      this.props.actions.onAuthModal();
    }
  };
  onClearFilters=()=>{
    this.setState({filter_reload: true});
    let filter = this.state.filter;
    Object.keys(filter).forEach(x => filter[x] = false);
    this.setState({filter: {}});
    this.setState({dataSet: []});
    this.setState({filter_reload: false});
  };

  render() {
    let data = this.state.data;
    let page ={
      title: `${data.name || ""} (${data.abbr || ""})`,
      description: `${data.abbr || ""} & Derived dashboard`,
      keywords: `${data.name || ""}, ${data.abbr || ""}`
    };

    return (
      <Fragment>
        {!isEmpty(this.state.data) ?
          <DownloadLayout page={page}>
            <FluidContainer>
              <Row>
                <Side>
                  <CustomScroll heightRelativeToParent='calc(100vh - calc(6rem + 60px))'>
                    <DataFilter
                      dataType={this.state.dataType}
                      options={this.state.allData}
                      onChange={this.onDataTypeChange}
                    />
                  </CustomScroll>
                </Side>

                <Main>
                  <Section1>
                    <Container>
                      <FilterContainer>
                        <IntroP>Products</IntroP>
                        {!this.state.filter_reload ?
                          <ProductFilter
                            onChange={this.onChange}
                            filter={this.state.filter}
                            products={this.state.data.products.edges}
                            errors={this.state.errors}
                          /> :
                          <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <img src={`${config.PUBLIC_URL}/img/preview.gif`} alt="" />
                          </div>
                        }
                        <Row style={{justifyContent: 'flex-end'}}>
                          {/*<ClearFilter onClick={this.onClearFilters}>Clear filters</ClearFilter>*/}
                        </Row>
                      </FilterContainer>
                    </Container>
                  </Section1>

                  <Section2>
                    <Container>
                      <FilterContainer>
                        <IntroP>Time period</IntroP>
                        <TimeFilter
                          onChange={this.onChange}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          timeSince={this.state.timeSince}
                          endTimeSince={this.state.endTimeSince}
                          onStartDateChange={this.onStartDateChange}
                          onEndDateChange={this.onEndDateChange} 
                          filter={this.state.filter}
                          dekads={this.state.dekads}/>

                      </FilterContainer>
                    </Container>
                  </Section2>

                  <Section2>
                    <Container>
                      <FilterContainer>
                        <IntroP>File Format</IntroP>
                        <FormatFilter
                          format={this.props.format}
                          errors={this.state.errors}
                          onFormatChange={this.onFormatChange}
                        />

                      </FilterContainer>
                    </Container>
                  </Section2>

                  <Section3>
                    <Container>
                      <FilterContainer>
                        <IntroP>Region of interest</IntroP>
                        <RegionFilter
                          onCountryChange={this.onCountryChange}
                          onAreaTypeChange={this.onAreaTypeChange}
                          onPaChange={this.onPaChange}
                          onAdminChange={this.onAdminChange}
                          country={this.props.country}
                          countries={this.state.countries}
                          areaType={this.props.areaType}
                          adminAreas={this.state.adminAreas}
                          admin_area={this.props.admin_area}
                          paAreas={this.state.paAreas}
                          pa_area={this.props.pa_area}
                          errors={this.state.errors}
                          //format={this.props.format}
                          //onFormatChange={this.onFormatChange}
                          

                        />
                      </FilterContainer>
                    </Container>
                  </Section3>
                  <Section4>
                    {this.state.submitting ? <img src={`${config.PUBLIC_URL}/img/button-loading.gif`} alt="Button Loading" height={50} width={50}/>
                      :
                      <DownloadButton onClick={this.onDownloadData}>
                        Download Data&nbsp;&nbsp;
                        <GoCloudDownload size={25}/>
                      </DownloadButton>
                    }

                  </Section4>
                </Main>
              </Row>
            </FluidContainer>

          </DownloadLayout>
          :
          <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={`${config.PUBLIC_URL}/img/preview.gif`} alt="" />
          </div>
        }
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.isAuthenticated,
    user: state.user,
    country: state.country,
    areaType: state.areaType,
    format: state.format,
    pa_area: state.pa_area,
    admin_area: state.admin_area
  };

}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign( areaActions, authActions, formatActions), dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DownloadPage));

function allDataQuery() {
  return `
    query{
     allData{
        edges{
          node{
            id,
            name,
            slug,
            shortcode,
            timeSince,
            dataType{
              id,
              name,
              slug
            }
          }
        }
      }
    }
  `;
}

function dataQuery(slug) {
  return `
      query{
        data(slug: "${slug}"){
          id,
          slug,
          name,
          abbr,
          timeSince,
          shortcode,
          dataType{
            id,
            name
          },
          spatialRes,
          temporalRes,
          products:derivedproductSet{
            edges{
              node{
                id,
                name,
                label,
                description
              }
            }
          }
        }
      }
    `
}

function countryQuery() {
  return`
query{
    countries:allCountries{
      edges{
        node{
          id,
          pk,
          gid,
          name
        }
      }
    },

  adminAreas:allAdminAreas{
    edges{
      node{
        id,
        pk,
        gid,
        name,
        country{
          id,
          gid,
          name
        }
      }
    }
  },
  paAreas:allProtectedAreas{
    edges{
      node{
        id,
        pk,
        wdpaid,
        name,
        country{
          id,
          gid,
          name
        }
      }
    }
  }
}
  `
}

function areaQuery(country) {
  return`
  query{
    adminAreas:allAdminAreas(country_Gid:"${country}"){
      edges{
        node{
          id,
          pk,
          gid,
          name
        }
      }
    },
    paAreas:allProtectedAreas(country_Gid:"${country}"){
      edges{
        node{
          id,
          pk,
          wdpaid,
          name
        }
      }
    }
  }
  `;
}
